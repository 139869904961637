import React from 'react'
import { Icon, IconVariant } from 'shared-design/components'

import './IconText.scss'

interface IconTextProps {
  icon: IconVariant
  children: React.ReactNode
  title?: string
}

const IconText: React.FunctionComponent<IconTextProps> = ({
  children,
  icon,
  title,
}: IconTextProps) => {
  return (
    <span className="icon-text" title={title}>
      <span className="icon-text__icon">
        <Icon variant={icon} />
      </span>
      <span className="icon-text__text">{children}</span>
    </span>
  )
}

IconText.defaultProps = {
  title: '',
}

export default IconText
