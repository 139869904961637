import React from 'react'
import './DisabledBlock.scss'
import classNames from 'classnames'

export const DisabledBlock: React.FC<{
  children: React.ReactNode
  disabled?: boolean
  allowPointer?: boolean
}> = ({ children, disabled = false, allowPointer = false }) => {
  return (
    <div
      className={classNames(
        'DisabledBlock',
        disabled ? 'DisabledBlock--disabled' : null,
        !allowPointer ? 'disable_pointer' : null,
      )}
    >
      {children}
    </div>
  )
}
