import React, { useContext } from 'react'
import moment from 'moment-timezone'
import { ReservationInfoRelevantForUsers } from 'common/types'
import {
  StyledButton as Button,
  Text,
  Link,
  FlexItem,
  Flex,
  GoogleMapsLink,
} from 'shared-design/components/atoms'
import {
  LocaleContext,
  useLocalizations,
} from 'shared-design/components/molecules/Locales'
import { formatRelativeDay } from 'common/utils/dates'
import { useReservationCancelling } from './hooks'

import './ReservationsList.scss'

interface Props {
  reservationInfo: ReservationInfoRelevantForUsers
  hideActions?: boolean
}

const RoomImage: React.FC<{ imgUrl?: string }> = ({ imgUrl }) => (
  <div className="RoomImage">
    {imgUrl !== undefined ? <img alt="" src={imgUrl} /> : <></>}
  </div>
)

const ReservationDates: React.FC<{
  start: moment.Moment
  end: moment.Moment
}> = ({ start, end }) => {
  const locale = useContext(LocaleContext)
  const formatDay = (day: moment.Moment) =>
    formatRelativeDay(day, locale.currentLanguage)

  const formatTime = (day: moment.Moment) => day.format('LT')

  const formatTimeRange = (d1: moment.Moment, d2: moment.Moment) =>
    `${formatTime(d1)} - ${formatTime(d2)}`

  const formatMultidayRange = (d1: moment.Moment, d2: moment.Moment) => {
    return `${formatDay(d1)} ${formatTime(d1)} - ${formatDay(d2)} ${formatTime(
      d2,
    )}`
  }

  return (
    <div className="ReservationDates" style={{ display: 'flex' }}>
      {start.isSame(end, 'day') ? (
        <>
          <div>{formatDay(start)}</div>
          <div>{formatTimeRange(start, end)}</div>
        </>
      ) : (
        <div>{formatMultidayRange(start, end)}</div>
      )}
    </div>
  )
}

const ReservationRow: React.FunctionComponent<Props> = (props) => {
  const {
    roomName,
    roomAddress,
    roomImageUrls,
    shareableId,
    reservationStart,
    reservationEnd,
    roomId,
    reservationId,
    locationName,
    roomTimezone,
  } = props.reservationInfo
  const roomImage = roomImageUrls.find((v) => v)
  const { localization } = useLocalizations()

  const [cancelReservation] = useReservationCancelling()

  return (
    <div className="ReservationRow">
      <div className="ReservationRow__content">
        <RoomImage imgUrl={roomImage} />
        <div className="ReservationRow__details">
          <div
            className="ReservationRow__name"
            title={`${localization.reservationId}: ${shareableId}`}
          >
            {roomName}
          </div>
          <ReservationDates
            start={moment.utc(reservationStart).tz(roomTimezone)}
            end={moment.utc(reservationEnd).tz(roomTimezone)}
          />
          <GoogleMapsLink query={roomAddress}>
            {locationName ? `${locationName}, ` : ''}
            {roomAddress}
          </GoogleMapsLink>
        </div>
      </div>
      {props.hideActions ? (
        <></>
      ) : (
        <div className="ReservationRow__cancel">
          <Flex direction="column">
            {moment().isBefore(reservationStart) &&
            reservationId !== undefined ? (
              <FlexItem>
                <Button
                  color="secondary"
                  onClick={() => cancelReservation(reservationId)}
                >
                  <Text tr="cancelReservation" />
                </Button>
              </FlexItem>
            ) : null}
            <FlexItem>
              <Link to={`/spaces/${roomId}`}>
                <Button color="secondary">
                  <Text tr="makeANewReservation" />
                </Button>
              </Link>
            </FlexItem>
          </Flex>
        </div>
      )}
    </div>
  )
}

export default ReservationRow
