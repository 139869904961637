import moment from 'moment'

// Supported languages need to be imported manually
// to prevent blaoting the build.
// See: https://create-react-app.dev/docs/troubleshooting/#momentjs-locales-are-missing
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/zh-cn'
import { Language } from 'common/types'

// Imported locales override the global default...
moment.locale('en')

// Added 'zh-cn' as possible language, since it has been working like that
// for quite some time now, even though the types have previously disallowed that. 🤷‍
export const localizeMoment =
  (language: Language | 'zh-cn') =>
  (...args: Parameters<typeof moment>): ReturnType<typeof moment> =>
    moment(...args).locale(language)
