import { TranslationKey } from 'common/locales/dictionary'
import { validateEmail } from 'common/utils/email'
import { isValidPhoneNumber, sanitizePhone } from 'common/utils/validation'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { ContentBlock, Flex, Text } from 'shared-design/components/atoms'
import { LocaleContext } from 'shared-design/components/molecules/Locales'
import Spinner from 'shared-design/components/Spinner/Spinner'
import { PlainCheckInRoom } from 'common/types/checkin'
import { checkInRequest, fetchGuestCheckIn } from '../../api'
import HeaderComponent from '../Header/Header'
import './GuestCheckIn.scss'
import { GuestCheckinForm } from './GuestCheckinForm'

const CheckInExpiredOrNotFound = () => {
  return (
    <ContentBlock>
      <div className="GuestCheckIn__invalid">
        <Text tr="checkInNoReservation" />
      </div>
    </ContentBlock>
  )
}

export const GuestCheckIn = () => {
  const history = useHistory()

  const match = useRouteMatch<{ shareableId: string }>(
    '/guest/check-in/:shareableId',
  )

  const params = {
    shareableId: match?.params.shareableId || '',
  }

  const locale = useContext(LocaleContext)
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [room, setRoom] = useState<PlainCheckInRoom>({})
  const [invalidCheckIn, setCheckinInvalid] = useState<boolean>(false)

  const extractTranslation = (obj: any) => {
    return obj[locale.currentLanguage] || obj.en
  }

  useEffect(() => {
    // we should check if the checkin is valid
    fetchGuestCheckIn(params.shareableId)
      .then((response) => {
        if (response.valid) {
          if (response.room) {
            setRoom({
              name: extractTranslation(response.room?.name),
              description: extractTranslation(response.room?.description),
            })
          }
        } else {
          setCheckinInvalid(true)
        }
        setLoading(false)
      })
      .catch(() => {
        setCheckinInvalid(true)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    // perhpaps we should create a text field type that passed through
    // only the correct values for a phone
    // for know I will re-use the existing function
    if (phone) setPhone(sanitizePhone(phone) as string)
  })

  const onSubmit = () => {
    if (!name) {
      return setError(locale.localization.checkInFormNameError)
    }

    if (!validateEmail(email)) {
      return setError(locale.localization.subscriptionEmailError)
    }

    if (!isValidPhoneNumber(phone)) {
      return setError(locale.localization.checkInFormPhoneError)
    }

    setError('')

    checkInRequest({
      shareableId: params.shareableId,
      name,
      email,
      phone,
    }).then((response) => {
      if (response.success) {
        // redirect to success page
        history.push(`/guest/check-in/success`)
      } else {
        const key: string = response.reason
          ? (response.reason as TranslationKey)
          : locale.localization.unknownError

        setError(locale.localization[key as TranslationKey])
      }
    })

    return null
  }

  return (
    <div className="GuestCheckIn">
      <HeaderComponent />
      <div className="GuestCheckIn__Content">
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Flex className="GuestCheckIn__Content" direction="column" gap="30">
            {invalidCheckIn ? (
              <CheckInExpiredOrNotFound />
            ) : (
              <GuestCheckinForm
                title={<Text tr="checkInHeader" />}
                room={room}
                values={{
                  name,
                  email,
                  phone,
                }}
                onChange={(v) => {
                  setName(v.name)
                  setEmail(v.email)
                  setPhone(v.phone)
                }}
                onSubmit={onSubmit}
                submitText={<Text tr="checkIn" />}
                error={error}
              />
            )}
          </Flex>
        )}
      </div>
    </div>
  )
}

export default GuestCheckIn
