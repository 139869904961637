import { en } from './en'
import { Translations } from './types'
import { generateTimeFormatters } from './generators'
import { Weekday } from '../../types'
import { svCountryCodeTranslations } from './country_code_translations'

export const constantStrings = {
  // Languages
  en: 'Engelska',
  fi: 'Finska',
  zh: 'Kinesiska',
  sv: 'Svenska',

  // location editor -- admin
  specialOpeningHoursClosed: 'stängd',
  specialOpeningHours: 'Särskilda öppettider',

  // amenities editor -- admin and website
  print_scan_copy: 'Print / Scan / Kopiering',
  coffee_tea: 'Kaffe och te',
  restaurant: 'On-site Restaurang',
  cafe: 'On-site Café',
  hand_sanitizer: 'Handsprit',
  enhanced_cleaning: 'Förbättrad städning',
  parking: 'Parkering',
  accessible: 'Tillgänglighetsanpassat',
  lockers: 'Skåp',
  reception: 'Bemannad reception',
  phone_booth: 'Telefonbås',
  gym: 'Gym',
  adjustable_table: 'Justerbart skrivbord',
  monitor: 'Externa skärmar',
  silent_space: 'Tyst utrymme',
  whiteboard: 'Whiteboardtavla ',
  projector: 'Projektor eller liknande',
  videoconference: 'Möjlighet till videokonferens',
  furnished: 'Möblerat (privat office)',
  pet_friendly: 'Djurvänlight',
  shower: 'Möjlighet till dusch',
  bike_parking: 'Cykelparkering',
  wifi: 'Wi-Fi',

  metaTitleTextDefault: 'Coworking, mötesrum och privata kontor',
  metaDescriptionDefault:
    'Vi ger dig möjligheten att kunna arbeta från var som helst. Som medlem hos Spacent kan du använda hundratals coworking spaces runt om hela Europa.',
  openGraphTitleTextDefault: 'Coworking, mötesrum och privata kontor',

  webMembershipDefaultAllUsersUsergroupName: 'Endast pay-per-use',
  webMembershipBasicMembershipUsergroupName: 'Basic',
  webBusinessMembershipUsergroupName: 'Business',
  webBusinessPlusMembershipUsergroupName: 'Business+',

  ...svCountryCodeTranslations,

  // Country code overrides
  FI: 'Finland',
  GR: 'Grekland',
  SE: 'Sverige',
  AT: 'Österrike',
  EE: 'Estland',
  ES: 'Spanien',
  LT: 'Litauen',
  LV: 'Lettland',
  NO: 'Norge',
  DK: 'Denmark',
  RO: 'Rumänien',
  CH: 'Schweiz',
  DE: 'Tyskland',
  GB: 'Förenade kungariket',
  PT: 'Portugal',
  PL: 'Polen',
  CZ: 'Tjeckien',
}

const validations = {
  ...en.validations,
}

const timeFormats = {
  ...en.timeFormats,
  momentWebDayFormat: 'YYYY-MM-DD',
  momentMonthFormat: 'YYYY-MM',
  momentDateFormat: 'YYYY-MM-DD',
  momentDateTimeFormat: 'YYYY-MM-DD HH:mm',
  momentTimeFullFormat: 'H.mm',
  momentTimeHourFormat: 'H',
  weekOrder: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ] as Weekday[],
  weekDayAbbreviations: {
    monday: 'Må',
    tuesday: 'Ti',
    wednesday: 'On',
    thursday: 'To',
    friday: 'Fr',
    saturday: 'Lö',
    sunday: 'Sö',
  },
}

export const sv: Translations = {
  // Use English translations as fallback language.
  // Comment following line to let TypeScript compiler tell you what translations are missing.
  ...en,
  ...constantStrings,
  validations,
  timeFormats,
  timeFormatters: generateTimeFormatters(timeFormats),
}
