import React, { useContext } from 'react'
import { ReservationBundle } from 'common/types'
import { Text } from 'shared-design/components/atoms'
import moment from 'moment'
import { RoomFilterCriteria, UserReservations } from './contexts'
import './ReservedSpace.scss'

const ReservedSpace: React.FC = () => {
  const roomCriteria = useContext(RoomFilterCriteria)
  const reservations = useContext(UserReservations)

  const criteriaStartDay = roomCriteria?.start.clone().utc().startOf('day')
  const criteriaEndDay = roomCriteria?.end.clone().utc().startOf('day')

  const reservation = (reservations || []).find((bundle: ReservationBundle) => {
    const start = moment.utc(bundle.reservation.start).startOf('day')
    const end = moment.utc(bundle.reservation.end).startOf('day')

    return (
      start.isSame(criteriaStartDay) ||
      start.isSame(criteriaEndDay) ||
      end.isSame(criteriaStartDay) ||
      end.isSame(criteriaEndDay)
    )
  })

  if (!reservation) {
    return null
  } else {
    return (
      <div className="ReservedSpace">
        <Text tr="reservationOnDate" />
        <span className="ReservedSpace__space">{reservation.room.name}</span>
      </div>
    )
  }
}

export default ReservedSpace
