import React, { useState } from 'react'
import Spinner from 'shared-design/components/Spinner/Spinner'

export interface DelayedSpinnerProps {
  children: React.ReactNode
  promise?: Promise<any>
  delay?: number
}
const DelayedSpinner: React.FC<DelayedSpinnerProps> = (
  props: DelayedSpinnerProps,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (!props.promise) {
    return <>{props.children}</>
  }

  const timer = setTimeout(() => setIsLoading(true), props.delay ?? 200)

  props.promise.finally(() => {
    clearTimeout(timer)
    setIsLoading(false)
  })

  return isLoading ? <Spinner margin="100px" center /> : <>{props.children}</>
}

export default DelayedSpinner
