import React from 'react'
import './ALink.scss'

interface ALinkProps {
  href: string
  children: React.ReactNode
  stopPropagation?: boolean
  target?: string
}

const ALink: React.FC<ALinkProps> = ({
  href,
  children,
  stopPropagation,
  target = '_blank',
}) => (
  <a
    href={href}
    target={target}
    rel="noopener noreferrer"
    className="Spacent__ALink"
    onClick={(e) => stopPropagation && e.stopPropagation()}
  >
    {children}
  </a>
)

export default ALink
