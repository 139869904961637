import React from 'react'
import cls from 'classnames'
import { Checkbox, CheckboxProps } from '@material-ui/core'
import { InputLabel } from '../InputLabel/InputLabel'

import './StyledCheckbox.scss'
import { ContentBlock } from '../ContentBlock/ContentBlock'
import { Tooltip } from '../Tooltip/Tooltip'

type StyledCheckboxProps = CheckboxProps & {
  children?: React.ReactNode
  label?: React.ReactNode
  id?: string
  tooltip?: React.ReactNode
  vertical?: boolean
  verticalChild?: boolean
  iconelement?: React.ReactNode
}

export const StyledCheckbox: React.FC<StyledCheckboxProps> = ({
  vertical = false,
  verticalChild = false,
  className = '',
  ...props
}: StyledCheckboxProps) => {
  return (
    <div
      className={cls('StyledCheckbox', className, {
        'StyledCheckbox--vertical-child': verticalChild,
      })}
    >
      <Tooltip title={props.tooltip ?? ''}>
        {props.label ? (
          <label
            htmlFor={props.id}
            className={cls('StyledCheckbox__label', {
              'StyledCheckbox__label--vertical': vertical,
            })}
          >
            <Checkbox {...props} className="StyledCheckbox__input" />
            {props.iconelement && props.iconelement}
            <InputLabel tooltip={props.tooltip} variant="lowercase">
              {props.label}
            </InputLabel>
          </label>
        ) : (
          <Checkbox {...props} className="StyledCheckbox__input" />
        )}
      </Tooltip>
      {props.children && props.checked && (
        <ContentBlock className="RadioOption__Label__Children">
          {props.children}
        </ContentBlock>
      )}
    </div>
  )
}

export default StyledCheckbox
