export type TransientError = {
  tag: 'transient_error'
  status: number
  message?: string
}
export type NonTransientError = {
  tag: 'non_transient_error'
  status: number
  message?: string
}
export type AuthError = {
  tag: 'auth_error'
  status: number
  message?: string
}

export type ApiError = TransientError | NonTransientError | AuthError

export const isApiError = (v: any): v is ApiError =>
  v?.tag === 'transient_error' ||
  v?.tag === 'non_transient_error' ||
  v?.tag === 'auth_error'

export const apiErrorFromResponse = (
  response: Response,
  message?: string,
): ApiError => {
  if (response.status === 401) {
    return {
      tag: 'auth_error',
      status: response.status,
      message: 'authError',
    }
  } else if (response.status >= 400 && response.status < 500) {
    return { tag: 'non_transient_error', status: response.status, message }
  } else {
    return { tag: 'transient_error', status: response.status, message }
  }
}
