import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiSlider, { ValueLabelProps, Mark } from '@material-ui/core/Slider'
import cns from 'classnames'
import adminColors from '../../../styles/_admin-colors.scss'
import { InputLabel } from '../InputLabel/InputLabel'

interface Props {
  className?: string
  label?: React.ReactNode
  marks?: Mark[]
  defaultValue: number
  value: [number, number]
  onRangeChange: (range: [number, number]) => void
  formatLabel: (v: number) => string
  step: number
  max: number
  min: number
  disabled?: boolean
}

type ExtendedValueLabelProps = ValueLabelProps & {
  classes: Record<string, string>
  valueLabelDisplay: 'on' | 'off'
}

// Simplified version of @material-ui/core/Slider/ValueLabel
// Why? The module has missing type declaration and
// an augmentation cannot be created directly.
const ValueLabel: React.FC<ExtendedValueLabelProps> = (props) => {
  const { children, classes, className, open, value, valueLabelDisplay } = props

  if (valueLabelDisplay === 'off') {
    return children
  } else {
    return React.cloneElement(
      children,
      {
        className: cns(
          children.props.className,
          {
            [classes.open]: open || valueLabelDisplay === 'on',
          },
          classes.thumb,
        ),
      },
      <span className={cns(classes.offset, className)}>
        <span className={classes.label}>{value}</span>
      </span>,
    )
  }
}

export const StyledValueLabel = withStyles({
  offset: {
    position: 'absolute',
    top: 28,
    left: (props: { index?: number }) =>
      props.index === 0 ? 'calc(-50% + -20px)' : 'calc(-50% + 12px)',
  },
  label: {
    color: 'rgb(247, 184, 58)',
  },
})(ValueLabel)

export const StyledSlider = withStyles(() => ({
  root: {
    marginTop: 20,
  },
  thumb: {
    border: '1px solid rgb(247, 184, 58)',
    background: '#262f36',
    boxShadow: '0 0 0 5px solid #black',
    width: 18,
    height: 18,
    marginTop: -8,
    marginLeft: -8,
    '&:hover': {
      boxShadow: '0 0 0 1px rgb(247, 184, 58)',
    },
  },
  disabled: {
    filter: 'grayscale(100%)',
  },
  track: {
    backgroundColor: 'rgb(247, 184, 58)',
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 1,
    backgroundColor: adminColors.pickerBackground,
  },
  mark: {
    display: 'none',
  },
  markLabel: {
    color: '#fff',
    top: -18,
    opacity: 0.6,
    fontWeight: 500,
  },
}))(MuiSlider)

const useStyles = makeStyles({
  root: {
    color: '#fff',
    fontFamily: 'Barlow',
    width: '22rem',
    flexGrow: 1,
    padding: 24,
    marginRight: '1rem',
  },
})

export const RangeSlider: React.FC<Props> = (props) => {
  const {
    className,
    label,
    defaultValue,
    value,
    onRangeChange,
    marks,
    min,
    max,
    step,
    formatLabel,
    disabled,
  } = props

  const classes = useStyles()

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <div className={classes.root}>
        <StyledSlider
          className={className}
          defaultValue={defaultValue}
          step={step}
          marks={marks}
          min={min}
          max={max}
          valueLabelDisplay={disabled ? 'off' : 'on'}
          valueLabelFormat={formatLabel}
          onChange={(_, newValue) =>
            onRangeChange(newValue as [number, number])
          }
          value={value}
          // There's a type mismatch with valueLabelDisplay, and since it works
          // I decided to circumvent this with any for now. Apparently the above
          // declared simplified ValueLabel has come its course. maybe.
          ValueLabelComponent={StyledValueLabel as any}
          disabled={disabled}
        />
      </div>
    </>
  )
}

export default RangeSlider
