import React, { createContext, useEffect, useState, useContext } from 'react'
import { Configuration } from 'common/types'
import Spinner from '../Spinner/Spinner'

export const ConfigurationContext = createContext<Configuration>({
  features: {},
  config: {},
})

export interface ConfigurationProviderProps {
  getConfiguration: () => Promise<Configuration>
}

export const ConfigurationProvider: React.FC<ConfigurationProviderProps> = ({
  children,
  getConfiguration,
}) => {
  const [configuration, setConfiguration] = useState<
    Configuration | undefined
  >()

  useEffect(() => {
    getConfiguration().then((response) => {
      setConfiguration(response)
    })
  }, [])

  if (!configuration) {
    return <Spinner margin="200px" center />
  }

  return (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  )
}

export const useConfiguration = () => {
  return useContext(ConfigurationContext)
}
