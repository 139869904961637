import React from 'react'
import MomentUtils from '@date-io/moment'
import moment from 'moment-timezone'
import { Moment } from 'moment'
import { MuiThemeProvider, TextFieldProps } from '@material-ui/core'
import {
  DateTimePicker as MuiDateTimePicker,
  MuiPickersUtilsProvider,
  DateTimePickerViewsProps,
} from '@material-ui/pickers'

import './DateTimePicker.scss'
import Schedule from '@material-ui/icons/Schedule'
import classNames from 'classnames'
import { TextButton } from '../TextButton'

import { materialTheme } from './themes'

type DateAdverb = 'yesterday' | 'today' | 'tomorrow'

interface LocaleProps {
  locale?: string
  fmtDateAdverb?: (adverb: DateAdverb) => string
}

interface TextProps {
  okLabel?: React.ReactNode
  cancelLabel?: React.ReactNode
  todayLabel?: React.ReactNode
}

const getTimezonedMoment = (
  timezone?: string,
  parseableDateString?: string,
): Moment =>
  timezone
    ? moment.tz(parseableDateString, timezone)
    : moment(parseableDateString)

const DateButton: React.FC<
  TextFieldProps &
    LocaleProps & {
      readOnly?: boolean
      timezone?: string
    }
> = ({ value, onClick, locale, fmtDateAdverb, readOnly, timezone }) => {
  const formatDate = (dateStr: string) => {
    const now = getTimezonedMoment(timezone)
    const parsedDate = getTimezonedMoment(timezone, dateStr)

    const compareDay = (d1: Moment, d2: Moment) => d1.isSame(d2, 'day')
    const compareWeek = (d1: Moment, d2: Moment) => d1.isSame(d2, 'week')

    const formatDay = () => {
      if (compareDay(now, parsedDate)) {
        return fmtDateAdverb?.('today') ?? 'Today'
      } else if (compareDay(now.add(1, 'day'), parsedDate)) {
        return fmtDateAdverb?.('tomorrow') ?? 'Tomorrow'
      } else if (compareDay(now.subtract(1, 'day'), parsedDate)) {
        return fmtDateAdverb?.('yesterday') ?? 'Yesterday'
      } else if (compareWeek(now, parsedDate)) {
        return parsedDate
          .toDate()
          .toLocaleDateString(locale, { weekday: 'long' })
      } else {
        return parsedDate
          .toDate()
          .toLocaleDateString(locale, { day: 'numeric', month: 'numeric' })
      }
    }

    return `${formatDay()} ${parsedDate.locale(locale ?? 'en').format('LT')}`
  }

  return (
    <div className={classNames('DateButton', { readOnly })}>
      <Schedule className="DateButton__icon" />
      <TextButton onClick={onClick ?? (() => undefined)} disabled={readOnly}>
        {typeof value === 'string' ? formatDate(value) : ''}
      </TextButton>
    </div>
  )
}

type DateTimePickerProps = {
  date?: Moment
  setDate: (d: Moment) => void
  openTo?: DateTimePickerViewsProps['openTo']
  readOnly?: boolean
  timezone?: string
} & TextProps &
  LocaleProps

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  date: selectedDate,
  setDate: setSelectedDate,
  locale = 'en',
  fmtDateAdverb,
  okLabel,
  cancelLabel,
  todayLabel,
  openTo = 'hours',
  readOnly,
  timezone,
}) => {
  const timezonedMoment = getTimezonedMoment(timezone)

  const is12HourLocale =
    timezonedMoment
      .locale(locale)
      .localeData()
      .longDateFormat('LT')
      .indexOf('A') !== -1

  return (
    <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
        <MuiDateTimePicker
          TextFieldComponent={(props) =>
            DateButton({
              ...props,
              fmtDateAdverb,
              locale,
              readOnly,
              timezone,
            })
          }
          label="Day"
          inputVariant="filled"
          value={selectedDate}
          openTo={openTo}
          format={moment.defaultFormat}
          onChange={(d) =>
            setSelectedDate(d ?? timezonedMoment.startOf('hours'))
          }
          showTodayButton
          views={['date', 'hours', 'minutes']}
          ampm={is12HourLocale}
          disablePast
          minutesStep={30}
          okLabel={okLabel}
          cancelLabel={cancelLabel}
          todayLabel={todayLabel}
          autoOk
          readOnly={readOnly}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export default DateTimePicker
