import React from 'react'
import MomentUtils from '@date-io/moment'
import moment, { Moment } from 'moment'
import {
  createMuiTheme,
  MuiThemeProvider,
  ThemeOptions,
  TextFieldProps,
} from '@material-ui/core'
import {
  TimePicker as MuiTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import adminColors from '../../../styles/_admin-colors.scss'
import './TimePicker.scss'

interface Props {
  date?: Moment
  setDate: (d: Moment) => void
}

const theme = {
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: adminColors.pickerBackground,
      },
    },
    MuiButton: {
      label: {
        color: '#000',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: adminColors.pickerBackground,
        color: '#fff',
        '&:hover': {
          backgroundColor: adminColors.pickerBackgroundHover,
          color: '#000',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Barlow',
  },
  palette: {
    background: { default: '#fff', paper: '#fff' },
  },
}

const materialTheme = createMuiTheme(theme as ThemeOptions)

const InputComponent: React.FC<TextFieldProps> = (props) => {
  const formatDate = (dateStr: string) => {
    const parsedDate = moment(dateStr)

    return `${parsedDate.format('LT')}`
  }

  return (
    <div className="TimePicker__input" onClick={props.onClick}>
      {typeof props.value === 'string' ? formatDate(props.value) : ''}
    </div>
  )
}

export const TimePicker: React.FC<Props> = ({
  date: selectedDate,
  setDate: setSelectedDate,
}) => {
  return (
    <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiTimePicker
          TextFieldComponent={(props: TextFieldProps) =>
            InputComponent({ ...props })
          }
          label="Day"
          inputVariant="filled"
          value={selectedDate}
          format={moment.defaultFormat}
          onChange={(d) => setSelectedDate(d ?? moment().startOf('hours'))}
          views={['hours', 'minutes']}
          minutesStep={30}
          ampm={false}
          // shouldDisableTime={(timeValue, clockType) => true}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export default TimePicker
