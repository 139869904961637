import { ceilDateTime } from 'common/utils/dates'
import { Moment } from 'moment'
import React, { useContext } from 'react'
import {
  DateTimePicker,
  Flex,
  FlexItem,
  Text,
} from 'shared-design/components/atoms'
import {
  LocaleContext,
  LocaleContextProps,
} from 'shared-design/components/molecules/Locales'
import {
  DispatchRoomFilterCriteriaChange,
  RoomFilterCriteria,
} from './contexts'
import './ReservationFilter.scss'

interface ReservationFilterDatePickerProps {
  date: Moment
  setDate: (dateTime: Moment) => any
  locale: LocaleContextProps
}

const ReservationFilterDateTimePicker: React.FC<
  ReservationFilterDatePickerProps
> = ({ date, setDate, locale }: ReservationFilterDatePickerProps) => {
  return (
    <DateTimePicker
      date={date}
      setDate={setDate}
      okLabel={locale.localization.pickerComponentOk}
      cancelLabel={locale.localization.pickerComponentCancel}
      todayLabel={locale.localization.pickerComponentToday}
      locale={locale.currentLanguage}
      fmtDateAdverb={(str: string) => {
        if (str === 'yesterday') {
          return locale.localization.yesterday
        } else if (str === 'today') {
          return locale.localization.today
        } else {
          return locale.localization.tomorrow
        }
      }}
    />
  )
}

export const DateTimeFilters = () => {
  const roomFilterCriteria = useContext(RoomFilterCriteria)
  const dispatchRoomFilterCriteriaChange = useContext(
    DispatchRoomFilterCriteriaChange,
  )

  const locale = useContext(LocaleContext)

  return (
    <div className="DatePickers">
      <Flex direction="column" gap="30">
        <Flex gap="30">
          <FlexItem>
            <Text tr="reservationTimespan:pre" /> &nbsp;
            <ReservationFilterDateTimePicker
              date={roomFilterCriteria.start?.clone()}
              setDate={(dateTime) => {
                const start = ceilDateTime(dateTime, 30, 'minutes')

                return dispatchRoomFilterCriteriaChange?.({
                  ...roomFilterCriteria,
                  start: start.clone(),
                  end: start.isBefore(roomFilterCriteria.end)
                    ? roomFilterCriteria.end
                    : start.clone().add(1, 'hour'),
                })
              }}
              locale={locale}
            />
          </FlexItem>
          <FlexItem>
            <Text tr="reservationTimespan:mid" /> &nbsp;
            <ReservationFilterDateTimePicker
              date={roomFilterCriteria.end?.clone()}
              setDate={(dateTime) => {
                const end = ceilDateTime(dateTime, 30, 'minutes')

                return dispatchRoomFilterCriteriaChange?.({
                  ...roomFilterCriteria,
                  start: end.isSameOrBefore(roomFilterCriteria.start)
                    ? end.clone().subtract(1, 'hour')
                    : roomFilterCriteria.start,
                  end: end.clone(),
                })
              }}
              locale={locale}
            />
          </FlexItem>
        </Flex>
      </Flex>
    </div>
  )
}

export const ReservationFilter = () => {
  return (
    <div className="ReservationFilter">
      <DateTimeFilters />
    </div>
  )
}

export default ReservationFilter
