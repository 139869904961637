import React, { useContext, createContext, useState } from 'react'
import MomentUtils from '@date-io/moment'
import classnames from 'classnames'
import moment, { Moment } from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useStyles as useDayStyles } from '@material-ui/pickers/views/Calendar/Day'
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import {
  createMuiTheme,
  MuiThemeProvider,
  ThemeOptions,
  IconButton,
} from '@material-ui/core'
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import variables from '../../../styles/_variables.scss'
import { NumberField } from '../../atoms'
import './DateRangePicker.scss'

const theme = {
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: variables.pickerBackground,
      },
    },
    MuiButton: {
      label: {
        color: '#000',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: variables.pickerBackground,
        color: '#fff',
        '&:hover': {
          backgroundColor: variables.pickerBackgroundHover,
          color: '#000',
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: variables.primaryDark,
      },
    },
  },
  typography: {
    fontFamily: 'Barlow',
  },
  palette: {
    background: { default: '#fff', paper: '#fff' },
  },
}

const useStyles = makeStyles(() => ({
  daySelected: {
    fontWeight: 500,
    backgroundColor: 'inherit',
    color: '#fff',
    '&:hover': {
      backgroundColor: variables.pickerBackgroundHover,
      color: '#000',
    },
  },
  daySelectedInMiddle: {
    backgroundColor: variables.pickerBackground,
  },
  daySelectedFirst: {
    backgroundColor: variables.pickerBackground,
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  daySelectedLast: {
    backgroundColor: variables.pickerBackground,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}))

interface LocaleProps {
  fromLabel?: React.ReactNode
  untilLabel?: React.ReactNode
  numberOfDaysLabel?: React.ReactNode
  locale?: string
}

type DateRangePickerProps = {
  startDate?: Moment
  endDate?: Moment
  setRange: (start: Moment, end: Moment) => void
} & LocaleProps

const materialTheme = createMuiTheme(theme as ThemeOptions)

type RangePickerContextProps = {
  duration: number
} & Omit<LocaleProps, 'numberOfDaysLabel'>

type RangePickerProviderProps = {
  children: React.ReactNode
} & RangePickerContextProps

const RangePickerContext = createContext<RangePickerContextProps | undefined>(
  undefined,
)
const RangePickerProvider: React.FC<RangePickerProviderProps> = ({
  duration,
  fromLabel,
  untilLabel,
  locale,
  children,
}: RangePickerProviderProps) => {
  return (
    <RangePickerContext.Provider
      value={{ duration, fromLabel, untilLabel, locale }}
    >
      {children}
    </RangePickerContext.Provider>
  )
}

const RangeToolbar: React.FC<ToolbarComponentProps> = (
  props: ToolbarComponentProps,
) => {
  const rangePickerContext = useContext(RangePickerContext)
  const duration = rangePickerContext?.duration ?? 1
  const locale = rangePickerContext?.locale?.toString() ?? 'en'
  const fmt = (date: Moment | null) =>
    date
      ? date.toDate().toLocaleDateString(locale, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          weekday: 'short',
        })
      : ''

  return (
    <div className="DateRangePicker__toolbar">
      {duration > 1 && (
        <span className="DateRangePicker__toolbarSubtitle">
          {rangePickerContext?.fromLabel ?? 'From'}
        </span>
      )}
      <span
        className={classnames('DateRangePicker__toolbarDate', {
          'DateRangePicker__toolbarDate--withoutSubtitle': duration <= 1,
        })}
      >
        {fmt(props.date)}
      </span>
      {props.date && duration > 1 && (
        <>
          <span className="DateRangePicker__toolbarSubtitle">
            {rangePickerContext?.untilLabel ?? 'Until'}
          </span>
          <span className="DateRangePicker__toolbarDate">
            {fmt(props.date.clone().add(duration - 1, 'days'))}
          </span>
        </>
      )}
    </div>
  )
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  setRange,
  fromLabel,
  untilLabel,
  numberOfDaysLabel,
  locale = 'en',
}: DateRangePickerProps) => {
  const classes = useStyles()
  const dayClasses = useDayStyles()
  const [selectedStartDate, setSelectedStartDate] = useState<Moment>(
    startDate ?? moment(),
  )
  const initialDuration = endDate ? endDate.diff(startDate, 'days') : 1
  const [durationDays, setDurationDays] = useState<number>(initialDuration)

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element,
  ) => {
    if (!day) {
      return <></>
    }

    const truncatedStart = selectedStartDate.clone().startOf('days')
    const truncatedEnd = selectedStartDate
      .clone()
      .add(durationDays - 1, 'days')
      .startOf('days')
    const isStart = day.isSame(truncatedStart)
    const isEnd = day.isSame(truncatedEnd)
    const isInMiddle = day.isAfter(truncatedStart) && day.isBefore(truncatedEnd)

    return (
      <div
        className={classnames({
          [classes.daySelectedFirst]: isStart,
          [classes.daySelectedInMiddle]: isInMiddle,
          [classes.daySelectedLast]: isEnd,
        })}
      >
        <IconButton
          className={classnames(dayClasses.day, {
            [classes.daySelected]: isStart || isInMiddle || isEnd,
            [dayClasses.hidden]: !dayInCurrentMonth,
            [dayClasses.dayDisabled]: dayComponent.props.disabled,
          })}
        >
          <span> {day.format('D')} </span>
        </IconButton>
      </div>
    )
  }

  const onChangeDate = (date?: MaterialUiPickersDate) => {
    const dateWithFallback = date ?? moment().startOf('hours')
    setSelectedStartDate(dateWithFallback)
    setRange(
      dateWithFallback,
      dateWithFallback.clone().add(durationDays - 1, 'days'),
    )
  }

  const onChangeDuration = (duration?: number) => {
    const durationWithFallback = duration ?? 1
    setDurationDays(durationWithFallback)
    setRange(
      selectedStartDate,
      selectedStartDate.clone().add(durationWithFallback - 1, 'days'),
    )
  }

  return (
    <div className="DateRangePicker">
      <MuiThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
          <RangePickerProvider
            duration={durationDays}
            fromLabel={fromLabel}
            untilLabel={untilLabel}
            locale={locale}
          >
            <MuiDatePicker
              label="Day"
              inputVariant="filled"
              value={startDate}
              openTo="date"
              format={moment.defaultFormat}
              onChange={onChangeDate}
              showTodayButton
              views={['date']}
              disablePast
              variant="static"
              renderDay={renderDay}
              ToolbarComponent={RangeToolbar}
            />
          </RangePickerProvider>
        </MuiPickersUtilsProvider>
        <NumberField
          className="DateRangePicker__duration"
          min={1}
          label={numberOfDaysLabel ?? 'Number of days'}
          value={durationDays}
          onChange={onChangeDuration}
        />
      </MuiThemeProvider>
    </div>
  )
}

export default DateRangePicker
