import React, { createContext, useState, useContext } from 'react'

interface ErrorContextProps {
  errorMessage: string
  isVisible: boolean
}

interface ErrorDispatcherProps {
  setErrorMessage: React.Dispatch<string>
  setErrorVisible: React.Dispatch<boolean>
}

const ErrorContext = createContext<ErrorContextProps | undefined>(undefined)
const ErrorDispatchContext = createContext<ErrorDispatcherProps | undefined>(
  undefined,
)

export const ErrorProvider: React.FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isVisible, setErrorVisible] = useState(false)

  return (
    <ErrorContext.Provider value={{ errorMessage, isVisible }}>
      <ErrorDispatchContext.Provider
        value={{
          setErrorMessage: (value: string) => {
            setErrorMessage(value)
            setErrorVisible(true)
          },
          setErrorVisible,
        }}
      >
        {children}
      </ErrorDispatchContext.Provider>
    </ErrorContext.Provider>
  )
}

export const useErrorState = () => {
  const context = useContext(ErrorContext)

  if (context === undefined) {
    throw new Error('useErrorState must be used within ErrorProvider')
  }

  return context
}

export const useErrorDispatch = () => {
  const context = useContext(ErrorDispatchContext)

  if (context === undefined) {
    throw new Error('useErrorDispatch must be used within ErrorProvider')
  }

  return context
}
