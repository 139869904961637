import React from 'react'
import cls from 'classnames'
import './Message.scss'
import { Dot, DotVariant } from '../Dot/Dot'

interface StatusProps {
  variant: DotVariant
  children: React.ReactNode
  title?: React.ReactNode
  pre?: boolean
  className?: string
}

export const Message: React.FC<StatusProps> = (props) => {
  const { children, title, variant, pre = false, className = '' } = props

  return (
    <div className={cls('Message', className)}>
      <div
        className={cls('Message__grid', {
          'Message__grid--short': !title,
        })}
      >
        <Dot variant={variant} />
        {title && <h3 className="Message__grid--title">{title}</h3>}
        {children && (
          <div className={cls('Message__grid--content', { pre })}>
            {children}
          </div>
        )}
      </div>
    </div>
  )
}
