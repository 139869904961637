/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import cls from 'classnames'

import './TextField.scss'
import { InputLabel } from '../InputLabel/InputLabel'
import { Flex } from '../Flex/Flex'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode
  required?: boolean
  name?: string
  helper?: React.ReactNode
  error?: boolean | string
  type?: React.HTMLInputTypeAttribute
  tooltip?: React.ReactNode
  trailing?: React.ReactNode
}

export const TextField: React.FC<Props> = ({
  label,
  required,
  helper,
  error,
  type,
  tooltip,
  trailing,
  ...props
}: Props) => {
  const showError = error && error !== true && error.length > 0

  return (
    <div className={cls('TextField')}>
      <label className="TextField__wrapper">
        <InputLabel required={required} tooltip={tooltip}>
          {label}
        </InputLabel>
        <Flex direction="row">
          <input
            className={cls('TextField__input', {
              'TextField__input--error': error,
            })}
            type={type || 'text'}
            {...props}
          />
          {trailing}
        </Flex>

        {showError && <div className="TextField__error">{error}</div>}
        {!showError && helper && (
          <div className="TextField__helper">{helper}</div>
        )}
      </label>
    </div>
  )
}

export default TextField
