import React, { useState, useEffect } from 'react'
import { User } from 'common/types'
import { TextField, StyledButton, Text } from 'shared-design/components/atoms'
import { useHistory } from 'react-router'
import { updateUserData, acceptTermsOfService } from '../../api'
import { LoginPageLayout } from './LoginPageLayout'
import {
  useUserData,
  refreshUserData,
  clearLoginStateFromLocalStorage,
} from './hooks'
import { LoginError } from './errors'
import { useErrorDispatch } from '../Error/context'

export const NewUserDetails: React.FC = () => {
  const { setErrorMessage } = useErrorDispatch()
  const [error] = useState<LoginError>()
  const [user, userError]: [User | undefined, LoginError | undefined] =
    useUserData()
  const [name, setName] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [nameAndPhoneEnabled, setNameAndPhoneEnabled] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    if (user?.name !== '' && user?.phone !== '' && user?.acceptedTerms) {
      history.push('/')
    }
    const n = user?.name || ''
    setName(n)
    const p = user?.phone || ''
    setPhone(p)
    setNameAndPhoneEnabled(n === '' || p === '')
  }, [user, history])

  const errorMessage =
    error ?? userError !== undefined ? (userError as LoginError) : undefined

  if (errorMessage) {
    setErrorMessage(errorMessage)
  }

  return (
    <LoginPageLayout>
      <h1>
        <Text tr="beforeWeStart" />
      </h1>
      <p>
        <Text
          tr={
            nameAndPhoneEnabled
              ? 'pleaseFillNameAndPhone'
              : 'pleaseAcceptTermsAndConditions'
          }
        />
      </p>
      {nameAndPhoneEnabled && (
        <>
          <TextField
            label={<Text tr="yourName" />}
            defaultValue={name}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setName(ev.target.value)
            }
            required
          />
          <TextField
            label={<Text tr="yourPhone" />}
            defaultValue={phone}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setPhone(ev.target.value)
            }
            required
          />
        </>
      )}
      <div>
        {/* TODO: replace inline style CSS */}
        <p style={{ marginTop: 0 }}>
          <Text tr="bySelectingAgree" />{' '}
          <a href="/user-terms" target="_blank" rel="noopener noreferrer">
            <Text tr="termsAndConditions" />
          </a>{' '}
          <Text tr="and" />{' '}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            <Text tr="privacyPolicy" />
          </a>
        </p>
        <StyledButton
          disabled={
            name === undefined ||
            name.length === 0 ||
            phone === undefined ||
            phone.length === 0
          }
          onClick={() => {
            if (phone && name) {
              const userUpdate = user && { ...user, phone, name }

              if (userUpdate !== undefined) {
                updateUserData(userUpdate)
                  .then(() => acceptTermsOfService())
                  .then(() => refreshUserData())
                  .then(() => history.push('/'))
                  .catch(() => {
                    clearLoginStateFromLocalStorage()
                    history.push('/')
                    setErrorMessage('unknownError')
                  })
              }
            }
          }}
        >
          <Text tr="agreeAndContinue" />
        </StyledButton>
      </div>
    </LoginPageLayout>
  )
}

export default NewUserDetails
