import React from 'react'
import { NavLink } from 'react-router-dom'
import './TextButtonNavLink.scss'

interface TextButtonNavLinkProps {
  children: React.ReactNode
  to: string
  exact?: boolean
}

const TextButtonNavLink: React.FunctionComponent<TextButtonNavLinkProps> = ({
  children,
  to,
  exact = true,
}: TextButtonNavLinkProps) => {
  return (
    <NavLink
      exact={exact}
      to={to}
      className="text-button-nav-link"
      activeClassName="text-button-nav-link--selected"
    >
      {children}
    </NavLink>
  )
}

export default TextButtonNavLink
