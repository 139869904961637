import { find } from 'ramda'
import React, { useContext, JSXElementConstructor } from 'react'
import Select, { InputActionMeta } from 'react-select'
import './StyledSelect.scss'
import classNames from 'classnames'
import { SelectComponents } from 'react-select/src/components'
import Creatable from 'react-select/creatable'
import { InputLabel } from '../InputLabel/InputLabel'
import { LocaleContext } from '../../molecules/Locales'

export const arrayToOptionProps = (
  options: readonly string[],
  current?: string | string[],
): { options: any; value: any } => {
  const opts = options.map((o) => ({ value: o, label: o }))
  const curOpt =
    current instanceof Array
      ? opts.filter((o) => current.includes(o.value))
      : opts.find((o) => o.value === current)

  return {
    options: opts,
    value: curOpt,
  }
}

type OptionType = Record<string, any>

export interface Option<T> extends OptionType {
  value: T
  label?: string
  isDisabled?: boolean
}

export interface StyledSelectProps<
  T extends OptionType,
  U extends boolean = false,
  Y extends boolean = false,
  Z = U extends true ? T[] : T,
> {
  className?: string
  inputId?: string
  options: T[]
  defaultValue?: U extends true ? T[] : T
  value?: (U extends true ? T[] : T) | null
  onChange?: (value: Y extends true ? Z | undefined : Z) => void
  variant?: 'default' | 'text_only'
  label?: React.ReactNode
  required?: boolean
  isDisabled?: boolean
  formatOptionLabel?: (option: T) => React.ReactNode
  getOptionLabel?: (option: T) => string
  getOptionValue?: (option: T) => string
  error?: string | boolean
  isMulti?: U
  isSearchable?: boolean
  isClearable?: Y
  tooltip?: React.ReactNode
  defaultMenuIsOpen?: boolean
  components?: Partial<SelectComponents<T, U>>
  filterOptions?: null | ((option: OptionType, rawInput: string) => boolean)
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
  inputValue?: string
  placeholder?: React.ReactNode
  hideIndicator?: boolean
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  creatable?: boolean
}

export function StyledSelect<
  V = string,
  T extends OptionType = Option<V>,
  U extends boolean = false,
  Y extends boolean = false,
>(props: StyledSelectProps<T, U, Y>) {
  const {
    className,
    inputId,
    options,
    defaultValue,
    value,
    onChange,
    variant = 'default',
    label,
    required,
    isDisabled,
    formatOptionLabel,
    getOptionLabel,
    getOptionValue,
    error,
    isMulti,
    isSearchable = false,
    isClearable = false,
    tooltip,
    defaultMenuIsOpen = false,
    components,
    filterOptions,
    onInputChange,
    inputValue,
    placeholder,
    hideIndicator = false,
    isOpen = undefined,
    onOpen,
    onClose,
    creatable,
  } = props

  const locale = useContext(LocaleContext)
  const defaultClassName = `StyledSelect${
    variant === 'default' ? '' : '--text-only'
  }`

  const SelectComp: JSXElementConstructor<
    typeof Select<T, U> | typeof Creatable<T, U>
  > = creatable ? Creatable<T, U> : Select<T, U>

  return (
    <div
      className={classNames(defaultClassName, className, {
        'StyledSelect--Error': error,
        'StyledSelect__hide-indicator': hideIndicator,
      })}
    >
      {label && (
        <InputLabel required={required} tooltip={tooltip}>
          {label}
        </InputLabel>
      )}

      <div className={defaultClassName}>
        <SelectComp
          inputId={inputId}
          options={options}
          defaultValue={
            // TODO: Fix any. I am defeated for now.
            defaultValue ?? ((!isClearable ? options[0] : undefined) as any)
          }
          // Typemismatch with ReactSelect but since it seems to work,
          // I did not spend time fixing it for now.
          onChange={onChange as any}
          value={value}
          isDisabled={isDisabled}
          formatOptionLabel={formatOptionLabel}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isMulti={isMulti}
          isSearchable={isSearchable}
          className={defaultClassName}
          classNamePrefix={defaultClassName}
          defaultMenuIsOpen={defaultMenuIsOpen}
          components={components}
          filterOption={filterOptions}
          onInputChange={onInputChange}
          inputValue={inputValue}
          noOptionsMessage={() => locale.localization.noOptions}
          placeholder={placeholder ?? locale.localization.select}
          isClearable={isClearable}
          menuIsOpen={isOpen}
          onMenuOpen={onOpen}
          onMenuClose={onClose}
        />
      </div>

      {error && error !== true && (
        <span className="StyledSelect__Error">{error}</span>
      )}
    </div>
  )
}

StyledSelect.defaultProps = {
  error: false,
}

/**
 * Annoying helper to handle string arrays with StyledSelect
 */
interface Selectable<T> {
  value: T
  label: T
}
export function simpleArrayToSelectable<T>(
  arr: T[] | readonly T[],
  current?: T,
): [Selectable<T>[], Selectable<T> | undefined] {
  const selectValues: Selectable<T>[] = [...arr].map(
    (item: T): Selectable<T> => ({
      value: item,
      label: item,
    }),
  )

  const currentValue = find((sv) => sv.value === current, selectValues)

  return [selectValues, currentValue]
}

export default StyledSelect
