import React from 'react'
import './MutedText.scss'

export interface MutedTextProps {
  children?: React.ReactNode
}

export const MutedText: React.FC<MutedTextProps> = (props: MutedTextProps) => {
  return <p className="MutedText">{props.children}</p>
}

export const ExtraMutedText: React.FC<MutedTextProps> = (
  props: MutedTextProps,
) => {
  return <span className="ExtraMutedText">{props.children}</span>
}
