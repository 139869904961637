import { useRouteMatch } from 'react-router'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ContentBlock,
  Message,
  StyledButton,
  Text,
} from 'shared-design/components'
import Spinner from 'shared-design/components/Spinner/Spinner'
import { ReservationInfoRelevantForUsers } from 'common/types'
import HeaderComponent from '../Header/Header'
import { cancelGuestReservation } from '../../api'
import ReservationRow from '../ReservationsList/ReservationRow'
import { defaultRequestOptions } from '../AcceptInvite/AcceptInvite'

const CancelReservation: React.FC = () => {
  const match = useRouteMatch<{ shareableId: string; credential: string }>(
    '/guest/cancel/:shareableId/:credential',
  )

  const params = {
    shareableId: match?.params.shareableId || '',
    credential: match?.params.credential || '',
  }

  const [loadingError, setLoadingError] = useState<any>()
  const [outcome, setOutcome] = useState<any>()
  const [reservationInfo, setReservationInfo] = useState<
    ReservationInfoRelevantForUsers | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (reservationInfo || isLoading) {
      return
    }

    setIsLoading(true)

    const options = {
      ...defaultRequestOptions,
      method: 'GET',
      headers: {
        ...defaultRequestOptions.headers,
      },
    } as RequestInit

    fetch(`/api/guest/reservations/${params.shareableId}`, options)
      .then((response) => response.json())
      .then((responseContent) => setReservationInfo(responseContent))
      .catch(() => {
        setLoadingError(<Text tr="reservationNotFound" />)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const cancelReservation = useCallback(async () => {
    setIsLoading(true)
    await cancelGuestReservation(params.shareableId, params.credential)
      .then((response) =>
        setOutcome(
          response.status === 200 ? (
            <Message variant="success">
              <Text tr="reservationCancelled" />
            </Message>
          ) : (
            <Message variant="error">
              <Text tr="reservationCancelingDenied" />
            </Message>
          ),
        ),
      )
      .catch(() =>
        setOutcome(
          <Message variant="error">
            <Text tr="reservationCancelingFailed" />.
            <Text tr="tryAgainLater" />
          </Message>,
        ),
      )
    setIsLoading(false)
  }, [])

  const loading = useCallback(
    (error: string) =>
      error ? (
        <div className="AcceptInviteGuestInfo__error" style={{ margin: '5em' }}>
          {error}
        </div>
      ) : (
        <Spinner margin="150px" center />
      ),
    [],
  )

  return (
    <>
      <div className="AcceptInvite">
        <HeaderComponent />
        {!reservationInfo ? (
          loading(loadingError)
        ) : (
          <div
            className="AcceptInvite__Content"
            style={{ margin: '0 auto', maxWidth: 1000 }}
          >
            <ContentBlock title={<Text tr="cancelReservation" />}>
              <ReservationRow reservationInfo={reservationInfo} hideActions />
              {outcome ? (
                <div>{outcome}</div>
              ) : (
                <StyledButton onClick={cancelReservation} color="danger">
                  <Text tr="cancelReservation" />
                </StyledButton>
              )}
            </ContentBlock>
          </div>
        )}
      </div>
    </>
  )
}

export default CancelReservation
