import React, { createContext, useState } from 'react'
import moment from 'moment'
import { ReservationBundle } from 'common/types'

export interface RoomFilterCriteria {
  peopleCount?: number
  start: moment.Moment
  end: moment.Moment
}

const defaultCriteria = {
  peopleCount: 1,
  start: moment().startOf('day').add(1, 'day').add(8, 'hours'),
  end: moment().startOf('day').add(1, 'day').add(17, 'hours'),
}

export const RoomFilterCriteria =
  createContext<RoomFilterCriteria>(defaultCriteria)

export const DispatchRoomFilterCriteriaChange = createContext<
  React.Dispatch<RoomFilterCriteria> | undefined
>(undefined)

export const UserReservations = createContext<ReservationBundle[] | undefined>(
  undefined,
)
export const DispatchUserReservations = createContext<
  React.Dispatch<ReservationBundle[] | undefined> | undefined
>(undefined)

export const RoomFilterCriteriaProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [criteria, setCriteria] = useState<RoomFilterCriteria>(defaultCriteria)

  return (
    <RoomFilterCriteria.Provider value={criteria}>
      <DispatchRoomFilterCriteriaChange.Provider value={setCriteria}>
        {children}
      </DispatchRoomFilterCriteriaChange.Provider>
    </RoomFilterCriteria.Provider>
  )
}

export const UserReservationsProvider: React.FC = ({ children }) => {
  const [userReservations, setUserReservations] = useState<
    ReservationBundle[] | undefined
  >()

  return (
    <UserReservations.Provider value={userReservations}>
      <DispatchUserReservations.Provider value={setUserReservations}>
        {children}
      </DispatchUserReservations.Provider>
    </UserReservations.Provider>
  )
}
