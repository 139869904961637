import React, { useState, useContext } from 'react'
import { TextField, Text, StyledButton, Flex } from 'shared-design/components'
import { useHistory } from 'react-router-dom'
import { LocaleContext } from 'shared-design/components/molecules/Locales'
import { LoginPageLayout } from './LoginPageLayout'
import { useEmailVerification } from './hooks'
import { useErrorDispatch } from '../Error/context'

interface EmailSentProps {
  email?: string
}

export const EmailSent: React.FC<EmailSentProps> = ({ email }) => {
  const [code, setCode] = useState<string>()
  const submitDisabled = !code?.length
  const emailVerification = useEmailVerification()
  const { setErrorMessage } = useErrorDispatch()
  const history = useHistory()
  const { localization } = useContext(LocaleContext)

  if (!email) {
    history.push('/login')
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()

        if (submitDisabled || !code || !email) return

        const loginSuccess = await emailVerification(email, code)

        if (!loginSuccess) {
          setErrorMessage(localization.loginAttemptFailed)
        }
      }}
    >
      <LoginPageLayout>
        <div>
          <h1>{localization.timeToCheckEmail}</h1>
          <p>{localization.emailSent}</p>
          <br />

          <Flex direction="column">
            <TextField
              label={localization.verificationCode}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setCode(ev.target.value)
              }}
              autoFocus
              required
            />
            <div>
              <StyledButton type="submit" disabled={submitDisabled}>
                <Text tr="continue" />
              </StyledButton>
            </div>
          </Flex>
        </div>
      </LoginPageLayout>
    </form>
  )
}

export default EmailSent
