import React, { useState, useContext, useMemo } from 'react'
import {
  StyledButton as Button,
  TextField,
  Text,
} from 'shared-design/components/atoms'
import { validateEmail } from 'common/utils/email'
import { LocaleContext } from 'shared-design/components/molecules/Locales'
import { useLogin } from './hooks'
import { LoginPageLayout } from './LoginPageLayout'
import { useErrorDispatch } from '../Error/context'
import DelayedSpinner from './DelayedSpinner'

interface SignInProps {
  onLogin: (email: string) => unknown
}
export const SignIn: React.FC<SignInProps> = ({ onLogin }) => {
  const { setErrorMessage } = useErrorDispatch()
  const [email, setEmail] = useState('')
  const [loggingInPromise, setLoadingPromise] = useState<Promise<void>>()
  const { currentLanguage } = useContext(LocaleContext)

  const login = useLogin()

  const loginDisabled = useMemo(
    () => email?.length === 0 || !/.+@.+\..+/.test(email),
    [email],
  )

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()

        if (loginDisabled) return

        const emailValidationError = validateEmail(email)
          ? undefined
          : 'invalidEmail'

        if (emailValidationError === undefined) {
          setLoadingPromise(login(email, currentLanguage))
          onLogin(email)
        } else {
          setErrorMessage(emailValidationError)
        }
      }}
    >
      <LoginPageLayout>
        <h1>
          <Text tr="startBySigningIn" />
        </h1>
        <DelayedSpinner promise={loggingInPromise}>
          <TextField
            label={<Text tr="yourWorkEmail" />}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(ev.target.value)
            }}
            autoFocus
            required
          />
          <div>
            <Button type="submit" disabled={loginDisabled}>
              <Text tr="continue" />
            </Button>
          </div>
        </DelayedSpinner>
      </LoginPageLayout>
    </form>
  )
}

export default SignIn
