import React, { useState, useMemo } from 'react'
import { Language } from 'common/types'
import { useAutoAnimate } from 'shared-design/components/hooks'
import {
  Text,
  Flex,
  TextField,
  StyledButton as Button,
} from 'shared-design/components'
import { useLocalizations } from 'shared-design/components/molecules/Locales'
import { validateEmail } from 'common/utils/email'
import { useEmailVerification } from '../Login/hooks'
import { initAuthRequest } from '../../api'

interface BehindEmailCodeProps {
  children?: React.ReactNode
  onVerified?: (email: string) => unknown
}

const BehindEmailCode: React.FC<BehindEmailCodeProps> = (props) => {
  const { children, onVerified } = props
  const [email, setEmail] = useState('')
  const loginDisabled = useMemo(
    () => email?.length === 0 || !validateEmail(email),
    [email],
  )
  const [code, setCode] = useState<string>('')
  const [step, setStep] = useState<'input_email' | 'code_sent' | 'verified'>(
    'input_email',
  )

  const emailVerification = useEmailVerification(() => {
    setStep('verified')

    if (onVerified !== undefined) onVerified(email)
  })

  const [animationParent] = useAutoAnimate()
  const { localization, currentLanguage } = useLocalizations()

  const requestCode = (userEmail: string, locale?: Language) => {
    initAuthRequest(userEmail, 'accept-invite', locale).then((response) => {
      if ('email' in response) {
        setStep('code_sent')
      }
    })
  }

  const verifyCode = async (userEmail: string, verificationCode: string) => {
    const loginSuccess = await emailVerification(userEmail, verificationCode)

    return loginSuccess
  }

  return (
    <div ref={animationParent}>
      {step === 'input_email' && (
        <Flex direction="row" style={{ alignItems: 'end' }}>
          <TextField
            label={<Text tr="email" />}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(ev.target.value.replace(/\s+/g, '').toLocaleLowerCase())
            }}
            autoFocus
            required
            trailing={
              <Button
                color="highlight"
                autoFocus
                onClick={(e) => {
                  e.preventDefault()

                  if (loginDisabled) return

                  requestCode(email, currentLanguage)
                }}
                disabled={loginDisabled}
                small
              >
                <Text tr="verify" />
              </Button>
            }
            placeholder={localization.checkInFormEmailPlaceholder}
          />
        </Flex>
      )}
      {step === 'code_sent' && (
        <Flex direction="row">
          <TextField
            label={localization.verificationCode}
            value={code}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setCode(ev.target.value)

              if (ev.target.value.length > 5) {
                verifyCode(email, ev.target.value)
              }
            }}
            autoFocus
            required
          />
        </Flex>
      )}
      {step === 'verified' && children}
    </div>
  )
}

export default BehindEmailCode
