import React from 'react'
import { StyledButton as Button, Text } from 'shared-design/components/atoms'
import { useHistory } from 'react-router'
import { LoginPageLayout } from './LoginPageLayout'

export const Welcome = () => {
  const history = useHistory()

  return (
    <LoginPageLayout>
      <div>
        <h1>
          <Text tr="welcomeTitle" />
        </h1>
        <p>
          <Text tr="welcomeMessage" />
        </p>
        <p>
          <Text tr="welcomeMessageContinued" />
        </p>
      </div>
      <Button
        color="primary"
        onClick={() => history.push('/login?step=input_email')}
        autoFocus
      >
        <Text tr="getStartedCTA" />
      </Button>
    </LoginPageLayout>
  )
}

export default Welcome
