import React, { useEffect } from 'react'
import moment from 'moment'
import * as R from 'ramda'
import Schedule from '@material-ui/icons/Schedule'
import { ceilDateTime } from 'common/utils/dates'

import './TimeRangePicker.scss'
import { StyledSelect } from '../../atoms'

interface Props {
  startTime: moment.Moment
  endTime: moment.Moment
  locale: string
  onStartTimeChange: (start: moment.Moment) => void
  onEndTimeChange: (end: moment.Moment) => void
}

interface OptionType {
  value: number
  label: string
}

const generateTimeOptions = (
  seed: moment.Moment | undefined,
  dayStart: moment.Moment,
  locale: string,
  maxMinutes: number = 23 * 60 + 30,
  stepMinutes = 30,
): OptionType[] => {
  const genArrayF = (val: OptionType): false | [OptionType, OptionType] => {
    const nextValue = val.value + stepMinutes

    return val.value > maxMinutes
      ? false
      : [
          val,
          {
            value: nextValue,
            label: moment(dayStart)
              .add(nextValue, 'minutes')
              .toDate()
              .toLocaleTimeString(locale, {
                hour: 'numeric',
                minute: 'numeric',
              }),
          },
        ]
  }

  return (
    (seed !== undefined &&
      R.unfold(genArrayF, {
        value: seed.hours() * 60 + seed.minutes(),
        label: seed
          .toDate()
          .toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' }),
      })) ||
    []
  )
}

const TimeRangePicker: React.FC<Props> = ({
  startTime,
  endTime,
  locale,
  onStartTimeChange,
  onEndTimeChange,
}) => {
  const earliestPossibleStart = startTime?.isSame(moment(), 'days')
    ? ceilDateTime(moment(), 30, 'minutes')
    : startTime.clone().startOf('day')

  const earliestPossibleEnd = (
    startTime.isAfter(earliestPossibleStart)
      ? ceilDateTime(startTime, 30, 'minutes')
      : earliestPossibleStart
  ).clone()

  const startOpts = generateTimeOptions(
    earliestPossibleStart,
    startTime.clone().startOf('day'),
    locale,
  ).filter((opt) => opt.value <= 23 * 60)

  const endOpts = generateTimeOptions(
    earliestPossibleEnd,
    startTime.clone().startOf('day'),
    locale,
  ).filter((opt) => opt.value > startTime.hours() * 60 + startTime.minutes())

  useEffect(() => {
    if (startTime.isAfter(endTime) || startTime.isSame(endTime, 'minute')) {
      if (endTime.hours() > startTime.hours()) {
        const hours = endTime.hours()
        const minutes = endTime.minutes()
        const day = startTime.clone().startOf('day')
        const updatedEndTime = day.add(hours, 'hours').add(minutes, 'minutes')
        onEndTimeChange(updatedEndTime)
      } else {
        onEndTimeChange(startTime.clone().add(30, 'minutes'))
      }
    }
  }, [startTime])

  return (
    <div className="TimeRange">
      <Schedule className="TimeRange__icon" />
      <StyledSelect
        options={startOpts}
        onChange={(opt) =>
          onStartTimeChange(
            startTime.startOf('day').clone().add(opt.value, 'minutes'),
          )
        }
        variant="text_only"
        value={
          startTime &&
          startOpts.find(
            (x) => x.value === startTime.hours() * 60 + startTime.minutes(),
          )
        }
      />
      <div className="TimeRange__separator">-</div>
      <StyledSelect
        options={endOpts}
        onChange={(opt) =>
          onEndTimeChange(
            startTime.clone().startOf('day').add(opt.value, 'minutes'),
          )
        }
        variant="text_only"
        value={
          endTime &&
          endOpts.find(
            (x) => x.value === endTime.hours() * 60 + endTime.minutes(),
          )
        }
      />
    </div>
  )
}

export default TimeRangePicker
