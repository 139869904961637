import React from 'react'
import './ContentBlock.scss'
import classNames from 'classnames'
import Titles from '../Titles'
import { MutedText } from '../MutedText/MutedText'

export interface ContentBlockProps {
  title?: React.ReactNode
  description?: React.ReactNode
  children: React.ReactNode
  className?: string
  condensed?: boolean
  topHighlight?: boolean
}

export const ContentBlock: React.FC<ContentBlockProps> = (
  props: ContentBlockProps,
) => {
  return (
    <div
      className={classNames(
        'ContentBlock',
        props.className,
        props.condensed ? 'ContentBlock--condensed' : null,
        props.topHighlight ? 'ContentBlock--topHighlight' : null,
      )}
    >
      {props.title && <Titles.H3>{props.title} </Titles.H3>}
      {props.description && <MutedText>{props.description}</MutedText>}
      {props.children}
    </div>
  )
}
