import React, { useState } from 'react'
import Spinner from 'shared-design/components/Spinner/Spinner'
import { Link } from 'react-router-dom'
import { Text } from 'shared-design/components/atoms'
import { Welcome } from './Welcome'
import { SignIn } from './SignIn'
import {
  useLoginCallbackHandler,
  useQuery,
  useLoginStep,
  LoginStep,
} from './hooks'
import { asLoginError } from './errors'
import { LoginPageLayout } from './LoginPageLayout'
import { NewUserDetails } from './NewUserDetails'
import { EmailSent } from './EmailSent'

export const LoginSuccess: React.FC = () => {
  useLoginCallbackHandler()

  return (
    <LoginPageLayout>
      <Spinner />
    </LoginPageLayout>
  )
}

export const LoginFailure: React.FC = () => {
  const error = asLoginError(useQuery().get('error'))
  const redirectLoginStep: LoginStep = 'input_email'

  return (
    <LoginPageLayout>
      <h1>
        <Text tr="loginFailed" />
      </h1>
      <p>
        <Text tr={error} />
      </p>
      <Link to={`/login?step=${redirectLoginStep}`}>
        <Text tr="returnToLogin" />
      </Link>
    </LoginPageLayout>
  )
}

export const LoginPage: React.FC = () => {
  const loginStep = useLoginStep()
  const [email, setEmail] = useState<string>()

  switch (loginStep) {
    case 'input_email':
      return <SignIn onLogin={setEmail} />

    case 'new_user':
      return <NewUserDetails />

    case 'email_sent':
      return <EmailSent email={email} />

    default:
      return <Welcome />
  }
}

export default LoginPage
