import {
  ContentBlock,
  Text,
  TextField,
  StyledButton as Button,
  Flex,
  Icon,
} from 'shared-design/components'
import React from 'react'
import { useLocalizations } from 'shared-design/components/molecules/Locales'

import './AcceptInviteGuestInfo.scss'
import BehindEmailCode from '../BehindEmailCode/BehindEmailCode'

export interface InviteeInfoValues {
  name: string
  email: string
  phone: string
}

export interface AcceptInviteGuestInfoFormProps {
  values: Partial<InviteeInfoValues>
  onChange: (values: InviteeInfoValues) => unknown
  onSubmit: () => unknown
  submitText: React.ReactNode
  error?: string
  onEmailVerified?: (email: string) => unknown
}

export const AcceptInviteInfoForm: React.FC<AcceptInviteGuestInfoFormProps> = ({
  values: { name = '', email = '', phone = '' },
  onChange,
  onSubmit,
  submitText,
  error,
  onEmailVerified,
}) => {
  const { localization } = useLocalizations()

  return (
    <Flex direction="column" center>
      <ContentBlock>
        <TextField
          required
          label={<Text tr="yourName" />}
          type="name"
          name="name"
          value={name}
          onChange={(e) =>
            onChange({
              email,
              phone,
              name: e.target.value,
            })
          }
          placeholder={localization.yourName}
          autoFocus
        />

        <BehindEmailCode
          onVerified={(verifiedEmail) => {
            onChange({
              email: verifiedEmail,
              phone,
              name,
            })

            if (onEmailVerified !== undefined) onEmailVerified(email)
          }}
        >
          <Flex direction="row">
            <h3>{email}</h3> <Icon variant="check" />
          </Flex>
        </BehindEmailCode>

        <br />

        <TextField
          required
          label={<Text tr="checkInPhone" />}
          type="tel"
          name="phone"
          value={phone}
          onChange={(e) =>
            onChange({
              email,
              phone: e.target.value,
              name,
            })
          }
          placeholder={localization.yourPhone}
        />
        {error && <div className="AcceptInviteGuestInfo__error">{error}</div>}
        <Button
          color="highlight"
          onClick={onSubmit}
          autoFocus
          disabled={!email}
        >
          {submitText}
        </Button>
      </ContentBlock>
    </Flex>
  )
}
