import { Language } from '../types'
import {
  TranslationKey,
  MoneyFormatter as MoneyFormatterType,
  MoneyWithTaxFormatter as MoneyWithTaxFormatterType,
  MoneyWithTaxFormatterProps,
} from './translations/types'
import { fullLocaleIdentifiersForLanguages, localizations } from './dictionary'

export type MoneyFormatter = MoneyFormatterType
export type MoneyWithTaxFormatter = MoneyWithTaxFormatterType

const numberFormattersByLocaleAndCurrency: Record<
  string,
  Record<string, Intl.NumberFormat>
> = {}

const getNumberFormatterForCurrency = (
  locale: string,
  currency: string,
): Intl.NumberFormat => {
  const localeEntry =
    numberFormattersByLocaleAndCurrency[locale] ??
    (numberFormattersByLocaleAndCurrency[locale] = {})
  const formatter =
    localeEntry[currency] ??
    (localeEntry[currency] = new Intl.NumberFormat(locale.replace('_', '-'), {
      style: 'currency',
      currency,
    }))

  return formatter
}

const buildMoneyFormatter =
  (defaultLocale: string, defaultCurrency: string): MoneyFormatterType =>
  (amount: number, currency?: string, locale?: string) =>
    getNumberFormatterForCurrency(
      locale ?? defaultLocale,
      currency ?? defaultCurrency,
    ).format(amount)

export const moneyFormatters = {
  fi: buildMoneyFormatter(fullLocaleIdentifiersForLanguages.fi, 'EUR'),
  en: buildMoneyFormatter(fullLocaleIdentifiersForLanguages.en, 'USD'),
  zh: buildMoneyFormatter(fullLocaleIdentifiersForLanguages.zh, 'RMB'),
  sv: buildMoneyFormatter(fullLocaleIdentifiersForLanguages.sv, 'SEK'),
}

const buildMoneyWithTaxFormatter =
  (defaultLocale: string): MoneyWithTaxFormatterType =>
  (params: MoneyWithTaxFormatterProps) => {
    const thisLocale = params.locale ?? defaultLocale
    const language = thisLocale.substr(0, 2).toLowerCase() as Language
    const taxName = localizations[language][params.taxType as TranslationKey]
    const format = (amount: number) =>
      moneyFormatters[language](amount, params.currency, thisLocale)
    const amountWithoutTax = format(params.amount)
    const amountOfTax = format(params.amount * params.taxRate)

    return `${amountWithoutTax} + ${amountOfTax} ${taxName} ${
      params.taxRate * 100
    }%`
  }

export const moneyWithTaxFormatters = {
  fi: buildMoneyWithTaxFormatter(fullLocaleIdentifiersForLanguages.fi),
  en: buildMoneyWithTaxFormatter(fullLocaleIdentifiersForLanguages.en),
  zh: buildMoneyWithTaxFormatter(fullLocaleIdentifiersForLanguages.zh),
  sv: buildMoneyWithTaxFormatter(fullLocaleIdentifiersForLanguages.sv),
}
