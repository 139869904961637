import React from 'react'
import cls from 'classnames'
import './InputLabel.scss'
import { Tooltip } from '../Tooltip/Tooltip'

export type InputLabelVariant = 'lowercase' | 'uppercase'
export interface InputLabelProps {
  children: React.ReactNode
  className?: string
  required?: boolean
  error?: boolean
  tooltip?: React.ReactNode
  variant?: InputLabelVariant
}

export const InputLabel: React.FC<InputLabelProps> = (
  props: InputLabelProps,
) => {
  return (
    <div
      className={cls(
        'InputLabel',
        props.className,
        props.error && 'InputLabel--error',
        props.variant === 'lowercase' && 'InputLabel--lower',
      )}
    >
      <span>{props.children}</span>
      {props.required ? <span className="required">*</span> : null}
      {props.tooltip && <Tooltip title={props.tooltip} />}
    </div>
  )
}
