import slugify from 'slugify'
import { pipe, sortBy } from 'ramda'
import { TranslationKey, Translations } from '../locales/dictionary'
import {
  CurrencyCode,
  Discount,
  Language,
  LegislationRegion,
  LocalizedContent,
  SomeSpacentSellables,
  SpacentSellable,
  SpacentSellableType,
  Usergroup,
} from '../types'
import { languages } from '../constants'

// TODO: use i18n library to format prices
export const formatCurrencySymbol = (currencyCode: CurrencyCode) =>
  currencyCode === 'usd' ? '$' : '€'

export const formatCurrencyCode = (currencyCode: CurrencyCode) =>
  currencyCode === 'usd' ? 'USD $' : 'EUR €'

export const formatTaxPercentage = (taxPercentage: number): string =>
  round(taxPercentage * 100, 2) + ' %'

export const formatCurrency = (
  amount: number,
  currency: string,
  locale: string,
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amount)
}
export const normalizeRedeemCode = (s: string): string =>
  s.toUpperCase().replace(/[^A-Z0-9]/g, '')

export const cloudinaryImageUrlFormatter = (
  imageUrl: string,
  options: {
    width?: number
    height?: number
    mode?: 'limit' | 'fill'
    quality?: 'auto' | 'best' | 'good' | 'eco' | 'low' | number
    roundCorners?: number
  },
): string => {
  if (!imageUrl.startsWith('https://res.cloudinary.com')) {
    return imageUrl
  }

  const sizeParam = [
    options.width && `w_${options.width}`,
    options.height && `h_${options.height}`,
    `c_${options.mode || 'limit'}`,
    `q_${options.quality || 'auto'}`,
    options.roundCorners && `r_${options.roundCorners}`,
  ]
    .filter((param) => !!param)
    .join(',')

  return imageUrl.replace('/image/upload/', `/image/upload/${sizeParam}/`)
}

export const getDiscountedTotal = (
  totalBeforeDiscount: number,
  discount?: Discount,
): number =>
  totalBeforeDiscount -
  (totalBeforeDiscount * (discount?.percentOff ?? 0)) / 100

export const getDiscountString = (discount?: Discount): string =>
  discount ? `-${discount.percentOff} %` : ''

export const getSpacentSellableTypeForOptionalOrganizationUsers = (
  organizationUsers?: number,
): SpacentSellableType => {
  if (!organizationUsers) {
    return SpacentSellableType.consumerSubscription
  }

  for (const key of Object.keys(SpacentSellableType)) {
    const matched = key.match(/^coworkingPass(\d+)persons?$/)

    if (matched && organizationUsers <= Number(matched[1])) {
      return key as SpacentSellableType
    }
  }

  throw new Error(`No sellable found for ${organizationUsers} users`)
}

export const newSpacentSellable = (
  partial: Partial<SpacentSellable>,
): SpacentSellable => ({
  stripePriceId: '',
  priceWithoutTax: 0,
  ...partial,
})

export const updateSpacentSellable = (
  spacentSellables: SomeSpacentSellables,
  field: SpacentSellableType,
  partial: Partial<SpacentSellable>,
): SomeSpacentSellables => ({
  ...spacentSellables,
  [field]: newSpacentSellable({
    ...(spacentSellables[field] ?? {}),
    ...partial,
  }),
})

export const getSpacentSellableForQuantity = (
  sellables: SomeSpacentSellables,
  organizationUsers?: number,
): SpacentSellable | undefined =>
  sellables[
    getSpacentSellableTypeForOptionalOrganizationUsers(organizationUsers)
  ]

export const getSpacentSellableFieldForQuantity = (
  region: LegislationRegion,
  field: keyof SpacentSellable,
  organizationUsers?: number,
  discount?: Discount,
): string | number => {
  const key =
    getSpacentSellableTypeForOptionalOrganizationUsers(organizationUsers)
  const priceWithoutTaxOrStripePriceId = region.prices?.[key]?.[field]

  if (!priceWithoutTaxOrStripePriceId) {
    throw new Error(
      `Missing SpacentSellable ${key}.${field} for region=${region.id} (${region.countryCode}) quantity=${organizationUsers}`,
    )
  }

  if (typeof priceWithoutTaxOrStripePriceId === 'number') {
    return getDiscountedTotal(Number(priceWithoutTaxOrStripePriceId), discount)
  } else {
    return priceWithoutTaxOrStripePriceId
  }
}

export const getPriceForPersonalOrOrganizationUsers = (
  region: LegislationRegion,
  organizationUsers?: number,
  discount?: Discount,
): number =>
  Number(
    getSpacentSellableFieldForQuantity(
      region,
      'priceWithoutTax',
      organizationUsers,
      discount,
    ),
  )

export const getFormattedTotalSubscriptionPrice = (
  quantity: number,
  region: LegislationRegion,
  localizations: Translations,
  corporateSubscription: boolean,
  showTax: boolean,
  discount?: Discount,
) => {
  return `${getPriceForPersonalOrOrganizationUsers(
    region,
    corporateSubscription ? quantity : 1,
    discount,
  )} ${localizations[region.currencyCode as TranslationKey]} / ${
    localizations.month
  }${
    showTax
      ? ` (+${localizations[region.roomTaxType]} ${
          region.countryCode === 'GB' ? 0 : region.roomTaxPercentage * 100
        } %)`
      : ''
  }`
}

export const getFormattedSubscriptionTaxTitle = (
  region: LegislationRegion,
  localizations: Translations,
) => {
  return `${localizations[region.roomTaxType]} ${
    region.countryCode === 'GB' ? 0 : region.roomTaxPercentage * 100
  } %`
}

export const rounded = (price: number) =>
  (Math.round(price * 100) / 100).toFixed(2)

export const getFormattedTotalDiscount = (
  quantity: number,
  region: LegislationRegion,
  localizations: Translations,
  corporateSubscription: boolean,
  discount?: Discount,
): string => {
  const totalBeforeDiscounts = getPriceForPersonalOrOrganizationUsers(
    region,
    corporateSubscription ? quantity : 1,
  )

  const discountValue =
    getDiscountedTotal(totalBeforeDiscounts, discount) - totalBeforeDiscounts

  return `${rounded(discountValue)} ${
    localizations[region.currencyCode as TranslationKey]
  }`
}

export const getFormattedSubscriptionTax = (
  quantity: number,
  region: LegislationRegion,
  localizations: Translations,
  discount?: Discount,
) => {
  const price = getPriceForPersonalOrOrganizationUsers(
    region,
    quantity,
    discount,
  )
  const tax =
    region.countryCode === 'GB' ? 0 : rounded(price * region.roomTaxPercentage)

  return `${tax} ${localizations[region.currencyCode as TranslationKey]}`
}

export const getFormattedSubscriptionTotal = (
  quantity: number,
  region: LegislationRegion,
  localizations: Translations,
  discount?: Discount,
) => {
  const price = getPriceForPersonalOrOrganizationUsers(
    region,
    quantity,
    discount,
  )
  const tax =
    price * (region.countryCode === 'GB' ? 0 : region.roomTaxPercentage ?? 0)
  const total = price + tax

  return `${rounded(total)} ${
    localizations[region.currencyCode as TranslationKey]
  }`
}

export const getFormattedSingleSubscriptionPrice = (
  region: LegislationRegion,
  localizations: Translations,
  corporateSubscription: boolean,
) =>
  getFormattedTotalSubscriptionPrice(
    1,
    region,
    localizations,
    corporateSubscription,
    true,
  )

export const getSubscriptionPrice = (
  region: LegislationRegion,
  localizations: Translations,
  quantity: number,
) => {
  const price = getPriceForPersonalOrOrganizationUsers(region, quantity)

  return `${rounded(price)} ${
    localizations[region.currencyCode as TranslationKey]
  }\n+${localizations[region.roomTaxType]} ${region.roomTaxPercentage * 100}%${
    localizations.perMonth
  }`
}
export const getTax = (
  region: LegislationRegion,
  localizations: Translations,
) => `$+${localizations[region.roomTaxType]} ${region.roomTaxPercentage * 100}%`

/**
 * Formats given string into url-friendly way.
 * keywords: slugify, machine name, tokenize, sanitize
 */
export const formatToUrlSafe = (toFormat: string, trim = false): string => {
  return slugify(toFormat, { lower: true, trim })
}

export const round = (num: number, fractions = 0): number => {
  return Number(num.toFixed(fractions))
}

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const redactEmails = (text: string): string =>
  text.replace(
    /(\w\S+)(@\w[\w-]+\.[\w-]+)/gim,
    (_, name, domain) => '*'.repeat(name.length) + domain,
  )

export const redactPhoneNumbers = (text: string): string =>
  text.replace(
    /(\+\d{3})([\d ]*)(\d{2}\b)/gm,
    (_, head, middle, tail) => head + '*'.repeat(middle.length) + tail,
  )

export const redactIPv4Addresses = (text: string): string =>
  text.replace(
    /(\d{1,3})(\.\d{0,4}){2,4}/gm,
    (_, first, rest) => first + rest.replace(/\d/g, '*'),
  )

export const redactIPv6Addresses = (text: string): string =>
  text.replace(
    /\b([0-9a-f]{1,4})((:[0-9a-f]{0,4}){1,7})\b/gim,
    (_, first, rest) =>
      first + (rest.match(/::|((:\w+){3,})/) ? rest.replace(/\w/g, '*') : rest),
  )

export const redactPrivacyTexts: (text: string) => string = pipe(
  redactEmails,
  redactPhoneNumbers,
  redactIPv4Addresses,
  redactIPv6Addresses,
)

export const getLocalizedContent = <T>(
  content: LocalizedContent<T>,
  lang: Language,
  fallback: T,
): T => {
  return (
    content[lang] ||
    (content.default && content[content.default ?? 'en']) ||
    (content[
      Object.keys(content).filter((key) =>
        languages.includes(key as Language),
      )[0] as Language
    ] as T) ||
    fallback
  )
}

export const getCurrentUnixTimestamp = (): number => {
  return +new Date() / 1000
}

export const sortUsergroupForSelect = (usergroups: Usergroup[]): Usergroup[] =>
  sortBy((u) => `${u.organizationName}_${u.name}`, usergroups)

export const getUsergroupSelectLabel = (u: Usergroup) => {
  const ugName = u.name || 'Empty usergroup name'

  if (u.organizationName === u.name) return ugName

  if (!u.organizationName) return ugName

  return `${ugName} - ${u.organizationName}`
}

/**
 * For some reason eslint does not allow overriding @typescript-eslint/no-unused-vars
 * in function parameters, so we need to use this workaround.
 */
export const noopIgnoreUnusedParamsForEslint = (...params: any[]) => {
  return params
}

export const countryCodeToEmoji = (countryCode: string) => {
  const emojiOffset = 127397
  const codeUnits = countryCode
    .toUpperCase()
    .split('')
    .map((char) => emojiOffset + char.charCodeAt(0))

  return String.fromCodePoint(...codeUnits)
}
