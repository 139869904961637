import React from 'react'
import SnackBar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Text } from 'shared-design/components/atoms'
import { TranslationKey } from 'common/locales/dictionary'
import { useErrorState, useErrorDispatch } from './context'
import './Error.scss'

export const Error: React.FC = () => {
  const { errorMessage, isVisible } = useErrorState()
  const { setErrorVisible } = useErrorDispatch()

  const handleClose = () => {
    setErrorVisible(false)
  }

  return (
    <SnackBar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isVisible}
      key="error-message"
    >
      <SnackbarContent
        className="errorDisplay"
        message={<Text tr={errorMessage as TranslationKey} />}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </SnackBar>
  )
}

export default Error
