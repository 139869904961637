import { GetMeResponse } from 'common/types/users'
import { sendCollectUserAnalyticsPermissionEvent } from 'common/utils/common-web-analytics-packets'
import { useHistory, useLocation } from 'react-router'
import { useState, useEffect } from 'react'
import { Language, User } from 'common/types'
import { initAuthRequest, fetchUserData, verifyEmail } from '../../api'
import { LoginError } from './errors'
import { isApiError } from '../../api/errors'
import { useErrorDispatch } from '../Error/context'

export const loginSteps = [
  'welcome',
  'input_email',
  'email_sent',
  'email_activation',
  'new_user',
] as const

export type LoginStep = typeof loginSteps[number]

export const useLogin = (): ((
  email: string,
  locale?: Language,
) => Promise<void>) => {
  const history = useHistory()
  const { setErrorMessage, setErrorVisible } = useErrorDispatch()

  const login = (email: string, locale?: Language) =>
    initAuthRequest(email, 'session', locale)
      .then((response) => {
        if ('token' in response) {
          localStorage.setItem('accessToken', response.token)
          history.push('/')
          setErrorVisible(false)
        } else if ('url' in response) {
          window.location.href = `${response.url}?clientType=web`
        } else if ('email' in response) {
          const emailSent: LoginStep = 'email_sent'
          history.push(`/login?step=${emailSent}`)
        } else {
          setErrorMessage('unknownError')
        }
      })
      .catch((err) => {
        const errorName =
          isApiError(err) && err.status === 403
            ? 'organizationNotFound'
            : 'unknownError'

        setErrorMessage(errorName)
      })

  return login
}

export const useQuery = () => new URLSearchParams(useLocation().search)

export const useQueryAccessToken = (): {
  accessToken?: string
  error?: LoginError
} => {
  const accessToken = useQuery().get('access_token')

  const error: LoginError | undefined =
    typeof accessToken !== 'string' ? 'invalidAccessToken' : undefined

  return {
    accessToken: error === undefined ? accessToken ?? undefined : undefined,
    error,
  }
}

export const useLoginCallbackHandler = () => {
  const history = useHistory()
  const { accessToken, error } = useQueryAccessToken()

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken)
      history.push('/')
    } else {
      const qs = error ? `?error=${error}` : ''
      history.push(`/login/failure${qs}`)
    }
  }, [accessToken, history, error])
}

export const useLoginStep = () => {
  const queryStep = useQuery().get('step')
  const loginStep: LoginStep =
    loginSteps.find((step) => step === queryStep) ?? 'welcome'

  return loginStep
}

export const refreshUserData = async () => {
  const userData = await fetchUserData()
  localStorage.setItem('user', JSON.stringify(userData))
}

export const clearLoginStateFromLocalStorage = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('user')
}

/**
 * FetchUserData says that it returns User, but it actually returns GetMeResponse.
 * This transforms the type accordingly with out having to refactor the whole
 * Web Reservation types for now.
 */
export const sendUserAnalyticsPermissionEventFromUserType = (
  user: User | GetMeResponse,
) => {
  const getMeResponse = user as unknown as GetMeResponse
  sendCollectUserAnalyticsPermissionEvent({
    locationDetectionAllowed:
      getMeResponse.organization.locationDetectionAllowed,
    showOnlyOrganizationRooms:
      getMeResponse.organization.showOnlyOrganizationRooms,
    storeOnlyUserEmail: getMeResponse.organization.storeOnlyUserEmail,
  })
}

export const useUserData = (): [User | undefined, LoginError | undefined] => {
  const [error, setError] = useState<LoginError>()
  const [user, setUser] = useState<User>()
  const history = useHistory()
  useEffect(() => {
    if (user === undefined) {
      fetchUserData()
        .then((userData) => {
          localStorage.setItem('user', JSON.stringify(userData))
          sendUserAnalyticsPermissionEventFromUserType(userData)
          setUser(userData)
        })
        .catch(() => {
          clearLoginStateFromLocalStorage()
          history.push('/')
          setError('userDataFetchingFailed')
        })
    }
  }, [user, history])

  return [user, error]
}

export const useEmailVerification = (callback?: () => unknown) => {
  const history = useHistory()
  const [, setError] = useState()

  const token = useQuery().get('token')

  return async (email: string, code: string): Promise<boolean> => {
    return verifyEmail({ token, email, code })
      .then((accessToken) => {
        localStorage.setItem('accessToken', accessToken)

        if (callback === undefined) {
          history.push('/')
        } else {
          callback()
        }

        return true
      })
      .catch((err) => {
        setError(err)

        return false
      })
  }
}
