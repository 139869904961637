export const validateEmail = (email: string): boolean => {
  const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

  return emailRegex.test(email)
}

export const getDomainFromEmail = (email: string): string => email.split('@')[1]

export const normalizeUserEmail = (email?: string): string => {
  return email?.toLowerCase().trim() ?? ''
}

export const obscureEmail = (email: string): string => {
  const atIndex = email.lastIndexOf('@')
  const [name, domain] = [email.slice(0, atIndex), email.slice(atIndex + 1)]

  const obscuredName =
    name.length > 1 ? name[0] + '*'.repeat(name.length - 1) : '*'

  return `${obscuredName}@${domain}`
}
