import React, { useEffect, useState, useContext } from 'react'
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom'
import Spinner from 'shared-design/components/Spinner/Spinner'

import { LocaleContext } from 'shared-design/components/molecules/Locales'
import { UserReservableRoom } from 'common/types'
import { ApiError } from '../api/errors'
import { fetchSpaces, fetchUserReservations } from '../api'
import './SpacesPage.scss'
import ContentContainer from './SpacesPage/ContentContainer'
import SpaceDetails from './SpacesPage/SpaceDetails'
import SpacesList from './SpacesPage/SpacesList'
import { useErrorDispatch } from './Error/context'
import { clearLoginStateFromLocalStorage } from './Login/hooks'
import {
  DispatchUserReservations,
  UserReservations,
  RoomFilterCriteria,
} from './SpacesPage/contexts'

const SpacesPage: React.FunctionComponent = () => {
  const { currentLanguage } = useContext(LocaleContext)
  const roomFilterCriteria = useContext(RoomFilterCriteria)
  const { setErrorMessage } = useErrorDispatch()
  const [spaces, setSpaces] = useState<UserReservableRoom[]>()
  const history = useHistory()

  const match = useRouteMatch<{ roomId: string }>('/spaces/:roomId')
  const dispatchUserReservations = useContext(DispatchUserReservations)
  const userReservations = useContext(UserReservations)

  const selectedSpace = match
    ? spaces?.find((space) => space.id === Number(match.params.roomId))
    : undefined

  useEffect(() => {
    Promise.all([
      fetchSpaces(
        currentLanguage,
        roomFilterCriteria.start,
        roomFilterCriteria.end,
      ),
      fetchUserReservations(),
    ])
      .then((results) => {
        const [receivedSpaces, receivedReservations] = results
        // While web reservation time aimed to fit only own spaces filter out others
        const availableReceivedSpaces = receivedSpaces.filter(
          (r) => r.ownOrganization,
        )
        setSpaces(availableReceivedSpaces)

        if (dispatchUserReservations) {
          dispatchUserReservations(receivedReservations)
        }
      })
      .catch((reason: ApiError) => {
        setSpaces([])

        if (dispatchUserReservations) {
          dispatchUserReservations([])
        }
        setErrorMessage(reason.message ?? 'Unknown error')

        if (reason.tag === 'auth_error') {
          clearLoginStateFromLocalStorage()
          history.push('login?step=input_email')
        }
      })
  }, [
    currentLanguage,
    roomFilterCriteria,
    dispatchUserReservations,
    history,
    setErrorMessage,
  ])

  return (
    <ContentContainer>
      {spaces === undefined || userReservations === undefined ? (
        <div>
          <Spinner margin="100px" center />
        </div>
      ) : (
        <Switch>
          <Route path="/spaces/:roomId">
            {selectedSpace !== undefined ? (
              <SpaceDetails space={selectedSpace} />
            ) : (
              ''
            )}
          </Route>
          <Route path="/spaces">
            <SpacesList spaces={spaces} />
          </Route>
        </Switch>
      )}
    </ContentContainer>
  )
}

export default SpacesPage
