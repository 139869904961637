import React, { useEffect, useContext, useState } from 'react'
import {
  Flex,
  ContentBlock,
  Message,
  Text,
  StyledCheckbox,
  DisabledBlock,
} from 'shared-design/components'
import { sanitizePhone, isValidPhoneNumber } from 'common/utils/validation'
import { useRouteMatch } from 'react-router'
import { LocaleContext } from 'shared-design/components/molecules/Locales'
import AppStoreLinks from 'shared-design/components/molecules/AppStoreLinks/AppStoreLinks'
import Spinner from 'shared-design/components/Spinner/Spinner'
import {
  ReservationInfoRelevantForUsers,
  RoomLocalizations,
} from 'common/types'
import { useAutoAnimate } from 'shared-design/components/hooks'
import { TranslationKey } from 'common/locales/dictionary'
import HeaderComponent from '../Header/Header'
import ReservationRow from '../ReservationsList/ReservationRow'
import { AcceptInviteInfoForm } from './AcceptInviteGuestInfo'

import './AcceptInvite.scss'
import { acceptTermsOfService } from '../../api'
import { useConfiguration } from 'shared-design/components/contexts/ConfigurationProvider'

export const defaultRequestOptions = {
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
}

const AcceptInvite: React.FC = () => {
  const match = useRouteMatch<{ shareableId: string }>(
    '/guest/accept-invite/:shareableId',
  )

  const params = {
    shareableId: match?.params.shareableId || '',
  }

  const locale = useContext(LocaleContext)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  // const [marketingAccepted, setMarketingAccepted] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [errorOnCreate, setErrorOnCreate] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [emailVerified, setEmailVerified] = useState<boolean>(false)
  const { config } = useConfiguration()

  // const extractTranslation = (obj: any) => {
  //   return obj[locale.currentLanguage] || obj.en
  // }

  const [view, setView] = useState<'accept-invite' | 'reservation-info'>(
    'accept-invite',
  )
  const [animationParent] = useAutoAnimate()
  const [originalReservationInfo, setOriginalReservationInfo] = useState<
    ReservationInfoRelevantForUsers | undefined
  >(undefined)
  const [reservationInfo, setReservationInfo] = useState<
    ReservationInfoRelevantForUsers | undefined
  >(undefined)
  const [roomLocalizations, setRoomLocalizations] = useState<
    RoomLocalizations | undefined
  >(undefined)
  const [canAcceptInvite, setCanAcceptInvite] = useState<boolean | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    // perhaps we should create a text field type that passed through
    // only the correct values for a phone
    // for now I will re-use the existing function
    if (phone) setPhone(sanitizePhone(phone) as string)
  })

  useEffect(() => {
    if (reservationInfo?.shareableId || isLoading) {
      return
    }

    setIsLoading(true)

    const options = {
      ...defaultRequestOptions,
      method: 'GET',
      headers: {
        ...defaultRequestOptions.headers,
      },
    } as RequestInit

    let isErrorSet = false
    const fetchFailed = (message: string) => {
      isErrorSet = true
      setError(message)
    }

    Promise.all([
      fetch(`/api/guest/reservations/${params.shareableId}`, options)
        .then((response) => response.json())
        .then((decodedResponse) => {
          setOriginalReservationInfo(decodedResponse)
        })
        .catch(() => {
          if (!isErrorSet) {
            fetchFailed(locale.localization.inviterReservationUnavailable)
          }
        }),
      fetch(`/api/guest/invite-available/${params.shareableId}`, options)
        .then((response) => response.json())
        .then((decodedResponse) => {
          setRoomLocalizations(decodedResponse.localizedRoomInfo)
          setCanAcceptInvite(decodedResponse.inviteAvailable)
        })
        .catch(() => fetchFailed(locale.localization.inviteUnavailable)),
    ]).then(() => setIsLoading(false))
  }, [])

  const onSubmit = () => {
    if (!name) {
      return setError(locale.localization.checkInFormNameError)
    }

    if (!emailVerified) {
      return setError(locale.localization.verifyYourEmail)
    }

    if (!isValidPhoneNumber(phone)) {
      return setError(locale.localization.checkInFormPhoneError)
    }

    setError('')
    setIsLoading(true)

    const token = localStorage.getItem('accessToken')

    if (!emailVerified || !token) {
      setError(locale.localization.verifyYourEmail)
      setIsLoading(false)

      return null
    }

    const options = {
      ...defaultRequestOptions,
      method: 'POST',
      body: JSON.stringify({
        name,
        phone,
        email,
        shareableId: reservationInfo?.shareableId,
        locale: locale.currentLanguage,
      }),
      headers: {
        ...defaultRequestOptions.headers,
        Authorization: `Bearer ${token}`,
      },
    } as RequestInit

    fetch(`/api/reservations/accept-invite/${params.shareableId}`, options)
      .then(async (response) => {
        if (response.status === 200) {
          const responseBody = await response.json()

          sendInvitedGuestInfoToHubspot({
            name,
            email,
            phoneNumber: phone,
            url: config.FRONTEND_HUBSPOT_INVITE_URL,
            reservationStart: responseBody.reservationStart,
            locationName: responseBody.locationName,
          })

          setReservationInfo(responseBody)
          setView('reservation-info')

          return
        } else {
          const explanations: Record<number, TranslationKey> = {
            0: 'unknownReason',
            400: 'missingEmail',
            402: 'invitationsAllSpent',
            403: 'duplicateInvitationAccept',
            404: 'missingInvitation',
            409: 'roomIsFull',
          }
          const explanation =
            locale.localization[
              explanations[response.status] ?? explanations[0]
            ]
          setErrorOnCreate(
            `${locale.localization.reservationFailed}: ${explanation}`,
          )
        }

        return null
      })
      .catch((reason) => {
        setErrorOnCreate(
          `${locale.localization.reservationFailed}. ${
            locale.localization.tryAgainLater
          } (${reason.message ?? reason})`,
        )
      })
      .finally(() => {
        setIsLoading(false)
      })

    return null
  }

  const bigErrorView = (message: string) => {
    return (
      <div className="AcceptInviteGuestInfo__error" style={{ margin: '5em' }}>
        {message}
      </div>
    )
  }

  const getTermsAndRegistrationForm = () => {
    return (
      <>
        <div style={{ padding: '0 2rem 0 2rem' }}>
          <ContentBlock condensed>
            <Flex
              direction="row"
              wrap="nowrap"
              style={{ alignItems: 'center' }}
            >
              <StyledCheckbox
                value={termsAccepted}
                onChange={(_, value) => setTermsAccepted(value)}
              />
              <p>
                <Text tr="inviteIAgreeTo" />{' '}
                <a href="/user-terms" target="_blank" rel="noopener noreferrer">
                  <Text tr="userTerms" />
                </a>{' '}
                <Text tr="and" />{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text tr="privacyPolicy" />
                </a>
                <Text tr="agreeToRest" />
              </p>
            </Flex>
          </ContentBlock>
        </div>

        <br />

        <div style={{ padding: '0 2rem 0 2rem' }}>
          <DisabledBlock disabled={!termsAccepted}>
            <AcceptInviteInfoForm
              values={{
                name,
                email,
                phone,
              }}
              onChange={(v) => {
                setName(v.name)
                setEmail(v.email)
                setPhone(v.phone.replace(/[^+0-9]/g, ''))
              }}
              onSubmit={onSubmit}
              error={error}
              submitText={<Text tr="acceptInvite" />}
              onEmailVerified={async () => {
                setEmailVerified(true)
                await acceptTermsOfService()
              }}
            />
          </DisabledBlock>
        </div>
        <br />
      </>
    )
  }

  const getCurrentView = (): React.ReactNode => {
    if (
      roomLocalizations === undefined ||
      canAcceptInvite === undefined ||
      originalReservationInfo === undefined
    ) {
      return error ? bigErrorView(error) : <Spinner margin="150px" center />
    }

    if (canAcceptInvite === false) {
      return (
        <div
          className="AcceptInvite__Content"
          style={{ margin: '0 auto', maxWidth: 1000 }}
        >
          <Flex direction="row" gap="30">
            <Message variant="warning" title={<Text tr="inviteUnavailable" />}>
              <Text tr="inviterNoInvitesLeft" />
            </Message>
          </Flex>
        </div>
      )
    }

    if (view === 'accept-invite') {
      return (
        <div
          className="AcceptInvite__Content"
          style={{ margin: '0 auto', maxWidth: 1000 }}
          ref={animationParent}
        >
          <div style={{ padding: '0 2rem 0 2rem' }}>
            <ContentBlock title={<Text tr="youHaveBeenInvited" />} condensed>
              <ReservationRow
                reservationInfo={originalReservationInfo}
                hideActions
              />
            </ContentBlock>
          </div>

          <br />

          {errorOnCreate
            ? bigErrorView(errorOnCreate)
            : getTermsAndRegistrationForm()}

          <div style={{ padding: '0 2rem 0 2rem' }}>
            <ContentBlock title={<Text tr="onboardingDownload" />}>
              <AppStoreLinks />
            </ContentBlock>
          </div>
          <br />
        </div>
      )
    }

    return reservationInfo === undefined ? (
      <Spinner margin="150px" center />
    ) : (
      <div
        className="AcceptInvite__Content"
        style={{ margin: '0 auto', maxWidth: 1000 }}
      >
        <Flex direction="row" gap="30">
          <Message variant="success" title="Success">
            <p>
              <Text tr="inviteMoreInfoInEmail" />
            </p>
            <ContentBlock>
              <ReservationRow reservationInfo={reservationInfo} hideActions />
            </ContentBlock>
          </Message>
          <ContentBlock title={<Text tr="onboardingDownload" />}>
            <AppStoreLinks />
          </ContentBlock>
        </Flex>
      </div>
    )
  }

  useEffect(() => {
    document.title = locale.localization.acceptInvitePageTitle
  }, [])

  return (
    <div className="AcceptInvite">
      <HeaderComponent />
      {isLoading ? <Spinner margin="150px" center /> : getCurrentView()}
    </div>
  )
}

const sendInvitedGuestInfoToHubspot = async (args: {
  name: string
  email: string
  phoneNumber: string
  reservationStart: string
  locationName: string
  url?: string
}): Promise<void> => {
  const { name, email, phoneNumber, reservationStart, locationName, url } = args

  const hubspotInvitedGuestFormUrl =
    url ||
    'https://api.hsforms.com/submissions/v3/integration/submit/9133795/00783e09-1330-4e4b-bf1d-189c5f89172b'

  const [firstname, lastname] = name.split(' ')

  const formBody = {
    fields: [
      {
        name: 'email',
        value: email,
      },
      {
        name: 'firstname',
        value: firstname,
      },
      {
        name: 'lastname',
        value: lastname,
      },
      {
        name: 'phone',
        value: phoneNumber,
      },
      {
        name: 'reservationStart',
        value: reservationStart,
      },
      {
        name: 'locationName',
        value: locationName,
      },
    ],
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Spacent to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 14716456,
            text: 'I agree to receive one to one emails from Spacent.',
          },
          {
            value: true,
            subscriptionTypeId: 11371715,
            text: 'I agree to receive marketing communications from Spacent related to my request.',
          },
        ],
      },
    },
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formBody),
  } as RequestInit

  await fetch(hubspotInvitedGuestFormUrl, requestOptions)
}

export default AcceptInvite
