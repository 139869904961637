import React from 'react'
import { languages } from 'common/constants'
import { localizations } from 'common/locales/dictionary'
import { components, IndicatorProps } from 'react-select'
import { useLocalizations } from '../../molecules/Locales'
import StyledSelect from '../StyledSelect'
import { Icon } from '../Icon/Icon'
import './LocaleSelector.scss'

const DropdownIndicator = (props: IndicatorProps<any, any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon variant="language" />
    </components.DropdownIndicator>
  )
}

export const LocaleSelector: React.FC = () => {
  const { currentLanguage, changeLanguage, localization } = useLocalizations()

  return (
    <div className="LocaleSelector">
      <StyledSelect
        options={languages
          .filter((language) => language !== 'zh' && language !== 'sv')
          .map((language) => ({
            label: localizations[language][language],
            value: language,
          }))}
        onChange={(newLanguage) => changeLanguage(newLanguage.value)}
        value={{
          label: localization[currentLanguage],
          value: currentLanguage,
        }}
        components={{ DropdownIndicator }}
      />
    </div>
  )
}

export default LocaleSelector
