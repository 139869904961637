import { useState, useEffect } from 'react'
import { Building } from 'common/types'
import { fetchBuilding } from '../../api'
import { ApiError } from '../../api/errors'

export const useBuilding = (
  buildingId: number,
  locale: string,
): [Building | undefined, ApiError | undefined] => {
  const [building, setBuilding] = useState<Building>()
  const [error, setError] = useState<ApiError>()

  useEffect(() => {
    if (building === undefined) {
      const f = async () => {
        try {
          setBuilding(await fetchBuilding(buildingId, locale))
        } catch (err) {
          setError(err as ApiError)
        }
      }

      f()
    }
  }, [building, buildingId, locale])

  return [building, error]
}

export default useBuilding
