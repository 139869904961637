import classNames from 'classnames'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import React from 'react'
import { SecondaryText } from '../SecondaryTextButton/SecondaryText'
import './Link.scss'

export const Link: typeof ReactRouterDomLink = (props) => {
  const { className, children, ...restProps } = props

  return (
    <ReactRouterDomLink
      className={classNames('Link', className)}
      {...restProps}
    >
      <SecondaryText>{children}</SecondaryText>
    </ReactRouterDomLink>
  )
}
