import React, { useContext } from 'react'
import { TranslationKey } from 'common/locales/dictionary'
import { LocaleContextProps, LocaleContext } from '../../molecules/Locales'
import { SecondaryText } from '../SecondaryTextButton/SecondaryText'

interface TextProps {
  tr: TranslationKey
  required?: boolean
}
const Text: React.FC<TextProps> = ({ tr, required }: TextProps) => {
  const lc: LocaleContextProps = useContext(LocaleContext)

  return (
    <>
      {lc.localization[tr] || tr}
      {required && <SecondaryText>*</SecondaryText>}
    </>
  )
}

export default Text
