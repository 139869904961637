import { User } from 'common/types'
import React from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import {
  Text,
  TextButton,
  TextButtonNavLink,
} from 'shared-design/components/atoms'
import { LocaleProvider } from 'shared-design/components/molecules'
import { logoutRequest } from './api'
import './App.scss'
import { ErrorProvider } from './components/Error/context'
import { Error } from './components/Error/Error'
import GuestCheckInComponent from './components/GuestCheckIn/GuestCheckIn'
import GuestCheckInSuccessComponent from './components/GuestCheckIn/GuestCheckinSuccess'
import { Header } from './components/Header/Header'
import {
  clearLoginStateFromLocalStorage,
  sendUserAnalyticsPermissionEventFromUserType,
} from './components/Login/hooks'
import {
  LoginFailure,
  LoginPage,
  LoginSuccess,
} from './components/Login/LoginPage'
import ReservationsList from './components/ReservationsList/ReservationsList'
import SpacesPage from './components/SpacesPage'
import {
  RoomFilterCriteriaProvider,
  UserReservationsProvider,
} from './components/SpacesPage/contexts'
import AcceptInvite from './components/AcceptInvite/AcceptInvite'
import CancelReservation from './components/Reservations/Cancel'

const checkTokenExists = () => localStorage.getItem('accessToken') !== null

const isUser = (v: any): v is User => v?.email && v?.name && v?.acceptedTerms

const findUser = (): User | null => {
  const str = localStorage.getItem('user')
  const json: Record<string, string> | null = str && JSON.parse(str)
  const user: User | null = isUser(json) ? json : null

  return user
}

function App() {
  return (
    <LocaleProvider>
      <ErrorProvider>
        <div className="App">
          <Error />
          <Switch>
            <Route path="/guest/check-in/success">
              <GuestCheckInSuccessComponent />
            </Route>
            <Route path="/guest/check-in/:shareableId">
              <GuestCheckInComponent />
            </Route>
            <Route path="/guest/accept-invite/:shareableId">
              <AcceptInvite />
            </Route>
            <Route path="/guest/cancel/:shareableId/:credential">
              <CancelReservation />
            </Route>

            <Route path="/login/failure">
              <LoginFailure />
            </Route>
            <Route path="/login/success">
              <LoginSuccess />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/">
              <UserReservationsProvider>
                <MainContainer />
              </UserReservationsProvider>
            </Route>
          </Switch>
        </div>
      </ErrorProvider>
    </LocaleProvider>
  )
}

function MainContainer() {
  const history = useHistory()
  const user = findUser()

  if (!checkTokenExists()) {
    return <Redirect to="/login" />
  } else if (
    user === null ||
    user.name === '' ||
    user.phone === '' ||
    !user.acceptedTerms
  ) {
    return <Redirect to="/login?step=new_user" />
  } else if (user) {
    sendUserAnalyticsPermissionEventFromUserType(user)
  }

  return (
    <div className="container">
      <Header>
        <TextButtonNavLink to="/spaces">
          <Text tr="spaces" />
        </TextButtonNavLink>
        <TextButtonNavLink to="/reservations">
          <Text tr="reservations" />
        </TextButtonNavLink>
        <TextButton
          onClick={() => {
            logoutRequest().then(() => {
              clearLoginStateFromLocalStorage()
              history.push('/login')
            })
          }}
        >
          <Text tr="logOut" />
        </TextButton>
      </Header>
      <div className="content">
        <Switch>
          <Route path="/spaces">
            <RoomFilterCriteriaProvider>
              <SpacesPage />
            </RoomFilterCriteriaProvider>
          </Route>
          <Route path="/reservations">
            <ReservationsList />
          </Route>
          <Route path="/">
            <Redirect to="/spaces" />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default App
