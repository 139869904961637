import { ReservedSlot, UserReservableRoom } from 'common/types'
import { isFree } from 'common/utils/pricing'
import moment from 'moment'
import { groupBy, prop, toPairs } from 'ramda'
import React, { useCallback, useContext, useMemo } from 'react'
import { Text } from 'shared-design/components/atoms'
import { RoomFilterCriteria } from './contexts'
import Grid from './Grid'
import { ReservationFilter } from './ReservationFilter'
import ReservedSpace from './ReservedSpace'
import SpaceCard from './SpaceCard'
import './SpacesList.scss'

interface SpacesListProps {
  spaces?: UserReservableRoom[]
}

const SpacesList: React.FunctionComponent<SpacesListProps> = ({ spaces }) => {
  const roomFilterCriteria = useContext(RoomFilterCriteria)

  const rangeStart = useMemo(
    () => moment(roomFilterCriteria.start?.clone()),
    [roomFilterCriteria],
  )
  const rangeEnd = useMemo(
    () => moment(roomFilterCriteria.end?.clone()),
    [roomFilterCriteria],
  )

  const duration = useMemo(
    () => moment.duration(rangeStart?.diff(rangeEnd) ?? 0),
    [rangeStart, rangeEnd],
  )

  const overlappingReservationFilter = useCallback(
    (reservation: ReservedSlot) => {
      const reservationStart = moment.utc(reservation.start)
      const reservationEnd = moment.utc(reservation.end)

      return (
        reservationStart.isBetween(rangeStart, rangeEnd) ||
        reservationEnd.isBetween(rangeStart, rangeEnd)
      )
    },
    [rangeStart, rangeEnd],
  )

  const reservableSpaces = useMemo(
    () =>
      spaces?.filter((space) => {
        const capacityExceeded =
          (roomFilterCriteria.peopleCount ?? 0) > space.capacity
        const overlappingTimespans = space.reservations.some(
          overlappingReservationFilter,
        )

        const maxReservationDurationExceeded =
          (space.maxReservationMinutes !== undefined &&
            (duration.minutes() ?? 0) > space.maxReservationMinutes) ||
          false

        const timeToReservation = moment.duration(
          roomFilterCriteria.start.diff(moment()),
        )
        const hrsToReservation = timeToReservation.asHours()

        const invalidTimeAdvanceForReservation =
          (space.maxReservableAdvanceHours !== undefined &&
            hrsToReservation > space.maxReservableAdvanceHours) ||
          (space.minReservableAdvanceHours !== undefined &&
            hrsToReservation < space.minReservableAdvanceHours) ||
          false

        const violatesCapacityRoomCriteria =
          (roomFilterCriteria.peopleCount ?? 0) > 1

        const violatesRoomCriteria =
          (space.reservationType === 'capacity'
            ? violatesCapacityRoomCriteria
            : overlappingTimespans) || invalidTimeAdvanceForReservation

        const pricing = space.pricingData ?? {
          type: 'byLength',
          rules: space.pricing,
        }
        const isFreeSpace = isFree(pricing)

        return (
          !capacityExceeded &&
          !maxReservationDurationExceeded &&
          !violatesRoomCriteria &&
          isFreeSpace
        )
      }) ?? [],
    [spaces, roomFilterCriteria, overlappingReservationFilter, duration],
  )

  const reservableSpacesByLocation = useMemo(
    () => toPairs(groupBy(prop('building'), reservableSpaces)),
    [reservableSpaces],
  )

  return (
    <div>
      <>
        <ReservationFilter />
        <ReservedSpace />
        {reservableSpaces.length === 0 ? (
          <h2 className="SpacesList__no-available-spaces">
            <Text tr="noAvailableSpaces" />
          </h2>
        ) : (
          reservableSpacesByLocation.map(([location, spacesPerLocation]) => (
            <div key={location}>
              <h2 className="SpacesList__LocationTitle">{location}</h2>
              <Grid cellWidth="265px" gap="2.5rem">
                {spacesPerLocation.map((space) => (
                  <SpaceCard
                    startTime={rangeStart}
                    endTime={rangeEnd}
                    space={space}
                    key={space.id}
                    largestImageWidth={540}
                  />
                ))}
              </Grid>
            </div>
          ))
        )}
      </>
    </div>
  )
}
export default SpacesList
