import React, { useState } from 'react'
import './Spinner.scss'

export interface SpinnerProps {
  center?: boolean
  margin?: string
  delay?: number
  debug?: string
  small?: boolean
}

const Spinner: React.FunctionComponent<SpinnerProps> = (
  props: SpinnerProps,
) => {
  const margin = props.margin || '0'
  const delay = props.delay ?? false
  const [showSpinner, setShowSpinner] = useState(!delay)
  const debug = process.env.NODE_ENV === 'development' && props.debug

  React.useEffect(() => {
    if (debug) {
      // eslint-disable-next-line no-console
      console.debug(`${showSpinner ? '' : '!'}Spinner(${props.debug})`)
    }

    if (!delay) {
      return
    }

    const timeout = setTimeout(() => setShowSpinner(true), delay)

    return () => {
      setShowSpinner(false)
      clearTimeout(timeout)
    }
  }, [delay])

  return showSpinner ? (
    <div
      className="spinner"
      style={{
        margin: props.center ? `${margin} auto` : `${margin}`,
        color: 'red',
        textAlign: 'center',
        ...(props.small ? { width: '1em', height: '1em' } : {}),
      }}
    >
      {debug || <>&nbsp;</>}
    </div>
  ) : null
}

export default Spinner
