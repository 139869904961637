import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, LabelText, Titles, Text } from 'shared-design/components/atoms'
import { UserReservableRoom } from 'common/types'
import { cloudinaryImageUrlFormatter } from 'common/utils/format'
import { countMaxCapacityUse } from 'common/utils/capacity'
import { Moment } from 'moment'
import CoverImage from './CoverImage'
import IconText from './IconText'
import './SpaceCard.scss'

interface SpaceCardProps {
  startTime: Moment
  endTime: Moment
  space: UserReservableRoom
  largestImageWidth?: number | undefined
}

const SpaceCard: React.FunctionComponent<SpaceCardProps> = (
  props: SpaceCardProps,
) => {
  const { startTime, endTime, space, largestImageWidth } = props

  const { roomImageUrls, name, description, floor, capacity } = space
  const imageUrl = roomImageUrls.length > 0 && roomImageUrls[0]
  const history = useHistory()

  let capacityText = `${capacity}`
  let canReserve = true

  if (space.reservationType === 'capacity') {
    const maxUse = countMaxCapacityUse(space.reservations, startTime, endTime)
    capacityText = `${maxUse} / ${capacity}`
    canReserve = maxUse < capacity
  }

  return (
    <div className="space-card">
      <Link to={`/spaces/${space.id}`} className="space-card__link">
        <div className="space-card__image-wrapper">
          {imageUrl ? (
            <CoverImage
              src={cloudinaryImageUrlFormatter(imageUrl, {
                width: largestImageWidth,
                mode: 'fill',
              })}
            />
          ) : null}
        </div>
        <div className="space-card__description-wrapper">
          <Titles.H3 secondary>{name}</Titles.H3>
          <div className="space-card__info-label-wrapper">
            <LabelText>
              <IconText icon="floors" title="Floor">
                {floor}
              </IconText>
              <IconText icon="person" title="Capacity">
                {capacityText}
              </IconText>
            </LabelText>
          </div>
          {description ? <p>{description}</p> : null}
        </div>
        <div className="space-card__cta-wrapper">
          <Button
            onClick={() => history.push(`/spaces/${space.id}`)}
            disabled={!canReserve}
          >
            <Text tr="reserve" />
          </Button>
        </div>
      </Link>
    </div>
  )
}

SpaceCard.defaultProps = {
  largestImageWidth: undefined,
}

export default SpaceCard
