import React from 'react'
import './ContentContainer.scss'

interface ContentContainerProps {
  children: React.ReactNode
  background?: string
}

const ContentContainer: React.FunctionComponent<ContentContainerProps> = ({
  background,
  children,
}: ContentContainerProps) => {
  return (
    <div
      className="content-container"
      style={{
        background: `${background}`,
      }}
    >
      <div className="content-container__content">{children}</div>
    </div>
  )
}

ContentContainer.defaultProps = {
  background: '',
}

export default ContentContainer
