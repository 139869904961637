import React from 'react'
import classNames from 'classnames'
import { IconVariant, Icon } from '../Icon/Icon'
import './StyledButton.scss'

type ButtonColor = 'primary' | 'secondary' | 'highlight' | 'danger'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor
  active?: boolean
  big?: boolean
  small?: boolean
  icon?: IconVariant
}

export const StyledButton: React.FC<Props> = ({
  color = 'primary',
  big,
  small,
  active,
  icon,
  children,
  ...props
}: Props) => (
  <div
    className={classNames(
      'StyledButton',
      big && 'StyledButton__big',
      small && 'StyledButton__small',
      active && 'StyledButton__active',
    )}
  >
    <button className={color} type="button" {...props}>
      <div className="StyledButton__children">
        {icon && <Icon variant={icon} />}
        {children}
      </div>
    </button>
  </div>
)

export default StyledButton
