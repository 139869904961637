import React from 'react'
import ALink from '../ALink'

interface GoogleMapsLinkProps {
  query: string
  children: React.ReactNode
  stopPropagation?: boolean
  target?: string
}

const GoogleMapsLink: React.FC<GoogleMapsLinkProps> = ({
  query,
  children,
  ...props
}) => (
  <ALink
    href={`https://www.google.com/maps/search/?api=1&query=${query}`}
    stopPropagation={props.stopPropagation}
    target={props.target}
  >
    {children}
  </ALink>
)
export default GoogleMapsLink
