export const loginErrors = [
  'organizationNotFound',
  'invalidAccessToken',
  'userDataFetchingFailed',
  'emailTokenVerificationFailed',
  'unknownError',
] as const

export type LoginError = typeof loginErrors[number]

export const asLoginError = <T>(v: T): LoginError =>
  (typeof v === 'string' && loginErrors.find((err) => err === v)) ||
  'unknownError'
