import moment, { Moment } from 'moment'

export const generateTimeFormatters = (timeFormats: Record<string, any>) => {
  const timeFormatters: Record<string, (ts: Date | Moment | string) => string> =
    {}

  Object.keys(timeFormats)
    .filter((key) => typeof timeFormats[key] === 'string')
    .forEach((key) => {
      const name = key.replace('moment', 'format').replace('Format', '')
      timeFormatters[name] = (ts: Date | Moment | string) =>
        moment(ts).format(timeFormats[key])
    })

  return timeFormatters
}
