import autoAnimate from '@formkit/auto-animate'
import { useRef, useEffect } from 'react'

export const useAutoAnimate = () => {
  const parent = useRef(null)

  useEffect(
    (config?: Parameters<typeof autoAnimate>[1]) => {
      parent.current && autoAnimate(parent.current as any, config)
    },
    [parent],
  )

  return [parent]
}
