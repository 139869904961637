import {
  Building,
  Language,
  ReservationBundle,
  ReservationOptions,
  TokenIntent,
  User,
  UserReservableRoomWithOrganization,
} from 'common/types'
import { GetAuthResponse } from 'common/types/auth'
import {
  CheckInResponse,
  CheckInUserRequest,
  CheckInUserRequestResponse,
} from 'common/types/checkin'
import moment, { Moment } from 'moment'
import { apiErrorFromResponse } from './errors'

const defaultRequestOptions = {
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
}

const apiRequest = async (path: string, method = 'GET', body?: string) => {
  const token = localStorage.getItem('accessToken')
  const options = {
    ...defaultRequestOptions,
    method,
    body,
    headers: {
      ...defaultRequestOptions.headers,
      Authorization: `Bearer ${token}`,
    },
  } as RequestInit

  return fetch(`/api${path}`, options)
}

export const initAuthRequest = async (
  email: string,
  intent: TokenIntent,
  locale?: Language,
): Promise<GetAuthResponse> => {
  const response = await fetch(
    `/api/auth?clientType=web&locale=${locale}&v=2`,
    {
      ...defaultRequestOptions,
      method: 'POST',
      body: JSON.stringify({ email, intent }),
    } as RequestInit,
  )

  return response.ok
    ? response.json()
    : Promise.reject(apiErrorFromResponse(response))
}

export const logoutRequest = async () => {
  return apiRequest('/auth/logout', 'POST')
}

export const fetchGuestCheckIn = async (
  shareableId: string,
): Promise<CheckInResponse> => {
  const response = await fetch(`/api/guest/check-in/${shareableId}`, {
    ...defaultRequestOptions,
    method: 'GET',
  } as RequestInit)

  return response.ok
    ? response.json()
    : Promise.reject(apiErrorFromResponse(response))
}

export const checkInRequest = async (
  props: CheckInUserRequest,
): Promise<CheckInUserRequestResponse> => {
  const response = await fetch(`/api/guest/check-in/${props.shareableId}`, {
    ...defaultRequestOptions,
    method: 'POST',
    body: JSON.stringify(props),
  } as RequestInit)

  return response.ok
    ? response.json()
    : Promise.reject(apiErrorFromResponse(response))
}

export const fetchSpaces = async (
  locale: Language,
  start?: Moment,
  end?: Moment,
): Promise<UserReservableRoomWithOrganization[]> => {
  const startEpoch: number =
    start?.valueOf() ||
    moment.utc().startOf('day').subtract(1, 'months').valueOf()
  const endEpoch: number =
    end?.valueOf() || moment.utc().endOf('day').add(1, 'months').valueOf()

  const response = await apiRequest(
    `/rooms?locale=${locale}&startTime=${startEpoch}&endTime=${endEpoch}`,
  )

  return response.ok
    ? response.json()
    : Promise.reject(apiErrorFromResponse(response, 'spacesFetchError'))
}

export const fetchUserReservations = async (
  start?: Moment,
  end?: Moment,
): Promise<ReservationBundle[]> => {
  const startEpoch =
    start?.valueOf() ||
    moment.utc().startOf('day').subtract(1, 'months').valueOf()
  const endEpoch =
    end?.valueOf() || moment.utc().endOf('day').add(1, 'months').valueOf()

  const response = await apiRequest(
    `/reservations?startTime=${startEpoch}&endTime=${endEpoch}`,
  )

  return response.ok
    ? response.json()
    : Promise.reject(apiErrorFromResponse(response, 'reservationsFetchError'))
}

export const createReservation = async (
  reservationDetails: ReservationOptions & { roomId: number },
) => {
  const response = await apiRequest(
    '/reservations',
    'POST',
    JSON.stringify(reservationDetails),
  )

  return response.ok
    ? response.json()
    : Promise.reject(
        apiErrorFromResponse(
          response,
          (await response.text()) ?? 'reservationCreationError',
        ),
      )
}

// TODO: handle bad request (reservation in past / clock sync anomaly)
export const cancelReservation = async (reservationId: number) => {
  return apiRequest(`/reservations/${reservationId}`, 'DELETE')
}

export const cancelGuestReservation = async (
  shareableId: string,
  credential: string,
) => {
  return apiRequest(
    `/guest/reservations/${shareableId}/${credential}`,
    'DELETE',
  )
}

export const fetchUserData = async (): Promise<User> => {
  const response = await apiRequest('/users/me')

  return response.ok
    ? response.json()
    : Promise.reject(apiErrorFromResponse(response))
}

export const updateUserData = async (user: User): Promise<void> => {
  await apiRequest(
    '/users/me',
    'PUT',
    JSON.stringify({ name: user.name, phone: user.phone }),
  )
}

export const acceptTermsOfService = async (): Promise<void> => {
  await apiRequest('/users/acceptedterms', 'POST')
}

export const verifyEmail = async (params: {
  token: string | null
  email: string
  code: string
}): Promise<string> => {
  const response = await apiRequest(
    '/auth/email/verify?clientType=web',
    'POST',
    JSON.stringify(params),
  )

  return response.ok
    ? response.json().then((authToken) => authToken.token)
    : Promise.reject(apiErrorFromResponse(response))
}

export const fetchBuilding = async (
  buildingId: number,
  locale: string,
): Promise<Building> => {
  const response = await apiRequest(
    `/buildings/${buildingId}?locale=${locale}`,
    'GET',
  )

  return response.ok
    ? response.json()
    : Promise.reject(apiErrorFromResponse(response))
}

export default initAuthRequest
