import React from 'react'
import SpacentLogo from 'shared-design/components/atoms/SpacentLogo'
import './Header.scss'
import colors from 'shared-design/styles/_colors.scss'
import { LocaleSelector } from 'shared-design/components/atoms'
import ContentContainer from '../SpacesPage/ContentContainer'

interface HeaderProps {
  children?: React.ReactNode
}

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { children } = props

  return (
    <ContentContainer background={colors.canonizedGrayBgDark}>
      <nav className="header">
        <div className="header__logo">
          <SpacentLogo />
        </div>
        <div className="header__links">
          {children}
          <LocaleSelector />
        </div>
      </nav>
    </ContentContainer>
  )
}

export default Header
