import React from 'react'

interface CoverImageProps {
  src: string
  position?: string
}

const CoverImage: React.FunctionComponent<CoverImageProps> = ({
  src,
  position,
}: CoverImageProps) => {
  return (
    <div
      className="cover-image"
      style={{
        width: '100%',
        height: '100%',
        background: `url(${src}) no-repeat ${position}`,
        backgroundSize: 'cover',
      }}
    />
  )
}

CoverImage.defaultProps = {
  position: 'center center',
}

export default CoverImage
