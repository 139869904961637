import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Icon, IconVariant } from '../Icon/Icon'
import './SecondaryText.scss'

interface SecondaryTextProps {
  icon?: IconVariant
  children?: ReactNode
  variant?: 'yellow' | 'blue'
}
export const SecondaryText: React.FC<SecondaryTextProps> = (
  props: SecondaryTextProps,
) => {
  const variant = props.variant || 'yellow'

  return (
    <span className={classNames('SecondaryText', variant)}>
      {props.icon && <Icon variant={props.icon} />}
      {props.children && (
        <span className="SecondaryText__Text">{props.children}</span>
      )}
    </span>
  )
}
