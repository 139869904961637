import React from 'react'
import { Header } from '../Header/Header'

import styles from './LoginPage.module.scss'

export const LoginPageLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className={styles.LoginPage}>
    <Header />
    <div className={styles.LoginPage__wrapper}>
      <div className={styles.LoginPage__container}>
        <div className={styles.LoginPage__content}>{children}</div>
      </div>
    </div>
  </div>
)

export default LoginPageLayout
