import React, { useEffect, useState } from 'react'
import Spinner from 'shared-design/components/Spinner/Spinner'
import { pickReservationInfoRelevantForUsers } from 'common/utils/reservation'
import moment from 'moment'
import { ReservationBundle } from 'common/types'
import { useHistory } from 'react-router'
import * as R from 'ramda'
import { Text } from 'shared-design/components/atoms'
import { Divider } from '@material-ui/core'
import { ApiError } from '../../api/errors'
import { useErrorDispatch } from '../Error/context'
import { fetchUserReservations } from '../../api'
import ReservationRow from './ReservationRow'
import { clearLoginStateFromLocalStorage } from '../Login/hooks'

import './ReservationsList.scss'

const ReservationsList: React.FunctionComponent = () => {
  const [reservations, setReservations] = useState<ReservationBundle[]>()
  const history = useHistory()
  const { setErrorMessage } = useErrorDispatch()
  const sortDescByDate = (a: ReservationBundle, b: ReservationBundle) =>
    moment(b.reservation.start).valueOf() -
    moment(a.reservation.start).valueOf()

  useEffect(() => {
    fetchUserReservations()
      .then((newReservations) => {
        setReservations(newReservations)
      })
      .catch((reason: ApiError) => {
        setReservations([])
        setErrorMessage(reason.message ?? 'Unknown error')

        if (reason.tag === 'auth_error') {
          clearLoginStateFromLocalStorage()
          history.push('login?step=input_email')
        }
      })
  }, [history, setErrorMessage])

  return (
    <div className="ReservationsList">
      {R.ifElse(
        R.isNil,
        () => <Spinner margin="100px" center />,
        R.pipe(
          R.sort(sortDescByDate),
          R.map((reservationBundle) => (
            <>
              <ReservationRow
                key={reservationBundle.reservation.id}
                reservationInfo={pickReservationInfoRelevantForUsers(
                  reservationBundle,
                )}
              />
              <Divider style={{ backgroundColor: '#39444b' }} />
            </>
          )),
          R.when(R.isEmpty, () => (
            <h2 className="ReservationsList__no-reservations">
              <Text tr="noReservations" />
            </h2>
          )),
        ),
      )(reservations)}
    </div>
  )
}

export default ReservationsList
