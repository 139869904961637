import React from 'react'
import { ContentBlock, Flex, Text } from 'shared-design/components'
import HeaderComponent from '../Header/Header'
import './GuestCheckInSuccess.scss'

const GuestCheckInSuccess = () => {
  return (
    <div className="GuestCheckInSuccess">
      <HeaderComponent />

      <Flex direction="column" gap="30">
        <div className="GuestCheckInSuccess__content">
          <div className="GuestCheckInSuccess__container">
            <div className="GuestCheckInSuccess__header">
              <Text tr="checkInSuccessHeader" />
            </div>
            <ContentBlock>
              <Text tr="checkInSuccessDesc" />
            </ContentBlock>
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default GuestCheckInSuccess
