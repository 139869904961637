import { Language } from '../types'
import {
  TranslationKey as TranslationKeyType,
  Translations as TranslationsType,
} from './translations/types'
import { en } from './translations/en'
import { fi } from './translations/fi'
import { zh } from './translations/zh'
import { sv } from './translations/sv'

// This is exported like this since eslint prettier/prettier did not support
// export type ... from syntax, or it was some other version mismatch.
export type Translations = TranslationsType
export type TranslationKey = TranslationKeyType

export const localizations: Record<Language, Translations> = { en, fi, zh, sv }

export const isValidLanguage = (locale: string): boolean =>
  !!localizations[locale as Language]

export const momentDateTimeFormat = {
  fi: fi.timeFormats.momentDateTimeFormat,
  en: en.timeFormats.momentDateTimeFormat,
  zh: zh.timeFormats.momentDateTimeFormat,
  sv: sv.timeFormats.momentDateTimeFormat,
} as const

export const momentDateFormat = {
  fi: fi.timeFormats.momentDateFormat,
  en: en.timeFormats.momentDateFormat,
  zh: zh.timeFormats.momentDateFormat,
  sv: sv.timeFormats.momentDateFormat,
} as const

export const momentTimeFullFormat = {
  fi: fi.timeFormats.momentTimeFullFormat,
  en: en.timeFormats.momentTimeFullFormat,
  zh: zh.timeFormats.momentTimeFullFormat,
  sv: sv.timeFormats.momentTimeFullFormat,
} as const

export const momentTimeHourFormat = {
  fi: fi.timeFormats.momentTimeHourFormat,
  en: en.timeFormats.momentTimeHourFormat,
  zh: zh.timeFormats.momentTimeHourFormat,
  sv: sv.timeFormats.momentTimeHourFormat,
} as const

export const weekDayAbbreviations = {
  fi: fi.timeFormats.weekDayAbbreviations,
  en: en.timeFormats.weekDayAbbreviations,
  zh: zh.timeFormats.weekDayAbbreviations,
  sv: sv.timeFormats.weekDayAbbreviations,
} as const

export const fullLocaleIdentifiersForLanguages: Record<Language, string> = {
  fi: 'fi-FI',
  en: 'en-IN',
  zh: 'zh-CN',
  sv: 'sv-SE',
}
