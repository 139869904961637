/**
 * Define common web analytics events and variables here.
 * Note: Variables can be any object, but events have to have `event: string` in them.
 *
 * @see https://developers.google.com/tag-manager/devguide
 */
import { sendWebAnalyticsPacket } from './web-analytics'

export function sendCollectUserAnalyticsPermissionEvent(event: {
  locationDetectionAllowed: boolean
  storeOnlyUserEmail: boolean
  showOnlyOrganizationRooms: boolean
}) {
  sendWebAnalyticsPacket({
    event: 'CollectUserAnalyticsPermissionEvent',
    ...event,
  })
}
