import { ReservationBundle, ReservationInfoRelevantForUsers } from '../types'

export const pickReservationInfoRelevantForUsers = (
  bundle: ReservationBundle,
  pickIds = true,
): ReservationInfoRelevantForUsers => {
  const { reservation, room } = bundle

  const ids = pickIds ? { roomId: room.id, reservationId: reservation.id } : {}

  return {
    roomName: room.name,
    roomAddress: room.address,
    roomImageUrls: room.roomImageUrls,
    shareableId: reservation.shareableId,
    reservationStart: reservation.start,
    reservationEnd: reservation.end,
    locationName: room.building,
    roomTimezone: room.timezone,
    ...ids,
  }
}
