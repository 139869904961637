import { en } from './en'
import { Translations } from './types'
import { generateTimeFormatters } from './generators'

export const constantStrings = {
  // Languages
  en: '英语',
  fi: '芬兰语',
  zh: '中文',

  // general
  language: '语言',
  ok: '确认',
  // todo
  close: 'Close',
  reserve: '预订',
  cancel: '取消',
  cancelReservation: '取消预订',
  continue: '继续',
  and: '以及',
  unknownError:
    '发生未知问题，请再试一次.\n如果问题仍然存在，请联系hello@spacent.com。',
  hide: '隐藏',
  select: '选择...',
  other: '其他...',
  noOptions: '无其他选项',
  closeWithoutSaving: '关闭并不保存',
  yes: '是',
  no: '否',
  save: '保存',
  saveInformation: '保存信息',
  search: '搜索...',
  clear: '清除',

  // dates
  yesterday: '昨天',
  today: '今天',
  tomorrow: '明天',
  reservationOnDate: '您在此日期有一个预订',

  // nav
  reservations: '预订',
  spaces: '办公空间',
  logOut: '登出',
  // nav -- admin
  myOrganization: '我的公司',
  locations: '办公地点',
  usergroups: '用户群组',
  settings: '设置',
  basicInformation: '基本信息',
  billing: '账单',

  // payment methods
  cash: '现金支付',
  invoice: '发票',
  hostInvoice: '电子账单发票',
  subscription: '订阅',
  creditCard: '信用卡',

  // login
  yourWorkEmail: '您的工作邮箱',
  welcomeTitle: '欢迎加入Spacent!',
  welcomeMessage: '预订工作桌，会议室或者项目室，只需几步。',
  loginFailed: '登录失败.',
  getStartedCTA: '开始',
  returnToLogin: '返回登录页面',
  organizationNotFound: '您还没有成为我们的客户。\n请联系hello@spacent.com。',
  emailTokenVerificationFailed:
    '电子邮件验证失败.\n请再试一次。如果问题仍然存在, 请联系hello@spacent.com。',
  userDataFetchingFailed:
    '无法加载所需的信息。\n.如果问题仍然存在, 请联系hello@spacent.com。',
  startBySigningIn: '在登录之前.',
  beforeWeStart: '开始之前.',
  pleaseFillNameAndPhone:
    '请填写您的姓名和电话号码，用于与大堂接待员联系和确认。',
  pleaseAcceptTermsAndConditions: '请阅读条款和条件并接受',
  yourName: '您的姓名',
  yourPhone: '您的电话号码',
  agreeAndContinue: '同意并继续',
  bySelectingAgree: '选择同意并继续，表示我同意Spacent。',
  termsAndConditions: '条款和条件 ',
  privacyPolicy: '隐私政策',
  invalidAccessToken: '无效的访问码.',
  invalidEmail: '请核对您的电子邮件地址。',

  // reserving
  'reservationTimespan:pre': '预定从',
  'reservationTimespan:mid': '至',
  reservationDays: '天数',
  reservationHours: '小时',
  tooLongReservationPeriod: '您可以最多预定这个办公空间 {amount} {units}.',
  // reserving -- admin
  tooManyReservationsForCapacity: '同时在线预定的数量超出了空间的限制',

  // reservation confirmation
  confirmReservation: '确认预订',
  confirmReservationAddress: '地址',
  confirmReservationTimespan: '预定时间',
  healthCommitment: '健康承诺',
  alreadyReservedThis: '您已经在所选的这段时间内预定了这个办公空间',
  alreadyReservedSpace: '在所选的这段的时间段内，您已经预订了:',

  // reservations -- admin
  bookingCalendar: '预定日历',
  calendarIntegrationError:
    '我们无法从您的公司日历系统中获取数据，这可能由于缺少事件引起。',
  noPaymentMethodsError: '无法创建事件：所选空间未启用任何付款方式',

  // reservations dialog -- admin
  createReservation: '创建预订',
  viewReservation: '查看预订',
  reservationWhere: '预订地点',
  reservationStart: '开始',
  reservationEnd: '结束',
  reservedFor: '保留',
  reserveFor: '为他人预订',
  organization: '公司',
  paymentMethod: '付款方式',
  invoiceReference: '发票参考号码',
  reservationNotes: '备注',
  cateringInformation: '餐饮信息',
  addCateringItem: '添加',
  reservationRoomPrice: '房间价格',
  reservationTotalPrice: '总价',
  updateReservation: '更新预订',
  myOrganizationInvoiceDetailsMissing:
    '公司发票相关信息丢失。请在"我的公司"标签下添加，并通过以下地址support@spacent.com与我们联系。',

  // spaces
  noAvailableSpaces: '很抱歉，没有符合您设置条件的空间。',

  // reservations
  noReservations: '您没有任何有效的预订。',

  // my organization -- admin
  nameAndBusinessId: '名称以及公司ID',
  organizationName: '公司名称',
  businessIdLabel: '公司ID /增值税发票代码',
  authenticationType: '认证方式',
  authConfigured: '公司认证已由Spacent团队通过。如需更改，请通过以下方式。',
  creditCardPayment: '信用卡付款',
  pdfInvoice: '发送一封电子发票到我的邮箱',
  pdfInvoiceReceiver: '邮箱地址',
  useInvoicingOperator: '使用电子发票第三方',
  eInvoiceOperator: '电子发票第三方',
  eInvoiceAddress: '电子发票地址',
  invoiceContact: '联络邮箱地址',
  changeBillingMethod: '如果您想更改结账方式，请联系',
  cardholderName: '持卡人',
  cardholderEmail: '账单接收邮箱',
  cardNumber: '卡号',
  cardExpiration: '到期',
  cardCvc: 'CVC码',
  creditCardDisclaimer:
    '银行卡将通过Stripe系统进行安全交易，并且我们接受信用卡付款。',
  paymentInfoUpdated: '付款信息已成功更新.',
  billingMethod: '账单方式',
  updateCard: '更新',
  deleteCard: '删除',
  authorizePendingCardPayment: 'Authorize pending credit card payment',
  pendingAmount: 'Pending amount',
  paymentCard: 'Payment card',
  cardEndingIn: 'ending in',
  paymentAuthorizationSuccess: 'Payment authorized successfully',
  paymentAuthorizationFail: 'Payment authorization failed',

  // locations -- admin
  allLocations: '所有地点',
  announcements: '公告',
  buildingManagement: '大厦管理',
  addNewLocation: '添加新的地点',
  operatorName: '地点名称',
  address: '地址',
  seeExample: '看例子',

  // location editor -- admin
  locationAdded: '已添加新的地点🎉！',
  locationAddError: '添加您的地点时出现问题😭, 请您重新确认地点信息。 ',
  locationSaved: '已保存您的地点! 🎉! ',
  locationSaveError: '更新您的地点时出现问题😭, 请您重新确认地点信息。',
  locationEdit: '编辑地点',
  locationAdd: '添加新地点',
  generalInformation: '基本信息',
  operatorNameHelper: '在应用程序中显示的名称',
  addressHelper: '街道地址，邮政编码和城市名称',
  operatorNameExists: '已经存在',
  coordinates: '协调',
  latitude: '纬度',
  longitude: '经度',
  selectOnMap: '在地图上选择',
  similarLocation: '相似地点',
  nearbyLocation: '在距当前地点不到30米处，还有另一处地点。',
  locationInfo: '地点信息',
  lobbyServicesOpeningHours: '大堂服务',
  lobbyNotAvailable: '不可用',
  lobbyAlwaysOpen: '全天',
  contactInfo: '联系方式',
  description: '描述',
  locationDescriptionPlaceholder:
    '请填写位置地点的描述以及相关服务，例如：附近的餐厅，咖啡馆，公共交通，停车位，办公空间的概念，联系信息，以及COVID-19相关的措施等。',
  arrivalInfo: '到达信息',
  arrivalInfoPlaceholder:
    '在访客预订后显示的信息，例如：如何进入地点，门禁码，主要的操作说明等。',
  healthCommitmentTitle: '标题',
  healthCommitmentTitlePlaceholder: '健康承诺',
  healthCommitmentContent: '内容',
  healthCommitmentContentPlaceholder:
    '请仔细检查以下清单，以确保您要预订的空间中的每个人都保持健康和安全。',
  healthCommitmentChecks: '最新检查方式',
  healthCommitmentChecksPlaceholder1: '我没有任何COVID-19症状。',
  healthCommitmentChecksPlaceholder2:
    '我最近14天内没有与确认COVID-19感染的人接触。',
  healthCommitmentChecksPlaceholder3: '我过去14天没有去过受限制的国家。',
  healthCommitmentNoChecks: '无需检查',
  healthCommitmentAddCheck: '添加检查',

  phone: 'Phone',
  phoneHelper: '电话格式, 比如+12345678',
  sms: '短信号码',
  smsHelper: '电话格式, 比如 +12345678',
  email: '邮箱',
  sendBookingNotifications: '向此地址发送电子邮件预订通知。',
  bookingNotificationLanguage: '推送通知的语言',
  openingHours: '营业时间',
  openingHoursDescription: '地点的营业时间将覆盖地点中所有的单一空间。',
  wifi: 'Wi-Fi',
  wifiName: 'Wi-Fi 名称',
  wifiPassword: 'Wi-Fi 密码',
  locationPhoto: '图片',
  onlyCoverPhoto: '只有封面图像会被显示.',
  issuesInForm: '上述表单项存在问题，请检查.',
  dragPinToEntrance: '将指针移至地点入口',
  searchByAddress: '按地址搜索',
  amenities: '便利设施',
  locationGeneralInformationHelp: '位置基本信息会在楼宇信息页面显示。',
  operatorNameHelp:
    '代表贵空间理念的位置名称将在列表显示。当然，所在楼宇的名称也可作为位置名称。',
  coordinatesHelp: '在地图上标记出贵空间的入口位置。',
  amenitiesHelp:
    '勾选包含在预订价格中的配套设施。若某些设施使用需额外付费，请选择“使用需额外收费”项。',
  locationInfoHelp:
    '位置描述将在楼宇信息页面显示。请填写位置描述信息，例如：该位置附近的餐厅或咖啡馆、公共交通枢纽、停车场等其他有效位置指引信息以及该地区实施的COVID-19防控措施。',
  arrivalInfoHelp:
    '到达信息将发送给完成预定的访客。有效到达信息能够引导访客找到空间所在位置并顺利进入。例如：请您至一楼大厅办理登记手续，并核实您在Spacent平台的预订信息',
  healthCommitmentHelp:
    '健康承诺是访客完成预订时的健康状况核实。他们需要勾选健康状况一栏中所有框才能成功预订。您可以根据所在地区的COVID-19政策随时修改需要核实的健康信息。',
  lobbyServicesOpeningHoursHelp:
    '大厅服务开放时间将在楼宇信息页面显示。该服务取决于所在位置的托管大厅服务开放时间，与贵处预订空间可用时间段无关。',
  contactInfoHelp:
    '联系人信息将在楼宇信息页面显示。请提供有效联系人信息以便访客提出疑问或及时与贵处取得联系。',
  contactEmailHelp:
    '想获取有关预订和取消的电子邮件通知，请选中以下框。您可以参考以下格式添加多个电子邮件：example@email.com; example2@email.com',
  openingHoursHelp: 'Spacent平台可预定时间段取决于贵空间的营业时间。',
  wifiHelp: 'Wi-fi连接信息在访客完成预订后显示。',
  locationPhotoHelp:
    '仅显示封面图片。有效的位置图片能够清晰显示空间所在楼宇的入口信息。',

  // amenities editor -- admin
  forExtraCost: '额外收费',
  locationSpecificInfo: '附加信息',
  print_scan_copy: '打印 / 扫描 / 复印',
  coffee_tea: '咖啡和茶',
  restaurant: '现场餐厅',
  cafe: '现场咖啡厅',
  hand_sanitizer: '消毒洗手液',
  enhanced_cleaning: '增强清洁的',
  parking: '专用停车区域',
  accessible: '无障碍',
  lockers: '储物柜',
  reception: '前台接待',
  phone_booth: '电话亭',
  gym: '健身房',
  adjustable_table: '可调办公桌',
  monitor: '外接显示器',
  silent_space: '静音空间',
  whiteboard: '白板或活动挂图',
  projector: '投影仪或类似',
  videoconference: '带视屏会议的',
  furnished: '带家具的(项目空间)',

  // image uploader -- admin
  imageUploaderCover: '封面',
  imageUploaderNew: '新的',
  imageUploaderAdded: '单击删除新的',
  imageUploaderRemoved: '已移除，点击再次添加。',
  imageUploaderNoChange: '点击删除当前图像.',
  imageUploaderUploading: '上传中...',
  imageUploaderDropHere: '将图像文件放到此处或单击从计算机中选择',

  // announcements -- admin
  sendAnnouncement: '发送公告',
  selectBuildings: '选择大楼',
  noBuildings: '没有可用的大楼',
  users: '用户',
  announcementsToOwnEmail: '我们将把公告发送到您注册的电子邮箱。',
  announcementSubject: '标题',
  announcementMessage: '信息',
  announcementConfirmation: '确认给所选大楼的用户发送公告吗?',

  // building management -- admin
  updateBuildingManagements: '更新大厦管理相关信息',
  buildingName: '大厦名称',
  managementFetchError: '没有找到大厦管理相关信息😭',
  managementSaved: '已保存大厦相关管理信息🎉',
  managementSaveError: ' 更新大厦管理相关信息时出现问题😭',
  editBuildingManagement: '编辑大厦管理相关信息',
  maintenanceEmails: '维护邮箱',
  maintenanceEmail: '邮箱',
  addEmail: '添加邮箱',
  buildingDescription: '大厦简介',
  buildingDescriptionDescription:
    '此简介会供大厦的访客了解相关信息。如果大厦已有第三方对于位置相关的介绍，则该简介将显示在介绍之后。',
  buildingDescriptionPlaceholder: '联系信息和更多关于建筑物维护的信息',
  buildingPhoto: '照片',
  buildingPhotoDescription:
    '如未添加该地点的照片，访客会浏览到此封面照片以有关该大厦的信息。',

  // spaces -- admin
  spaceAdded: '已添加新的办公空间 🎉',
  spaceAddError: '添加新的办公空间时出现问题😭 ，请您检查空间信息。',
  spaceSaved: '已保存新的办公空间🎉! ',
  spaceSaveError: '更新办公空间信息时出现问题😭，请您检查空间信息。',
  spaceHidingCleared: '已清除不显示的办公空间',
  spaceHidingClearError: '清除不显示的办公空间时出现问题😭。',
  spaceNotFoundError: '没有找到办公空间😭 ',
  addNewSpace: '添加新的办公空间',
  spaceName: '办公空间名称',
  location: '地点',
  capacity: '空间容量',
  avgRating: '用户评分',
  hideFromCalendars: '不在日历中显示',
  spacesHidden: '这办公空间不会被显示',
  spacesHiddenError: '不在日历中显示出现问题😭',
  hideFromBookingCalendars: '不在预定日历中显示',
  hideTimeSelection: '什么时候不显示',
  hideSpaceSelection: '不显示以下办公空间',
  hideFrom: '从',
  hideUntil: '至',
  hideDays: '天数',
  offerSuffix: '价格',
  name: '名字',
  noUsers: '没有用户',
  pricing: '价格',
  hours: '小时',
  price: '价格',
  editSpace: '编辑办公空间',
  spaceNamePlaceholder: '灵活的座位',
  floor: '楼层',
  inheritFromLocation: '使用地点的营业时间',
  customHours: '自订营业时间',
  spaceDescription: '办公空间简介',
  spaceDescriptionDescription: '为提升用户体验，请尽可能以访客的母语提供简介。',
  spaceTypeCapacity: '将此办公空间标记为多张办公桌',
  numberOfDesks: '办公桌数量',
  spaceTypeMeetingRoom: '将此办公空间标记为会议室',
  meetingRoomCapacity: '办公空间容量',
  noPricingSet: 'No pricing set',
  sellForFixedRate: 'Sell for fixed hourly rate',
  priceExcludesTaxes: 'Price excludes taxes',
  currency: 'Currency',
  availability: '可用',
  freeForOwnOrganization: '您公司的会员可以免费预订此办公空间',
  spacePhotos: '照片',
  spaceHidden: '可见性',
  spaceHiddenDescription: '将不在日历中显示此办公空间:',
  offer: '价格',
  offering: '群组',
  fieldsMissing: '缺少必填项信息',
  saveAndPublish: '保存并发布',
  saveAndSendForReview: '保存并发送以供审核',
  hourlyPricingDisabledForCapacity:
    'If you want to change desk pricing, please contact us!',
  spaceLocationHelp:
    '首先选择贵空间所在位置。未被创建过的新位置无法在列表显示，需至上一栏创建该新位置，然后再创建空间。',
  spaceNameHelp:
    '建议空间命名阐明办公类型。例如，灵活工位、共享办公空间或会议室等。',
  spaceOpeningHoursHelp:
    'Spacent平台可预定时间段取决于贵空间的营业时间（可自定义）。如果您选择自定义开放时间，所在位置的开放时间将会被相应覆盖。',
  spaceAmenitiesHelp: '勾选这一办公空间包含在预订价格中的配套设施。',
  spaceDescriptionHelp:
    '办公空间文字描述：例如可用家具、插座、设备、公区或安静区域等描述信息。',
  spaceCapacityHelp:
    '您可以选择将空间以多张办公桌（共享办公室和灵活工位）或会议室进行出售。针对多张办公桌，请选择贵空间为Spacent用户提供的办公桌的每日容量。针对会议室，请选择会议室的人数容量。',
  spacePricingHelp: '办公桌的定价请参见合作协议。会议室请给出小时单价。',
  spaceAvailabilityHelp:
    '选中此复选框，贵公司员工（例如，大厅服务人员或办公空间员工）即可在Spacent平台免费预定。',
  spacePhotosHelp:
    '您可以添加多张空间图片，这些图片将在Spacent App中显示。首张图片将被默认为封面图片。添加的图片建议能清晰展示办公空间及公共区域的工作环境。',
  status: 'Status',
  live: 'Live',
  hidden: 'Hidden',
  draft: 'Draft',
  inReview: 'In review',
  internal: 'Internal',
  confirmPublishingStatusChange:
    "Are you sure you want to change the space's visibility?",

  // user groups -- admin
  addUsergroup: '添加新的用户群组',
  usergroupSaved: '已成功更新您的用户群组 🎉',
  usergroupSaveError: '😭更新您的用户群组时出现问题.',
  inviteListFormatError: '输入内容中包含无效的邮箱或未正确分割字符',
  invitesSent: '成功发送邀请🎉',
  invitesSendError: '发送邀请时出现问题 😭',
  membersOfGroup: '成员',
  editUsergroup: '编辑用户群组',
  inviteAddresses: '通过邮箱邀请',
  sendInvites: '发送邀请',
  pendingInvites: '待处理的邀请',
  usersInGroup: '群组中的用户',

  // settings -- admin
  yourAccount: '您的账户',
  yourOrganization: '您的公司',
  logout: '登出',

  // login -- admin
  loginToAdmin: '登录Spacent平台',
  adminLoginUnauthorized: '抱歉，您没有访问Spacent平台的权限。',
  adminLoginError: '登录失败',
  adminLoginEmailAuth:
    '我们已给您发送了登录链接！请检查您的邮箱，然后单击此链接或将其复制到浏览器。 接收邮件可能需要几分钟时间。',
  adminLoginEmail: '输入您的邮箱',
  adminLoginTryAgain: '请尝试重新登录',
  adminLoginRetryInstructions:
    '请确保您使用的是最新的链接，接收邮件可能需要几分钟时间。',
  adminWelcome: '欢迎登录Spacent管理平台',
  login: 'Log in',
  adminErrorContact: '如遇到问题，请联系',
  clickToRetry: '点击此处重试',

  // invites -- admin
  inviteStart: '嗨，您好！让我们开始使用Spacent。',
  inviteWelcome: '欢迎登录您的办公空间!',
  invitedAccount: '受邀帐户',
  inviteMicrosoftSso: '使用微软SSO',
  inviteGoogleSso: '使用谷歌SSO',
  inviteEmailAuth: '继续使用邮件链接',
  inviteCheckEmail: '请查看您的邮箱',
  inviteCheckEmailDescription: '验证链接已发送至您的邮箱。',
  inviteNotFound: '很遗憾，我们没有找到您的邀请信息。',
  inviteError: '处理您的邀请时出了点问题，请稍后再试。',
  inviteDoneExistingHeader: '开始吧!',
  inviteDoneExistingContent:
    '您的公司已加入Spacent, 请从应用商店下载Spacent应用。如需获得邀请价格，请使用被邀请的邮箱登录:',
  inviteDoneNewOrganization: '创建公司成功',
  inviteDoneNewHeader: '欢迎来到Spacent社区！',
  inviteDoneNewContent:
    '我们已经创建了与您的公司相关联的账户。下载Spacent应用程序， 并请使用您的账户登录。',
  createAccount: '创建账号',

  // onboarding -- admin
  onboardingLoading: '正在加载页面...',
  onboardingNotFound: '页面无法被找到..',
  onboardingError: '出了些问题',
  onboardingDetails: '具体信息',
  onboardingSummary: '概要和付款',
  onboardingDownload: '下载应用',
  onboardingDownloadTooltip: '完成所有步骤',
  introTitle: '欢迎加入未来的办公空间',
  introMembership: '成为Spacent会员资格，您将获得：',
  introLocationsFI: '个在芬兰的办公空间',
  introDesks: '无限使用的办公桌',
  introShare: 'Share with your employees',
  introRooms: '按次付费的会议室和项目空间',
  introPriceTitle: '每人',
  yourInfo: '您的信息',
  subscriptionAddUsers: '添加更多会员',
  subscriptionUsersCount: '用户: ',
  subscriptionTotal: '总价: ',
  subscriptionMembership: 'x Spacent 会员',
  corporateSubscriptionMembership: 'x Spacent Corporate Membership',
  subscriptionLarge: '您的团队是否超过10名成员？ 请联系',
  subscriptionSalesEmail: 'Spacent 销售',
  subscriptionCustomize: '为您的团队定制一个方案。',
  subscriptionAdd: '添加',
  subscriptionRemove: '移除',
  subscriptionEmailError: '有效的邮箱',
  organizationInfo: '组织信息',
  addressStreet: '地址',
  addressPostalCode: '邮编',
  addressCity: '城市',
  addressCountry: '国家',
  paymentIAccept: '接受',
  paymentTermsAndConditions: 'Spacent条款和条件',
  paymentUserTerms: '用户条款',
  paymentPrivacyPolicy: '隐私政策',
  summary: '概要',
  payment: '付款',
  emailInvoice: '电子邮件发票',
  electronicInvoice: '电子发票',
  order: '确认',
  contact: '联系',
  summaryIncomplete: '(未完成)',
  otherMembers: '其他成员',
  fillDetailsForSummary: '填写详细信息以查看概要',
  onboardingDoneTitle: '欢迎加入未来的办公空间!',
  onboardingDoneSuccess: '您已成功注册Spacent',
  onboardingDoneDownload: '下载Spacent应用，并使用您的邮箱登录。',
  onboardingDoneFindSpace: '寻找最合适的办公空间',

  // emails -- admin
  emailBestRegards: '祝好',
  emailSpacentTeam: 'Spacent团队',

  emailNewReservationTitle: '您有一个新的预订!',
  emailCancelReservationTitle: '预订已取消',

  emailYouWereAddedToGroup: '您已被添加到Spacent',
  emailMoreWorkspaces: '在Spacent您拥有更多的办公空间!',
  emailGreatCompany: '欢迎加入未来的办公空间.',
  emailHello: '嗨',
  emailAddedToGroup: '您已被添加到新的用户群组中.',
  emailOrganizationAddedToGroup: '已将您加入群组',
  emailMoreWorkspacesAvailable:
    '您现在可以在Spacent应用中可以使用更多的办公空间。',

  emailWelcomeToBook: '欢迎加入Spacent预订办公空间！',
  emailWelcomeTitle: '欢迎加入未来的办公空间Spacent!',
  emailWelcomeSpacentProvidesSolution:
    '提供灵活的办公空间方案  –- 让您能随时随地找到合适的空间进行工作。',
  emailWelcomeOrganizationHasInvited:
    '邀请您加入Spacent平台，您可以在需要的时间和地点预订您的办公空间。',
  emailWelcomeGetStarted: '让我们开始吧:',
  emailWelcomeGetApp: '下载Spacent应用.',
  emailWelcomeLogin: '用邮箱登录',
  emailWelcomeFindYourSpot: '找到您理想的办公地点',

  emailOnboardedAdminWelcome: '欢迎来到Spacent!',
  emailOnboardedAdminTitle: '欢迎加入未来的办公空间Spacent!',
  emailWelcome: '欢迎',
  emailSpacentProvides:
    '提供了动态的办公空间解决方案 –- 让您能够让您能随时随地找到合适的空间进行工作。',
  emailGetStarted: '开始吧',
  emailOnboardedAdminReady: '开始吧',
  emailOnboardedAdminOrganization: '开始吧',
  emailOnboardedAdminMembersOf: '的成员',
  emailOnboardedAdminMembersHaveBeenInvited:
    '被邀请加入您的Spacent会员。我们已经向他们发送了如何加入的电子邮件。',
  emailOnboardedAdminContact:
    '您的Spacent 会员应与您的公司需求匹配。您可以随时联系我们来修改或取消您公司的Spacent会员。',
  emailOnboardedAdminView: '您可以在以下位置查看和更新您的公司各种信息包括帐单',
  emailOnboardedAdminManagement: 'Spacent管理工具',
  emailOnboardedAdminInvoicing: '发票',
  emailOnboardedAdminInvoicingDescription: '每月的订阅依据订阅信息开具发票',
  emailOnboardedAdminInvoicingItem: '项',
  emailOnboardedAdminInvoicingQuantity: '数量',
  emailOnboardedAdminInvoicingPrice: '价格',
  emailOnboardedAdminInvoicingMembership: 'Spacent会员',
  emailOnboardedAdminInvoicingTotal: '总共',
  emailOnboardedAdminDescription:
    "会员覆盖的地点会标记为'会员包含'。当您公司的会员\n    预订'会员不包含'的办公空间时，相应的价格会被显示\n   此类预订的费用将会叠加在您公司的的月订阅费用发票上。",
  emailOnboardedAdminConfirmation:
    '如果不是您所做的预订，请通过以下方式与我们联系',

  emailOnboardingUserWelcome: '欢迎使用Spacent预订办公空间',
  emailOnboardingUserTitle: '欢迎来到Spacent加入未来的办公空间!',
  emailOnboardingUserUserAtOrganization: 'at',
  emailOnboardingUserInvitedYou: '邀请您加入Spacent平台',
  emailOnboardingUserSpacentProvides:
    '灵活的办公空间方案 -– 通过Spacent应用，您可以在需要的时间和地点预订您的办公空间。',
  emailOnboardingUserDescription:
    "作为Spacent的会员，您可以使用所有 '会员包含'的地点。 额外定价的办公空间，将根据预订时显示的价格开具发票",
  emailOnboardingUserMoreInfo:
    '您可以在以下位置找到更多有关Spacent会员的信息：',
  emailOnboardingUserContact: '如有疑问，请通过以下方式与我们联系',

  emailAuthSubject: '登录Spacent', // TODO update to 'Signing in to Spacent' translation when available. This uses emailAuthTitle
  emailAuthTitle: '这是您Spacent的登录链接',
  emailAuthGreeting: '您好',
  emailAuthLoginLink:
    '这是您的登录链接。单击后，您将跳转到Spacent页面并自动登录帐户。',
  emailAuthNote: '注',
  emailAuthAppPressLoginLink:
    '请确保在已下载Spacent应用程序的手机端尝试此登录链接',
  emailAuthAppPressHereToLogin: '在手机端按此处登录',
  emailAuthWebPressHereToLogin: '在此处登录',
  emailAuthIfButtonDoesNotWork:
    '如果上方按钮响应失败。您还可以将以下链接复制到浏览器地址栏中。此登录链接在一小时内有效。',
  emailAuthContactUs: '如有疑问 ',
  emailAuthContactUsLinkText: '请联系hello@spacent.com',
  emailAuthContactUsIfProblems: '',

  // organizations -- super-admin
  emailDomainExplanation: '每个人的邮箱指定域名和所属单位必须一致。',
  emailDomainWarning: '请不要添加与单位名不一致的任何域名！',
  emailDomainRejection: '您选择的域不可用或已被其他单位使用:',

  // atom / molecule specific
  pickerComponentOk: '确认',
  pickerComponentCancel: '取消',
  pickerComponentToday: '今天',
  openingHoursClosed: '关闭',
  openingHoursAlwaysOpen: '全天24小时',
  openingHoursCustom: '自订',
  localizableContentAdd: '添加',

  // errors
  authError: '访问被拒绝，请重新登录。',
  reservationsFetchError: '获取预订时出现错误，请稍后再试。',
  reservationCreationError: '创建新的预订时出现错误，请稍后再试。',
  reservationCancelError: '无法取消预订，请稍后再试。',
  reservationConflictError: '无法创建预订，请稍后再试。',
  reservationUpdateError: '无法更新预订，请稍后再试。',
  reservationUpdateConflictError: '无法更新预订； 因为此房间已被预约',
  spacesFetchError: '进入房间时出现错误，请稍后再试。',
  addCardError: '无法添加信用卡',
  createOrganizationError:
    '很遗憾，加入Spacent时发生错误。 请先检查您的数据，然后重试。',
  generalCreditCardError: '很遗憾，我们无法使用此银行卡信息设置帐单。',
  retryCreditCardError: '很遗憾，我们无法验证您的信用卡信息。请再试一次。',

  // Stripe error messages
  stripe_card_declined: '您的卡已被拒绝',
  stripe_card_declined_card_not_supported: '不支持您的卡.',
  stripe_card_number_in_name_field: '请确保姓名栏不包含卡号',
  stripe_card_number_in_postal_code_field: '请确保邮政编码栏不包含卡号',
  stripe_customer_canceled_authentication: '您必须先进行身份验证才能完成此交易',
  stripe_email_invalid: '无效的邮箱',
  stripe_expired_card: '您的卡已过期',
  stripe_incomplete_au_bank_account_bsb: '您输入的BSB信息不完整',
  stripe_incomplete_au_bank_account_number: '您输入的帐号信息不完整',
  stripe_incomplete_card: '请填写您的卡片信息。',
  stripe_incomplete_cvc: '您的卡片安全码不完整',
  stripe_incomplete_expiry: '您的卡片有效期不完整',
  stripe_incomplete_iban: '您输入的IBAN码不完整',
  stripe_incomplete_number: '您的卡片号不完整',
  stripe_incomplete_payment_details: '请提供完整的付款明细',
  stripe_incomplete_zip: '您的邮政编码不完整',
  stripe_incorrect_cvc: '您的卡片安全码不正确',
  stripe_incorrect_number: '您的卡号不正确',
  stripe_incorrect_zip: '您的卡号和邮政编码不匹配',
  stripe_invalid_au_bank_account_bsb: '您输入的BSB信息无效',
  stripe_invalid_au_bank_account_number_testmode:
    '您输入的帐号在测试模式下无效.',
  stripe_invalid_cvc: '您的卡片安全码无效.',
  stripe_invalid_expiry_month: '您的卡片有效期无效',
  stripe_invalid_expiry_month_past: '您的卡片已过有效期',
  stripe_invalid_expiry_year: '您的卡片有效期无效',
  stripe_invalid_expiry_year_past: '您的卡片已过有效期',
  stripe_invalid_iban: '您输入的IBAN码无效',
  stripe_invalid_iban_country_code:
    '您输入的IBAN号无效，不支持的国家或者地区代码 "{code}"',
  stripe_invalid_iban_start: '您的IBAN号应以国家或者地区代码的两个字母开头.',
  stripe_invalid_number: '您的卡片号无效',
  stripe_payment_intent_authentication_failure:
    '我们无法验证您的付款方式。 请选择其他付款方式，然后重试。',
  stripe_payment_intent_unexpected_state: '发生处理错误',
  stripe_process_error_intransient: '处理您的卡片信息时发生错误。',
  stripe_processing_error: '处理您的卡片信息时发生错误。 请稍后再试。',
  stripe_setup_intent_authentication_failure:
    '我们无法验证您的付款方式。 请选择其他付款方式，然后重试。',
  stripe_setup_intent_unexpected_state: '发生处理错误',
  stripe_unexpected: '发生意外错误',

  // statistics
  print: '打印',
}

const validations = {
  ...en.validations,
  required: ({ field }: { field: string }) => `${field} 必填`,
  email: ({ field }: { field: string }) => `${field} 必须是有效的电子邮箱`,
  min: ({ field, min }: { field: string; min: string }) =>
    `${field} 至少 ${min} 字符`,
  max: ({ field, max }: { field: string; max: string }) =>
    `${field} 最多 ${max} 字符`,
  phoneFormat: ({ field }: { field: string }) =>
    `${field} 必须是以加号和数字开头, 比如: +123456789`,
  businessIdOnly: () => 'Business id only',
}

const formatters = {
  reservationNotification: (props: {
    roomName: string
    locationAddress: string
    startTime: string
    endTime: string
  }) =>
    `在此地点有一个新的预订 ${props.roomName}, ${props.locationAddress} from ${props.startTime} to ${props.endTime}.`,
  cancellationNotification: (props: {
    roomName: string
    locationAddress: string
    startTime: string
    endTime: string
  }) =>
    `在此地点的预订已取消 ${props.roomName}, ${props.locationAddress} from ${props.startTime} to ${props.endTime}.`,
}

const timeFormats = {
  ...en.timeFormats,
  weekDayAbbreviations: {
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sunday: '周日',
  },
}

export const zh: Translations = {
  // Use English translations as fallback language.
  // Comment following line to let TypeScript compiler tell you what translations are missing.
  ...en,

  ...formatters,
  ...constantStrings,
  validations,
  timeFormats,
  timeFormatters: generateTimeFormatters(timeFormats),
}
