import { sortBy, prop } from 'ramda'
import {
  PricingData,
  RoomOffer,
  TieredPricingByUnit,
  TieredPricingByLength,
  VolumePricing,
  ReservationLimits,
} from '../types'
import { RoomOffering } from '../types/admin'
import {
  SimplePricingEditorOfferNamePrefix,
  DefaultInternalFreeOfferName,
  DefaultInternalOfferingName,
} from '../constants'
import { round } from './format'

export const isASimplePricingEditorOffer = (ro: RoomOffer): boolean =>
  new RegExp(SimplePricingEditorOfferNamePrefix).test(ro.name)

export const isFree = (pricingData: PricingData): boolean => {
  return (
    pricingData.type === 'free' ||
    (pricingData.type === 'hourly' &&
      pricingData.rules[0]?.priceWithoutTax === 0) ||
    (pricingData.type === 'byLength' &&
      pricingData.rules[0]?.priceWithoutTax === 0)
  )
}

export const sortUnitPricesByUptoCount = (
  tiered: TieredPricingByUnit,
): TieredPricingByUnit => {
  return {
    ...tiered,
    rules: {
      ...tiered.rules,
      unitPrices: sortBy(prop('uptoUnitCount'), tiered.rules.unitPrices),
    },
  }
}

export const sortByLengthPricesByHours = (
  byLengthPricing: TieredPricingByLength,
): TieredPricingByLength => {
  return {
    ...byLengthPricing,
    rules: sortBy(prop('hours'), byLengthPricing.rules),
  }
}

export const findInternalFreeOffer = (
  offerings: RoomOffering[],
): RoomOffer | undefined => {
  return offerings
    ?.find((aro) => aro.name === DefaultInternalOfferingName)
    ?.roomOffers.find((ro) => ro.name === DefaultInternalFreeOfferName)
}

export const calculateVolumePricingDefaults = (
  volumePricingRules: VolumePricing['rules'],
) => {
  const weeklyPrice =
    volumePricingRules.weeklyPriceWithoutTax ||
    round(volumePricingRules.monthlyPriceWithoutTax / 4, 2)
  const dailyPrice =
    volumePricingRules.dailyPriceWithoutTax || round(weeklyPrice / 5, 2)

  if (weeklyPrice <= dailyPrice) {
    return volumePricingRules
  }

  const calculatedPricingRules = {
    ...volumePricingRules,
    dailyPriceWithoutTax: dailyPrice,
    weeklyPriceWithoutTax: weeklyPrice,
  }

  return calculatedPricingRules
}

export const defaultNoLimits: ReservationLimits = {
  minReservationMinutes: undefined,
  maxReservationMinutes: undefined,
  maxReservableAdvanceLimitType: undefined,
  maxReservableAdvanceLimit: undefined,
  maxReservableAdvanceHours: undefined,
  minReservableAdvanceLimitType: undefined,
  minReservableAdvanceLimit: undefined,
  minReservableAdvanceHours: undefined,
  maxReservationMinutesPerDay: undefined,
  maxUpcomingReservationsPerUser: undefined,
}

export const hasLimits = (
  limits?: ReservationLimits,
): limits is ReservationLimits => {
  if (!limits) return false

  return (
    !!limits.minReservationMinutes ||
    !!limits.maxReservationMinutes ||
    (!!limits.maxReservableAdvanceLimit &&
      limits.maxReservableAdvanceLimit != '0') ||
    !!limits.maxReservableAdvanceHours ||
    (!!limits.minReservableAdvanceLimit &&
      limits.minReservableAdvanceLimit != '0') ||
    !!limits.minReservableAdvanceHours ||
    !!limits.maxReservationMinutesPerDay ||
    !!limits.maxUpcomingReservationsPerUser
  )
}
