import {
  ContentBlock,
  Text,
  TextField,
  StyledButton as Button,
  Flex,
} from 'shared-design/components'
import React from 'react'
import { PlainCheckInRoom } from 'common/types/checkin'
import { Room } from '@material-ui/icons'
import { useLocalizations } from 'shared-design/components/molecules/Locales'

export interface CheckInValues {
  name: string
  email: string
  phone: string
}

export interface GuestCheckinFormProps {
  title: React.ReactNode
  room: PlainCheckInRoom
  values: Partial<CheckInValues>
  onChange: (values: CheckInValues) => unknown
  onSubmit: () => unknown
  submitText: React.ReactNode
  error?: string
}

export const GuestCheckinForm: React.FC<GuestCheckinFormProps> = ({
  title,
  room,
  values: { name = '', email = '', phone = '' },
  onChange,
  onSubmit,
  submitText,
  error,
}) => {
  const { localization } = useLocalizations()

  return (
    <>
      <Flex direction="column">
        <ContentBlock title={title}>
          <div className="GuestCheckIn__arriving_at">
            <div className="address">
              <Room className="SpaceDetails__icon" />{' '}
              <Text tr="checkInAddress" />
            </div>
            <div className="location">{room.name}</div>
          </div>

          <TextField
            label={<Text tr="yourName" />}
            type="name"
            name="name"
            value={name}
            onChange={(e) =>
              onChange({
                email,
                phone,
                name: e.target.value,
              })
            }
            placeholder={localization.yourName}
            autoFocus
          />

          <TextField
            label={<Text tr="email" />}
            type="email"
            name="name"
            value={email}
            onChange={(e) =>
              onChange({
                email: e.target.value,
                phone,
                name,
              })
            }
            placeholder={localization.checkInFormEmailPlaceholder}
          />

          <TextField
            label={<Text tr="checkInPhone" />}
            type="text"
            name="phone"
            value={phone}
            onChange={(e) =>
              onChange({
                email,
                phone: e.target.value,
                name,
              })
            }
            placeholder={localization.yourPhone}
          />
          {error && <div className="GuestCheckIn__error">{error}</div>}
        </ContentBlock>

        <ContentBlock>
          <Button color="highlight" onClick={onSubmit} autoFocus>
            {submitText}
          </Button>
        </ContentBlock>
      </Flex>
    </>
  )
}
