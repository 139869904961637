import React from 'react'
import MaterialTooltip, {
  TooltipProps as MaterialTooltipProps,
} from '@material-ui/core/Tooltip'
import { createStyles, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { Icon } from '../Icon/Icon'

import './Tooltip.scss'

const useStyles = makeStyles(() =>
  createStyles({
    readableText: {
      fontSize: '14px',
    },
  }),
)

type TooltipProps = Omit<MaterialTooltipProps, 'children'> & {
  children?: React.ReactNode
  icon?: boolean
  className?: string
}

const TooltipContentWrapper = React.forwardRef((props: any, ref: any) => {
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  )
})

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const classes = useStyles()
  const { children, icon, className, ...restProps } = props

  return (
    <MaterialTooltip
      className={classNames('Tooltip', className)}
      arrow
      placement="right"
      {...restProps}
      classes={{ tooltip: classes.readableText }}
    >
      <TooltipContentWrapper>
        {children ? <div className="Tooltip__Children">{children}</div> : null}
        {icon || !children ? <Icon variant="info" /> : null}
      </TooltipContentWrapper>
    </MaterialTooltip>
  )
}
