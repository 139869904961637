import moment, { Moment } from 'moment'
import { chain } from 'ramda'
import { ReservedSlot } from '../types'

export const countMaxCapacityUse = (
  reservations: ReservedSlot[],
  startTime: Moment | string = moment().year(1970),
  endTime: Moment | string = moment().year(2100),
): number => {
  const overlappingReservations = reservations
    .filter(
      (reservation) =>
        moment(endTime).isAfter(reservation.start) &&
        moment(startTime).isBefore(reservation.end),
    )
    .map((reservation) => {
      const newEnd = new Date(reservation.end)
      newEnd.setMilliseconds(newEnd.getMilliseconds() - 1)

      return {
        ...reservation,
        end: newEnd,
      } as ReservedSlot
    })
  const changes = chain(
    (r) => [
      {
        at: moment(r.start),
        add: 1,
      },
      {
        at: moment(r.end),
        add: -1,
      },
    ],
    overlappingReservations,
  )

  changes.sort((a, b) => a.at.diff(b.at))

  const countMax = () => {
    let max = 0
    let count = 0
    changes.forEach((it) => {
      max = Math.max(max, (count += it.add))
    })

    return max
  }

  return countMax()
}
