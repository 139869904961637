import { createMuiTheme, ThemeOptions } from '@material-ui/core'
import adminColors from '../../../styles/_admin-colors.scss'

const theme = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: adminColors.pickerBackgroundHover,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#3b444a',
      },
    },
    MuiButton: {
      label: {
        color: '#000',
      },
    },
    MuiPickersDay: {
      current: {
        color: adminColors.pickerBackgroundHover,
      },
      daySelected: {
        backgroundColor: '#3b444a',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#f7b83a',
          color: '#000',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Barlow',
  },
  palette: {
    background: { default: '#fff', paper: '#fff' },
    primary: {
      main: adminColors.pickerBackground,
    },
  },
}

export const materialTheme = createMuiTheme(theme as ThemeOptions)
