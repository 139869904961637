import React from 'react'
import './AppStoreLinks.scss'

import appStore from '../../../public/app_store_badge.svg'
import googlePlay from '../../../public/google_play_badge.png'

interface AppStoreLinksProps {
  appleLink?: string
  googleLink?: string
}

export const AppStoreLinks: React.FC<AppStoreLinksProps> = ({
  appleLink = 'https://apps.apple.com/us/app/spacent/id1533233125',
  googleLink = 'https://play.google.com/store/apps/details?id=com.spacent.app&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
}) => (
  <div className="AppStoreLinks">
    <div>
      <span>iOS</span>
      <a target="_blank" rel="noopener noreferrer" href={appleLink}>
        <img alt="Download on the App Store" src={appStore} />
      </a>
    </div>
    <div>
      <span>Android</span>
      <a target="_blank" rel="noopener noreferrer" href={googleLink}>
        <img
          alt="Get it on Google Play"
          src={googlePlay}
          style={{ width: '164px', marginTop: '-10px', marginLeft: '-10px' }}
        />
      </a>
    </div>
  </div>
)

export default AppStoreLinks
