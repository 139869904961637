import React from 'react'
import './Grid.scss'

interface GridProps {
  children: React.ReactNode
  gap?: string
  cellWidth?: string
}

const Grid: React.FunctionComponent<GridProps> = ({
  gap,
  cellWidth,
  children,
}: GridProps) => {
  return (
    <div
      className="grid"
      style={{
        gridGap: gap,
        gridTemplateColumns: `repeat(auto-fill, minmax(${cellWidth}, 1fr))`,
      }}
    >
      {children}
    </div>
  )
}

Grid.defaultProps = {
  gap: '2rem',
  cellWidth: '300px',
}

export default Grid
