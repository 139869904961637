import React from 'react'
import classnames from 'classnames'

import './Button.scss'

type ButtonVariants = 'primary' | 'secondary' | 'danger'

export interface ButtonProps {
  onClick?(): unknown

  disabled?: boolean
  children: React.ReactNode
  variant?: ButtonVariants
  small?: boolean
  className?: string
}

const defaultProps = {
  variant: 'primary',
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const propsWithDefaults = {
    ...defaultProps,
    ...props,
  }
  const { children, onClick, variant, disabled, small, className } =
    propsWithDefaults

  return (
    <button
      type="button"
      className={classnames('button', className, {
        primary: variant === 'primary',
        delete: variant === 'danger',
        small,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
