// source: 'https://github.com/onomojo/i18n-country-translations',

export const enCountryCodeTranslations = {
  AC: 'Ascension Island',
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua & Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia & Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'St. Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Caribbean Netherlands',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Congo - Kinshasa',
  CF: 'Central African Republic',
  CG: 'Congo - Brazzaville',
  CH: 'Switzerland',
  CI: 'Côte d’Ivoire',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CP: 'Clipperton Island',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EA: 'Ceuta & Melilla',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  EU: 'European Union',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia & South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong SAR China',
  HM: 'Heard & McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  IC: 'Canary Islands',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'St. Kitts & Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'St. Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'St. Martin',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar (Burma)',
  MN: 'Mongolia',
  MO: 'Macau SAR China',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'St. Pierre & Miquelon',
  PN: 'Pitcairn Islands',
  PR: 'Puerto Rico',
  PS: 'Palestinian Territories',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  QO: 'Outlying Oceania',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'St. Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard & Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'São Tomé & Príncipe',
  SV: 'El Salvador',
  SX: 'Saint Martin',
  SY: 'Syria',
  SZ: 'Swaziland',
  TA: 'Tristan da Cunha',
  TC: 'Turks & Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad & Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'U.S. Outlying Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'St. Vincent & Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'U.S. Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis & Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}

export const fiCountryCodeTranslations = {
  AC: 'Ascension-saari',
  AD: 'Andorra',
  AE: 'Arabiemiirikunnat',
  AF: 'Afganistan',
  AG: 'Antigua ja Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Alankomaiden Antillit',
  AO: 'Angola',
  AQ: 'Antarktis',
  AR: 'Argentiina',
  AS: 'Amerikan Samoa',
  AT: 'Itävalta',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Ahvenanmaa',
  AZ: 'Azerbaidžan',
  BA: 'Bosnia ja Hertsegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgia',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint-Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Karibian Alankomaat',
  BR: 'Brasilia',
  BS: 'Bahama',
  BT: 'Bhutan',
  BV: 'Bouvet’nsaari',
  BW: 'Botswana',
  BY: 'Valko-Venäjä',
  BZ: 'Belize',
  CA: 'Kanada',
  CC: 'Kookossaaret (Keelingsaaret)',
  CD: 'Kongon demokraattinen tasavalta',
  CF: 'Keski-Afrikan tasavalta',
  CG: 'Kongon tasavalta',
  CH: 'Sveitsi',
  CI: 'Norsunluurannikko',
  CK: 'Cookinsaaret',
  CL: 'Chile',
  CM: 'Kamerun',
  CN: 'Kiina',
  CO: 'Kolumbia',
  CP: 'Clippertoninsaari',
  CR: 'Costa Rica',
  CU: 'Kuuba',
  CV: 'Kap Verde',
  CW: 'Curaçao',
  CX: 'Joulusaari',
  CY: 'Kypros',
  CZ: 'Tšekki',
  DE: 'Saksa',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Tanska',
  DM: 'Dominica',
  DO: 'Dominikaaninen tasavalta',
  DZ: 'Algeria',
  EA: 'Ceuta ja Melilla',
  EC: 'Ecuador',
  EE: 'Viro',
  EG: 'Egypti',
  EH: 'Länsi-Sahara',
  ER: 'Eritrea',
  ES: 'Espanja',
  ET: 'Etiopia',
  EU: 'Euroopan unioni',
  FI: 'Suomi',
  FJ: 'Fidži',
  FK: 'Falklandinsaaret',
  FM: 'Mikronesian liittovaltio',
  FO: 'Färsaaret',
  FR: 'Ranska',
  GA: 'Gabon',
  GB: 'Yhdistynyt kuningaskunta',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'Ranskan Guayana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Grönlanti',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Päiväntasaajan Guinea',
  GR: 'Kreikka',
  GS: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hongkong – Kiinan e.h.a.',
  HM: 'Heard ja McDonaldinsaaret',
  HN: 'Honduras',
  HR: 'Kroatia',
  HT: 'Haiti',
  HU: 'Unkari',
  IC: 'Kanariansaaret',
  ID: 'Indonesia',
  IE: 'Irlanti',
  IL: 'Israel',
  IM: 'Mansaari',
  IN: 'Intia',
  IO: 'Brittiläinen Intian valtameren alue',
  IQ: 'Irak',
  IR: 'Iran',
  IS: 'Islanti',
  IT: 'Italia',
  JE: 'Jersey',
  JM: 'Jamaika',
  JO: 'Jordania',
  JP: 'Japani',
  KE: 'Kenia',
  KG: 'Kirgisia',
  KH: 'Kambodža',
  KI: 'Kiribati',
  KM: 'Komorit',
  KN: 'Saint Kitts ja Nevis',
  KP: 'Pohjois-Korea',
  KR: 'Etelä-Korea',
  KW: 'Kuwait',
  KY: 'Caymansaaret',
  KZ: 'Kazakstan',
  LA: 'Laos',
  LB: 'Libanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Liettua',
  LU: 'Luxemburg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Marokko',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint-Martin',
  MG: 'Madagaskar',
  MH: 'Marshallinsaaret',
  MK: 'Makedonia',
  ML: 'Mali',
  MM: 'Myanmar (Burma)',
  MN: 'Mongolia',
  MO: 'Macao – Kiinan e.h.a.',
  MP: 'Pohjois-Mariaanit',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Malediivit',
  MW: 'Malawi',
  MX: 'Meksiko',
  MY: 'Malesia',
  MZ: 'Mosambik',
  NA: 'Namibia',
  NC: 'Uusi-Kaledonia',
  NE: 'Niger',
  NF: 'Norfolkinsaari',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Alankomaat',
  NO: 'Norja',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'Uusi-Seelanti',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'Ranskan Polynesia',
  PG: 'Papua-Uusi-Guinea',
  PH: 'Filippiinit',
  PK: 'Pakistan',
  PL: 'Puola',
  PM: 'Saint-Pierre ja Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestiinalaisalueet',
  PT: 'Portugali',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  QO: 'ulkomeri',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Venäjä',
  RW: 'Ruanda',
  SA: 'Saudi-Arabia',
  SB: 'Salomonsaaret',
  SC: 'Seychellit',
  SD: 'Sudan',
  SE: 'Ruotsi',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Huippuvuoret ja Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Surinam',
  SS: 'Etelä-Sudan',
  ST: 'São Tomé ja Príncipe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syyria',
  SZ: 'Swazimaa',
  TA: 'Tristan da Cunha',
  TC: 'Turks- ja Caicossaaret',
  TD: 'Tšad',
  TF: 'Ranskan ulkopuoliset eteläiset alueet',
  TG: 'Togo',
  TH: 'Thaimaa',
  TJ: 'Tadžikistan',
  TK: 'Tokelau',
  TL: 'Itä-Timor',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkki',
  TT: 'Trinidad ja Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tansania',
  UA: 'Ukraina',
  UG: 'Uganda',
  UM: 'Yhdysvaltain erillissaaret',
  US: 'Yhdysvallat',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatikaani',
  VC: 'Saint Vincent ja Grenadiinit',
  VE: 'Venezuela',
  VG: 'Brittiläiset Neitsytsaaret',
  VI: 'Yhdysvaltain Neitsytsaaret',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis ja Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Jemen',
  YT: 'Mayotte',
  ZA: 'Etelä-Afrikka',
  ZM: 'Sambia',
  ZW: 'Zimbabwe',
}

export const svCountryCodeTranslations = {
  AC: 'Ascension',
  AD: 'Andorra',
  AE: 'Förenade Arabemiraten',
  AF: 'Afghanistan',
  AG: 'Antigua och Barbuda',
  AI: 'Anguilla',
  AL: 'Albanien',
  AM: 'Armenien',
  AN: 'Nederländska Antillerna',
  AO: 'Angola',
  AQ: 'Antarktis',
  AR: 'Argentina',
  AS: 'Amerikanska Samoa',
  AT: 'Österrike',
  AU: 'Australien',
  AW: 'Aruba',
  AX: 'Åland',
  AZ: 'Azerbajdzjan',
  BA: 'Bosnien och Hercegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgien',
  BF: 'Burkina Faso',
  BG: 'Bulgarien',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'S:t Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Karibiska Nederländerna',
  BR: 'Brasilien',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvetön',
  BW: 'Botswana',
  BY: 'Vitryssland',
  BZ: 'Belize',
  CA: 'Kanada',
  CC: 'Kokosöarna',
  CD: 'Kongo-Kinshasa',
  CF: 'Centralafrikanska republiken',
  CG: 'Kongo-Brazzaville',
  CH: 'Schweiz',
  CI: 'Elfenbenskusten',
  CK: 'Cooköarna',
  CL: 'Chile',
  CM: 'Kamerun',
  CN: 'Kina',
  CO: 'Colombia',
  CP: 'Clippertonön',
  CR: 'Costa Rica',
  CU: 'Kuba',
  CV: 'Kap Verde',
  CW: 'Curaçao',
  CX: 'Julön',
  CY: 'Cypern',
  CZ: 'Tjeckien',
  DE: 'Tyskland',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Danmark',
  DM: 'Dominica',
  DO: 'Dominikanska republiken',
  DZ: 'Algeriet',
  EA: 'Ceuta och Melilla',
  EC: 'Ecuador',
  EE: 'Estland',
  EG: 'Egypten',
  EH: 'Västsahara',
  ER: 'Eritrea',
  ES: 'Spanien',
  ET: 'Etiopien',
  EU: 'Europeiska unionen',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falklandsöarna',
  FM: 'Mikronesien',
  FO: 'Färöarna',
  FR: 'Frankrike',
  GA: 'Gabon',
  GB: 'Storbritannien',
  GD: 'Grenada',
  GE: 'Georgien',
  GF: 'Franska Guyana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Grönland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Ekvatorialguinea',
  GR: 'Grekland',
  GS: 'Sydgeorgien och Sydsandwichöarna',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hongkong (S.A.R. Kina)',
  HM: 'Heardön och McDonaldöarna',
  HN: 'Honduras',
  HR: 'Kroatien',
  HT: 'Haiti',
  HU: 'Ungern',
  IC: 'Kanarieöarna',
  ID: 'Indonesien',
  IE: 'Irland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'Indien',
  IO: 'Brittiska territoriet i Indiska oceanen',
  IQ: 'Irak',
  IR: 'Iran',
  IS: 'Island',
  IT: 'Italien',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordanien',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kirgizistan',
  KH: 'Kambodja',
  KI: 'Kiribati',
  KM: 'Komorerna',
  KN: 'S:t Kitts och Nevis',
  KP: 'Nordkorea',
  KR: 'Sydkorea',
  KW: 'Kuwait',
  KY: 'Caymanöarna',
  KZ: 'Kazakstan',
  LA: 'Laos',
  LB: 'Libanon',
  LC: 'S:t Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Litauen',
  LU: 'Luxemburg',
  LV: 'Lettland',
  LY: 'Libyen',
  MA: 'Marocko',
  MC: 'Monaco',
  MD: 'Moldavien',
  ME: 'Montenegro',
  MF: 'S:t Martin',
  MG: 'Madagaskar',
  MH: 'Marshallöarna',
  MK: 'Makedonien',
  ML: 'Mali',
  MM: 'Myanmar (Burma)',
  MN: 'Mongoliet',
  MO: 'Macao (S.A.R. Kina)',
  MP: 'Nordmarianerna',
  MQ: 'Martinique',
  MR: 'Mauretanien',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldiverna',
  MW: 'Malawi',
  MX: 'Mexiko',
  MY: 'Malaysia',
  MZ: 'Moçambique',
  NA: 'Namibia',
  NC: 'Nya Kaledonien',
  NE: 'Niger',
  NF: 'Norfolkön',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Nederländerna',
  NO: 'Norge',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'Nya Zeeland',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'Franska Polynesien',
  PG: 'Papua Nya Guinea',
  PH: 'Filippinerna',
  PK: 'Pakistan',
  PL: 'Polen',
  PM: 'S:t Pierre och Miquelon',
  PN: 'Pitcairnöarna',
  PR: 'Puerto Rico',
  PS: 'Palestinska territorierna',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  QO: 'yttre öar i Oceanien',
  RE: 'Réunion',
  RO: 'Rumänien',
  RS: 'Serbien',
  RU: 'Ryssland',
  RW: 'Rwanda',
  SA: 'Saudiarabien',
  SB: 'Salomonöarna',
  SC: 'Seychellerna',
  SD: 'Sudan',
  SE: 'Sverige',
  SG: 'Singapore',
  SH: 'S:t Helena',
  SI: 'Slovenien',
  SJ: 'Svalbard och Jan Mayen',
  SK: 'Slovakien',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Surinam',
  SS: 'Sydsudan',
  ST: 'São Tomé och Príncipe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syrien',
  SZ: 'Swaziland',
  TA: 'Tristan da Cunha',
  TC: 'Turks- och Caicosöarna',
  TD: 'Tchad',
  TF: 'Franska sydterritorierna',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tadzjikistan',
  TK: 'Tokelau',
  TL: 'Östtimor',
  TM: 'Turkmenistan',
  TN: 'Tunisien',
  TO: 'Tonga',
  TR: 'Turkiet',
  TT: 'Trinidad och Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraina',
  UG: 'Uganda',
  UM: 'USA:s yttre öar',
  US: 'USA',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatikanstaten',
  VC: 'S:t Vincent och Grenadinerna',
  VE: 'Venezuela',
  VG: 'Brittiska Jungfruöarna',
  VI: 'Amerikanska Jungfruöarna',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis- och Futunaöarna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Jemen',
  YT: 'Mayotte',
  ZA: 'Sydafrika',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}
