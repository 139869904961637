import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Flex,
  Message,
  Text,
  TextButton,
} from 'shared-design/components/atoms'
import { LocaleContext } from 'shared-design/components/molecules/Locales'
import {
  AllowedReservationTimes,
  Building,
  ReservationBundle,
  UserReservableRoom,
  Weekday,
  MomentTimeInclusivity,
  ReservedSlot,
} from 'common/types'
import moment, { Moment, unitOfTime } from 'moment'
import { useHistory } from 'react-router'
import { ErrorOutline, Room, Schedule } from '@material-ui/icons'
import { TranslationKey } from 'common/locales/dictionary'
import { sortBy, prop } from 'ramda'
import { getWeekdayFromMoment } from 'common/utils/dates'
import { countMaxCapacityUse } from 'common/utils/capacity'
import { createReservation } from '../../api'
import { ApiError } from '../../api/errors'
import { RoomFilterCriteria, UserReservations } from './contexts'
import { useBuilding } from './hooks'
import { ReservationFilter } from './ReservationFilter'
import { clearLoginStateFromLocalStorage } from '../Login/hooks'
import { useErrorDispatch } from '../Error/context'
import './SpaceDetails.scss'

interface Props {
  space: UserReservableRoom
}

const getAllowedReservationTimesForDate = (
  space: UserReservableRoom,
  datetime: Moment,
): [string, string] | [] => {
  const weekDay = getWeekdayFromMoment(datetime)

  return space.allowedReservationTimes[weekDay]
}

const isBetweenGivenFormat = (
  format: string,
  comparedMoment: Moment,
  [start, end]: AllowedReservationTimes[Weekday],
  granularity: unitOfTime.StartOf = 'minutes',
  inclusivity: MomentTimeInclusivity = '[]',
) => {
  if (!start || !end) return false

  const momentFormatted = moment(comparedMoment.format(format), format)
  const startFormatted = moment(start, format)
  const endFormatted = moment(end, format)

  return momentFormatted.isBetween(
    startFormatted,
    endFormatted,
    granularity,
    inclusivity,
  )
}

const HealthCommitment: React.FC<
  Pick<
    Building,
    'visitorInfoTitle' | 'visitorInfoContent' | 'visitorInfoChecks'
  >
> = ({ visitorInfoTitle, visitorInfoContent, visitorInfoChecks }) => {
  const [isFadingOut, setIsFadingOut] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(true)

  /**
   * Some organization has their health commitment checks in the content and just have single "I accept" button,
   * and others have multiple separate checks. This removes the confirmation check if its just a simple "I accept" button
   * since it's not something that you can press in the web.
   */
  const filteredVisitorInfoChecks = useMemo(
    () =>
      visitorInfoChecks?.filter(
        (vic) => !RegExp(/(Hyväksyn ehdot)|(I accept the terms)/i).test(vic),
      ),
    [visitorInfoChecks],
  )

  useEffect(() => {
    if (isFadingOut === true) {
      setTimeout(() => {
        setIsFadingOut(false)
        setIsVisible(false)
      }, 450)
    }
  }, [isFadingOut, isVisible])

  const content = visitorInfoContent?.split('\\n').map((v) => (
    <div key={v}>
      {v}
      <br />
    </div>
  ))

  return isVisible ? (
    <div
      className={
        isFadingOut ? 'HealthCommitment__fade-out' : 'HealthCommitment'
      }
    >
      <ErrorOutline className="HealthCommitment__attention" />
      <div className="HealthCommitment__attention-bg">&nbsp;</div>
      <h4 className="HealthCommitment__title">
        {visitorInfoTitle ?? <Text tr="preReservationChecks" />}
      </h4>
      <div className="HealthCommitment__content">{content}</div>
      {filteredVisitorInfoChecks && filteredVisitorInfoChecks.length > 0 && (
        <ul className="HealthCommitment__checks">
          {filteredVisitorInfoChecks.map((check) => (
            <li key={check}>{check}</li>
          ))}
        </ul>
      )}
      <div className="HealthCommitment__ok">
        <TextButton onClick={() => setIsFadingOut(true)}>
          <Text tr="hide" />
        </TextButton>
      </div>
    </div>
  ) : null
}

const getOverlappingReservationSlots = (
  roomCriteria: RoomFilterCriteria,
  space: UserReservableRoom,
): ReservedSlot[] => {
  const overlappingReservations: ReservedSlot[] = []
  let currentOverlap: ReservedSlot | undefined

  let start = roomCriteria.start.clone()

  while (start.isBefore(roomCriteria.end)) {
    const end = start.clone().add(30, 'minutes')

    if (countMaxCapacityUse(space.reservations, start, end) >= space.capacity) {
      if (!currentOverlap?.start) {
        currentOverlap = {
          start: start.toDate(),
          end: end.toDate(),
        }
      } else {
        currentOverlap = {
          ...currentOverlap,
          end: end.toDate(),
        }
      }
    } else {
      if (currentOverlap) {
        overlappingReservations.push(currentOverlap)
      }
      currentOverlap = undefined
    }

    start = start.add(30, 'minutes')
  }

  return overlappingReservations
}

const SpaceDetails: React.FC<Props> = ({ space }) => {
  const history = useHistory()
  const roomCriteria = useContext(RoomFilterCriteria)
  const { localization, currentLanguage } = useContext(LocaleContext)
  const { setErrorMessage } = useErrorDispatch()
  const reservations = useContext(UserReservations)
  const overlappingReservations: ReservedSlot[] = useMemo(
    () =>
      sortBy(
        prop('start'),
        getOverlappingReservationSlots(roomCriteria, space),
      ),
    [roomCriteria, space],
  )

  const [building, error] = useBuilding(space.buildingId, currentLanguage)

  if (error) {
    setErrorMessage(error.message || 'Missing building')
  }

  const onClickReserve = () => {
    createReservation({
      roomId: space.id,
      start: moment.utc(roomCriteria.start).toISOString() ?? '',
      end: moment.utc(roomCriteria.end).toISOString() ?? '',
      participants: 1,
      paymentMethod: 'cash',
      products: [],
      reservedFrom: 'web',
    })
      .then(() => history.push('/reservations'))
      .catch((reason: ApiError) => {
        if (reason.message?.startsWith('"Too long reservation period.')) {
          const hoursMatch = reason.message.match(/\d+/)
          const hours = hoursMatch ? parseInt(hoursMatch[0], 10) : 0
          const template = localization.tooLongReservationPeriod
          setErrorMessage(
            hours > 24
              ? template
                  .replace('{amount}', Math.floor(hours / 24).toString())
                  .replace('{units}', localization.reservationDays)
              : template
                  .replace('{amount}', hours.toString())
                  .replace('{units}', localization.reservationHours),
          )
        } else if (reason.message?.match(/Reservation time not allowed/)) {
          setErrorMessage(localization.reservationTimeNotAllowed)
        } else if (reason.message?.match(/Conflict/)) {
          setErrorMessage(localization.conflictingReservation)
        } else {
          const errorMessage = reason.message ?? 'Unknown error'
          setErrorMessage(
            localization[errorMessage as TranslationKey] || errorMessage,
          )
        }

        if (reason.tag === 'auth_error') {
          clearLoginStateFromLocalStorage()
          history.push('login?step=input_email')
        }
      })
  }

  const healthCommitmentAvailable = space.buildingVisitorInfoAvailable

  const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const localizedStart = roomCriteria.start
    .toDate()
    .toLocaleDateString(currentLanguage, dateTimeFormatOptions)
  const localizedEnd = roomCriteria.end
    .toDate()
    .toLocaleDateString(currentLanguage, dateTimeFormatOptions)
  const reservationDuration = moment
    .duration(roomCriteria.end.diff(roomCriteria.start), 'milliseconds')
    .locale(currentLanguage)

  const ownOverlappingReservation = (reservations || []).find(
    (bundle: ReservationBundle) => {
      return (
        moment
          .utc(bundle.reservation.start)
          .isBetween(roomCriteria.start, roomCriteria.end, null, '[]') ||
        moment
          .utc(bundle.reservation.end)
          .isBetween(roomCriteria.start, roomCriteria.end, null, '[]')
      )
    },
  )

  const isTooLongReservation = useMemo(
    () =>
      space.maxReservationMinutes
        ? reservationDuration.asMinutes() > (space.maxReservationMinutes || 0)
        : false,
    [reservationDuration, space],
  )

  const isReservingWhenNotOpen = useMemo(() => {
    const startIsBetween = isBetweenGivenFormat(
      'HH:mm',
      roomCriteria.start,
      getAllowedReservationTimesForDate(space, roomCriteria.start),
    )

    const endIsBetween = isBetweenGivenFormat(
      'HH:mm',
      roomCriteria.end,
      getAllowedReservationTimesForDate(space, roomCriteria.end),
    )

    return !startIsBetween || !endIsBetween
  }, [roomCriteria, space])

  const capacityIsFull = useMemo(
    () =>
      countMaxCapacityUse(
        space.reservations,
        roomCriteria.start,
        roomCriteria.end,
      ) >= space.capacity,
    [space.capacity, space.reservations, roomCriteria],
  )

  const isReservationDisabled = useMemo(
    () => isTooLongReservation || isReservingWhenNotOpen || capacityIsFull,
    [isTooLongReservation, isReservingWhenNotOpen, capacityIsFull],
  )

  return (
    <>
      <ReservationFilter />
      <hr className="SpaceDetails__separator" />
      <div className="SpaceDetails">
        <h2 className="SpaceDetails__title">
          <Text tr="confirmReservation" />: {space.name}
        </h2>
        {healthCommitmentAvailable && building ? (
          <HealthCommitment
            visitorInfoTitle={building.visitorInfoTitle}
            visitorInfoContent={building.visitorInfoContent}
            visitorInfoChecks={building.visitorInfoChecks}
          />
        ) : null}
        {building ? (
          <>
            <div className="SpaceDetails__section">
              <h4 className="SpaceDetails__section-heading">
                <Room className="SpaceDetails__icon" />{' '}
                <Text tr="confirmReservationAddress" />
              </h4>
              <div>{building.name}</div>
              <div>{building.address}</div>
            </div>
          </>
        ) : null}
        <div className="SpaceDetails__section">
          <h4 className="SpaceDetails__section-heading">
            <Schedule className="SpaceDetails__icon" />
            <Text tr="confirmReservationTimespan" />
          </h4>

          <Flex gap="15" direction="column">
            <div className="SpaceDetails__timerange-wrapper">
              <Flex style={{ alignItems: 'center' }} gap="30">
                <div className="SpaceDetails__timerange">
                  {`${localizedStart} - ${localizedEnd} `}
                  <wbr />
                  {`(${reservationDuration
                    .asHours()
                    .toLocaleString(currentLanguage, {
                      maximumFractionDigits: 1,
                    })} H)`}
                </div>
              </Flex>
            </div>

            <Flex direction="column" className="SpaceDetails__ErrorMessages">
              {ownOverlappingReservation && (
                <Message variant="warning">
                  {ownOverlappingReservation.room.id === space.id ? (
                    <Text tr="alreadyReservedThis" />
                  ) : (
                    <>
                      <Text tr="alreadyReservedSpace" />
                      <span className="SpaceDetails__timerange-message--other-space">
                        {ownOverlappingReservation.room.name}
                      </span>
                    </>
                  )}
                </Message>
              )}

              {isTooLongReservation && (
                <Message variant="error">
                  {localization.maximumReservationLengthInHours({
                    hours: moment
                      .duration(space.maxReservationMinutes, 'minutes')
                      .asHours(),
                  })}
                </Message>
              )}

              {isReservingWhenNotOpen && (
                <Message variant="error" pre>
                  {localization.reservationIsOpenOn(
                    space.allowedReservationTimes,
                  )}
                </Message>
              )}

              {capacityIsFull && (
                <Message variant="error" pre>
                  {localization.capacityFullMessage({
                    overlappingReservations,
                  })}
                </Message>
              )}
            </Flex>
          </Flex>
        </div>

        <div className="SpaceDetails__buttons">
          <Flex direction="row" grow>
            <Button
              variant="secondary"
              onClick={() => {
                history.push('/spaces')
              }}
            >
              <Text tr="cancel" />
            </Button>
            <Button onClick={onClickReserve} disabled={isReservationDisabled}>
              <Text tr="reserve" />
            </Button>
          </Flex>
        </div>
      </div>
    </>
  )
}

export default SpaceDetails
