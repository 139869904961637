import React from 'react'

import './SpacentLogo.scss'

import classNames from 'classnames'
import { ReactComponent as SpacentLogoSvg } from '../../../public/spacent-logo.svg'
import spacentIcon from '../../../public/favicon-32x32.png'

export interface SpacentLogoProps {
  linkPath?: string
  icon?: boolean
}

export const SpacentLogo: React.FC<SpacentLogoProps> = ({ icon, linkPath }) => (
  <div className={classNames('SpacentLogo', { 'SpacentLogo--icon': icon })}>
    <a href={linkPath ? `${linkPath}` : '/'} className="SpacentLogo__Link">
      {icon ? <img src={spacentIcon} alt="Spacent Oy" /> : <SpacentLogoSvg />}
    </a>
  </div>
)

export default SpacentLogo
