interface GoogleTagManagerPacket {
  event?: string
  [key: string]: unknown
}

declare let dataLayer: GoogleTagManagerPacket[] | undefined

export function sendWebAnalyticsPacket(event: GoogleTagManagerPacket) {
  if (!dataLayer) {
    // eslint-disable-next-line no-console
    return console.error(
      'Attempting to send web analytics data, but the datalayer does not exist.',
    )
  }

  return dataLayer.push(event)
}
