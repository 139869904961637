import React from 'react'
import './TextButton.scss'
import classNames from 'classnames'

interface TextButtonProps {
  children: React.ReactNode
  onClick: React.MouseEventHandler
  style?: React.CSSProperties
  variant?: 'primary' | 'danger'
  disabled?: boolean
}

const TextButton: React.FunctionComponent<TextButtonProps> = (
  props: TextButtonProps,
) => {
  const { children, onClick, style, variant = 'primary' } = props

  return (
    <button
      type="button"
      className={classNames('text-button', { danger: variant === 'danger' })}
      onClick={onClick}
      style={style}
      disabled={props.disabled}
    >
      {children}
    </button>
  )
}

export default TextButton
