import React, { createContext, useState, useContext, useCallback } from 'react'
import { FormikErrors, FormikValues, getIn } from 'formik'
import { Language, LocalizedText } from 'common/types'
import { languages } from 'common/constants'
import { TranslatedValidationKey } from 'common/locales/translations/types'
import moment from 'moment'
import * as Yup from 'yup'
import {
  localizations,
  Translations,
  TranslationKey,
} from 'common/locales/dictionary'
import {
  moneyFormatters,
  moneyWithTaxFormatters,
  MoneyFormatter,
  MoneyWithTaxFormatter,
} from 'common/locales/money'
import { find } from 'ramda'
import {
  getLocalizedContent,
  noopIgnoreUnusedParamsForEslint,
} from 'common/utils/format'
import { localizeMoment } from './moment'

Yup.setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    email: 'email',
    min: ({ min }: { min: number }) => ({ key: 'min', values: { min } }),
    max: ({ max }: { max: number }) => ({ key: 'max', values: { max } }),
  },
})

Yup.addMethod<Yup.ObjectSchema<any>>(
  Yup.object,
  'atLeastOneLanguage',
  function atLeastOneLanguage() {
    return this.test({
      name: 'atLeastOneLanguage',
      message: 'required',
      exclusive: true,
      params: { keys: languages },
      test: (value) =>
        value === null || languages.some((lang) => !!value[lang]),
    })
  },
)

interface ImageWithState {
  state: string
}

Yup.addMethod<Yup.ArraySchema<ImageWithState>>(
  Yup.array,
  'atLeastThreeActiveImages',
  function atLeastThreeActiveImages() {
    return this.test({
      name: 'atLeastThreeActiveImages',
      message: 'minThreePhotos',
      exclusive: true,
      test: (arr) => {
        return (
          ((arr as any[]) ?? []).filter((image: any) =>
            ['added', 'noChange'].includes(image.state),
          ).length >= 3
        )
      },
    })
  },
)

declare module 'yup' {
  interface ObjectSchema<T> {
    atLeastOneLanguage(): ObjectSchema<T>
  }
  interface ArraySchema<T> {
    atLeastThreeActiveImages(): ArraySchema<T>
  }
}

function getDefaultLanguage(): Language {
  const storedLanguage = localStorage.getItem('locale')
  const browserLanguage = window?.navigator?.language?.slice(0, 2)
  const fallbackLanguage: Language = 'en'
  const supportedLanguages = languages

  const clientLanguagePriority: (string | null)[] = [
    storedLanguage,
    browserLanguage,
    fallbackLanguage,
  ]

  return find(
    (lang) => supportedLanguages.includes(lang as Language),
    clientLanguagePriority,
  ) as Language
}

const defaultLanguage: Language = getDefaultLanguage()

export interface LocaleContextProps {
  currentLanguage: Language
  localization: Translations
  moment: ReturnType<typeof localizeMoment>
  moneyFormatter: MoneyFormatter
  moneyWithTaxFormatter: MoneyWithTaxFormatter
  error: (
    errors: FormikErrors<FormikValues> | string | undefined,
    path: string | string[],
    fieldName?: string,
  ) => string | undefined
  changeLanguage: CallableFunction
}

export const LocaleContext = createContext<LocaleContextProps>({
  currentLanguage: defaultLanguage,
  localization: localizations[defaultLanguage],
  moment: localizeMoment(defaultLanguage),
  moneyFormatter: moneyFormatters[defaultLanguage],
  moneyWithTaxFormatter: moneyWithTaxFormatters[defaultLanguage],
  error: (
    errors: FormikErrors<FormikValues> | string | undefined,
    path: string | string[],
  ) => {
    if (!errors) {
      return ''
    }

    if (typeof errors === 'string') {
      return errors
    }

    return `${Array.isArray(path) ? path[path.length - 1] : path} ${getIn(
      errors,
      path,
    )}`
  },
  changeLanguage: noopIgnoreUnusedParamsForEslint,
})

export const LocaleProvider: React.FC = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] =
    useState<Language>(defaultLanguage)

  const momentLocale = currentLanguage === 'zh' ? 'zh-cn' : currentLanguage

  // Moment.js global locale state doesn't update correctly,
  // therefore this hack. This should work even if the bug (in Moment.js?)
  // will be fixed. Could be also something webpack related, locale
  // imports cause strange issues.
  if (['fi', 'sv', 'zh', 'sv'].includes(currentLanguage)) {
    moment.updateLocale(momentLocale, {
      week: {
        dow: 1,
      },
    })
  } else if (currentLanguage === 'en') {
    moment.updateLocale(momentLocale, {
      week: {
        dow: 0,
      },
    })
  }

  const localizedMoment = localizeMoment(momentLocale)

  const localizeSingleError = (
    error: string | { key: string; values: any },
    translation: Translations,
    field: string,
  ): string => {
    const values = {
      field,
      ...(typeof error === 'string' ? {} : error.values),
    }
    const validation = translation.validations
    const key = typeof error === 'string' ? error : error.key
    try {
      return validation[key as TranslatedValidationKey](values)
    } catch (e) {
      // l10n is intermittently fragile; this helps development and can be asked from users; TODO: send to backend?
      // eslint-disable-next-line no-console
      console.error(
        `Error in ${currentLanguage} validation[${key}] of "${error}"`,
        e,
      )
    }

    return `${field}: ${error}`
  }

  const localizedError = (
    errors: FormikErrors<FormikValues> | string | undefined,
    path: TranslationKey | TranslationKey[] | string | string[],
    fieldName?: string,
  ) => {
    const errorsForPath =
      errors && (typeof errors === 'string' ? errors : getIn(errors, path))

    if (!errorsForPath) {
      return undefined
    }

    const translation: Translations = localizations[currentLanguage]

    const resolveDefaultFieldName = () => {
      const fieldKey = Array.isArray(path) ? path[path.length - 1] : path

      return fieldKey in translation
        ? translation[fieldKey as TranslationKey]
        : fieldKey
    }
    const field = fieldName ?? resolveDefaultFieldName()

    const errorArray = Array.isArray(errorsForPath)
      ? errorsForPath // can be an array of errors for given field
      : [errorsForPath]

    return errorArray
      .map((error) => localizeSingleError(error, translation, field))
      .join('\n–\n')
  }

  const provider = {
    currentLanguage,
    localization: localizations[currentLanguage],
    error: localizedError,
    moment: localizedMoment,
    moneyFormatter: moneyFormatters[currentLanguage],
    moneyWithTaxFormatter: moneyWithTaxFormatters[currentLanguage],
    changeLanguage: (language: Language) => {
      const newLanguage = localizations[language] ? language : defaultLanguage
      localStorage.setItem('locale', newLanguage)
      setCurrentLanguage(newLanguage)
    },
  }

  return (
    <LocaleContext.Provider value={provider}>{children}</LocaleContext.Provider>
  )
}

export const useLocalizations = (): LocaleContextProps => {
  const context = useContext(LocaleContext)

  if (context === undefined) {
    throw new Error(
      'useLocalizations must be used within LocalizedContentContext',
    )
  }

  return context
}

export const pluralize = (n: number, singular: string, plural: string) =>
  `${n} ${n !== 1 ? plural : singular}`

export const useLocalizedContentExtractor = () => {
  const { currentLanguage } = useLocalizations()

  const extractor = useCallback(
    (localized: LocalizedText, fallback = '') => {
      return getLocalizedContent(localized, currentLanguage, fallback)
    },
    [currentLanguage],
  )

  return extractor
}

export default LocaleProvider
