import { useState } from 'react'
import { cancelReservation } from '../../api'

export const useReservationCancelling = (): [
  (reservationNumber: number) => Promise<void>,
  string | undefined,
] => {
  const [error, setError] = useState<any>()

  const cancel = async (reservationId: number) => {
    try {
      await cancelReservation(reservationId)
      window.location.reload()
    } catch (err) {
      setError(err)
    }
  }

  return [cancel, error]
}

export default useReservationCancelling
